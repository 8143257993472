import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { updateDeployment } from '../common';
import { CustomWidgets } from 'form-components';
import { iDeployment, iDeploymentServicesConfig } from 'shared/deployment';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { Alert, Button, Space, Typography } from 'antd';
import { buttonWidth } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';

const { Title } = Typography;

export const DeploymentOneServiceSettings = (props: {
  onSave?: () => void;
  serviceName: string;
  deployment: iDeployment;
  schema: iDeploymentServicesConfig;
}) => {
  const handleSubmit = async (e: any) => {
    const data = {};
    data[props.serviceName] = e.formData;
    await updateDeployment(props.deployment.id, { vars: data });
    if (props.onSave) props.onSave();
  };

  if (!props?.schema?.hasUIconfig)
    return <Alert message={`Service ${props.serviceName} does not have any configuration options`} type="info" showIcon closable={false} />;

  const FormSchema: any = props.schema.FormSchema;
  return (
    <>
      <Title level={5}> Configuration for service {props.serviceName} </Title>

      <Form
        widgets={CustomWidgets}
        formData={props.schema.serviceVarConfig}
        schema={FormSchema}
        uiSchema={props.schema.UISchema}
        validator={validator}
        onChange={() => console.log('changed')}
        onSubmit={handleSubmit}
        onError={(e: any) => console.log('Error from submit: ', e)}
        templates={{ FieldTemplate: CustomFieldTemplate }}
      >
        <BottomButtons>
          <Space>
            {props.onSave && (
              <Button
                style={buttonWidth}
                type="default"
                key="back"
                onClick={() => {
                  if (props.onSave) props.onSave();
                }}
              >
                Cancel
              </Button>
            )}
            <Button type="primary" htmlType="submit" style={buttonWidth}>
              Save
            </Button>
          </Space>
        </BottomButtons>
      </Form>
    </>
  );
};
