import { gql } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { setContainerStorageConfig } from 'queries/queries';
import { iProject } from 'interface/project';
import { useAuthedMutation, useAuthedQuery } from 'utils/qlAuth';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { NullSpaces } from 'components/SharedComponents/NullSpaces/NullSpaces';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';
import { layout } from 'components/Deployments/Setting/common';
import { Alert, Skeleton, notification, Button, Collapse, Form, Input, Space, Radio, Typography, Tooltip, Image } from 'antd';
import { DeleteFilled, KeyOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { spaceWidth, buttonWidth } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';

export interface IProjectContainerStorageProps {
  project: iProjectModel;
}

export interface IOneSecretProps {
  index: number;
  subField: any;
  subOpt: any;
  item?: any;
  form: any;
}

const { Title, Text } = Typography;
const { Item, List } = Form;
const { Password } = Input;
const { Panel } = Collapse;
const { Group } = Radio;

export const OneSecret = (props: IOneSecretProps) => {
  const subField = props.subField;
  const [secretType, set_secretType] = useState(props.item?.type || 'password');

  useEffect(() => {
    props.item?.type ? set_secretType(props.item.type) : null;
  }, [props.item?.type]);

  const renderRadioButtons = () => {
    const radioOptions = [
      { value: 'password', tooltip: 'Login and Password', icon: <KeyOutlined /> },
      { value: 'AWS ECR', tooltip: 'AWS ECR', icon: <Image src="branding/logoAWS.svg" preview={false} height={25} /> },
      { value: 'quay.io', tooltip: 'Quay.io', icon: <Image src="branding/logoQuay.svg" preview={false} height={20} /> },
      { value: 'docker.io', tooltip: 'Docker Hub', icon: <Image src="branding/logoDocker.svg" preview={false} height={25} /> },
    ];
    return radioOptions.map(option => (
      <Radio.Button key={option.value} value={option.value}>
        <Tooltip color="#115EA3" placement="topLeft" title={option.tooltip}>
          {option.icon}
        </Tooltip>
      </Radio.Button>
    ));
  };

  const secretTypeConfig = () => {
    const renderLoginForm = (loginItems: any[]) =>
      loginItems.map((item, index) => (
        <Item {...layout} key={index} name={[subField.name, item.name]} label={<Text>{item.label}</Text>}>
          {item.password ? <Password placeholder={item.placeholder} /> : <Input placeholder={item.placeholder} />}
        </Item>
      ));

    const generateLoginItems: any = (loginType: string) => {
      const passwordForm = [
        { name: 'args.user', label: 'Username', placeholder: 'Enter Your Username Here', repeat: 3 },
        { name: 'args.password', label: 'Password', placeholder: 'Enter Your Password Here', repeat: 4 },
        { name: 'args.server', label: 'Server', placeholder: 'Enter Your Server Name Here', repeat: 9 },
      ];
      const awsForm = [
        { name: 'args.AWS_ACCESS_KEY_ID', label: 'AWS access key ID ', placeholder: 'Enter Your AWS access key ID Here', repeat: 11 },
        {
          name: 'args.AWS_SECRET_ACCESS_KEY',
          label: 'AWS secret access key',
          placeholder: 'Enter Your AWS secret access key Here',
          repeat: 2,
          password: true,
        },
        { name: 'args.region', label: 'AWS Region with ECR', placeholder: 'Enter Your AWS Region with ECR Here', repeat: 10 },
        { name: 'args.ECR_URL', label: 'AWS ECR URL', placeholder: 'Enter Your AWS ECR URL Here', repeat: 22 },
      ];
      const quayForm = [
        { name: 'args.user', label: 'Username', placeholder: 'Enter Your Username Here', repeat: 10 },
        { name: 'args.password', label: 'Robot Account', placeholder: 'Enter Your Robot Account Here', repeat: 2, password: true },
      ];
      const dockerForm = [
        { name: 'args.user', label: 'Username', placeholder: 'Enter Your Username Here', repeat: 7 },
        { name: 'args.password', label: 'Access Token', placeholder: 'Enter Your Access Token Here', repeat: 2, password: true },
      ];
      const loginItemConfig = { password: passwordForm, aws: awsForm, quay: quayForm, docker: dockerForm };
      return loginItemConfig[loginType].map((item: { label: any }) => ({ ...item, placeholder: `Enter Your ${item.label} Here` }));
    };

    const passwordLogin = () => renderLoginForm(generateLoginItems('password'));
    const awsLogin = () => renderLoginForm(generateLoginItems('aws'));
    const quayLogin = () => renderLoginForm(generateLoginItems('quay'));
    const dockerLogin = () => renderLoginForm(generateLoginItems('docker'));

    const loginForms = { password: passwordLogin, 'AWS ECR': awsLogin, 'quay.io': quayLogin, 'docker.io': dockerLogin };

    return (
      secretType && (
        <Collapse size="small" accordion defaultActiveKey={['1']} style={{ backgroundColor: 'transparent' }}>
          <Panel key="1" header={<Text strong> Config for Container Storage Provider </Text>}>
            {loginForms[secretType]?.()}
          </Panel>
        </Collapse>
      )
    );
  };

  return (
    <>
      <Space direction="vertical">
        <Text />
        <Text strong> Choose the Container Storage Provider Here: </Text>
        <Space direction="horizontal">
          <Space direction="vertical">
            <Text />
            <Item name={[subField.name, 'type']} label={``}>
              <Group size="large" defaultValue="password" onChange={e => set_secretType(e.target.value)}>
                {renderRadioButtons()}
              </Group>
            </Item>
          </Space>
          <Space>
            <Tooltip color="#115EA3" placement="right" title="Delete Addition Variables Here">
              <Button type="link" size="large" danger>
                <DeleteFilled onClick={() => props.subOpt.remove(subField.name)} />
              </Button>
            </Tooltip>
          </Space>
        </Space>
      </Space>
      <Item
        name={[subField.name, 'name']}
        label={<Text strong> Secret Name </Text>}
        rules={[{ message: 'Enter Your Secret Name Here', required: true }]}
      >
        <Input placeholder="Enter Your Secret Name Here" />
      </Item>
      {secretTypeConfig()}
    </>
  );
};

export const ProjectContainerStorage = (props: IProjectContainerStorageProps): ReactElement => {
  const [form] = Form.useForm();
  const { loading, error, data } = useAuthedQuery(
    gql`
      query getContainerStorageFormConfig($projectId: Int!) {
        getContainerStorageConfig(projectId: $projectId) {
          registry {
            type
            name
            args
          }
        }
      }
    `,
    { variables: { projectId: props.project.id } },
  );
  const [mutateFunction, saveRes] = useAuthedMutation(setContainerStorageConfig);

  const registry: any[] = (data?.getContainerStorageConfig.registry || []).map((item: any) => {
    let res = { type: item.type || 'password', name: item.name };
    item.type === 'AWS ECR'
      ? ((res['args.ECR_URL'] = item?.args?.ECR_URL),
        (res['args.region'] = item?.args?.region),
        (res['args.AWS_SECRET_ACCESS_KEY'] = item?.args?.AWS_SECRET_ACCESS_KEY),
        (res['args.AWS_ACCESS_KEY_ID'] = item?.args?.AWS_ACCESS_KEY_ID))
      : ((res['args.user'] = item?.args?.user), (res['args.password'] = item?.args?.password), (res['args.server'] = item?.args?.server));
    return res;
  });

  useEffect(() => {
    data?.getContainerStorageConfig ? form.setFieldsValue({ registry: registry }) : null;
  }, [data]);

  if (error) {
    return (
      <Skeleton active={true} loading={true}>
        Error: {JSON.stringify(error)}
      </Skeleton>
    );
  }

  if (loading || error) return <Skeleton active={true} loading={true} />;

  const alertData = () => {
    const alertMessage = `This tab allows you configure integration with container storage services and create image pull secrets. It needed if you use Private Containers Registry.`;

    const alertAction = () => {
      const alertActionButton = <QuestionCircleTwoTone twoToneColor="#00A58E" />;

      const alertLinks = () => {
        const links = [
          {
            href: 'https://kubernetes.io/docs/tasks/configure-pod-container/pull-image-private-registry/',
            text: 'Pull an Image from a Private Registry',
          },
          {
            href: 'https://docs.aws.amazon.com/AmazonECR/latest/userguide/docker-pull-ecr-image.html',
            text: 'Pulling an image from an Amazon ECR private repository',
          },
        ];

        return (
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <Text strong>
                  <a href={link.href} target="_blank">
                    {link.text}
                  </a>
                </Text>
              </li>
            ))}
          </ul>
        );
      };

      return (
        <HelpDrawerTpl title="Help" btnText={alertActionButton}>
          <Space direction="vertical">
            <Text> This page allows you to create image pull secret from credentials. It needed if you use Private Containers Registry </Text>
            <Text /> <Text />
            <Title level={4}> Troubleshooting: </Title>
            <Text>
              Be sure to add your secret to <Text strong> "imagePullSecrets" </Text> fields in Pod configuration.
            </Text>
            <Text /> <Text />
            <Title level={4}> Additional information: </Title>
            {alertLinks()}
          </Space>
        </HelpDrawerTpl>
      );
    };

    return <Alert showIcon type="info" message={alertMessage} action={alertAction()} />;
  };

  const storageData = () => {
    const handleSubmit = async (formData: any) => {
      debugger;
      const registry = formData.registry.map((item: any) => {
        let args = {};
        args =
          item.type === 'AWS ECR'
            ? {
                ECR_URL: item['args.ECR_URL'],
                region: item['args.region'],
                AWS_SECRET_ACCESS_KEY: item['args.AWS_SECRET_ACCESS_KEY'],
                AWS_ACCESS_KEY_ID: item['args.AWS_ACCESS_KEY_ID'],
              }
            : { user: item['args.user'], password: item['args.password'], server: item['args.server'] };
        return { type: item.type || 'password', name: item.name, args: args };
      });
      await mutateFunction({ variables: { config: { form: { registry } }, projectId: props.project.id } });
      saveRes.error ? notification.error({ message: `Error - ${saveRes.error}` }) : notification.success({ message: `Ready` });
    };

    return (
      <Form onFinish={handleSubmit} form={form} name="dynamic_form_complex" autoComplete="off" initialValues={{ registry: registry }}>
        <List name="registry">
          {(subFields, subOpt) => (
            <>
              {subFields.map((subField, index) => {
                const itemArr = form.getFieldsValue()?.registry;
                const item = itemArr ? itemArr[subField.key] : registry[subField.key];
                return <OneSecret form={form} index={index} subField={subField} subOpt={subOpt} item={item} />;
              })}
              <Space direction="vertical">
                <Text />
                <Button type="primary" onClick={() => subOpt.add()} style={buttonWidth}>
                  Add Image Pull Secret
                </Button>
                <NullSpaces />
              </Space>
            </>
          )}
        </List>
        <Item>
          <BottomButtons>
            <Button type="primary" htmlType="submit" style={buttonWidth}>
              Save
            </Button>
          </BottomButtons>
        </Item>
      </Form>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Title level={5}> Pull Secrets Configuration </Title>
      {alertData()}
      {storageData()}
    </Space>
  );
};
