import gql from 'graphql-tag';
import { ReactElement, useEffect, useState } from 'react';
import { projectService } from 'services/project.service';
import { useAuthedQuery } from 'utils/qlAuth';
import { createGitHubRepoForProject, getGitHubAllRepos, getGitHubRepoBranches, getGitHubToken, isValidGithubApiToken } from 'services/githubApi';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { ProjectGitBtn_Clone, ProjectGitBtn_Pull, ProjectGitBtn_push, projectGitPull } from './GitButtons';
import {
  Button,
  notification,
  Skeleton,
  Alert,
  Input,
  Space,
  Select,
  Form,
  Radio,
  Tag,
  Typography,
  Tooltip,
  Popover,
  Dropdown,
  MenuProps,
  message,
} from 'antd';
import { ArrowRightOutlined, GithubOutlined, KeyOutlined, QuestionCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { spaceWidth, divWidth, buttonWidth } from 'utils/styles';
import { ProjectGitConfigurationHelp } from './ProjectGitConfigurationHelp';
import { iProject } from 'interface/project';
import { iProjectModel } from 'shared/deployment';

interface iProjectGitSettings {
  project: iProjectModel;
  showInDrawer?: boolean;
  onSave?: () => void;
}

const { Text, Title } = Typography;
const { Item } = Form;
const { Option } = Select;
const { Group } = Radio;

export const ProjectGitSettings = (props: iProjectGitSettings): ReactElement => {
  const projectId = props.project.id;

  const [dstBranch, setDstBranch] = useState(null);
  const [gitReposArray, setGitRepos] = useState([]);
  const [gitReposBranches, setGitRepoBranches] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [selectedGitHubRepo, setSelectedGitHubRepo] = useState(null);
  const [reLink, setReLink] = useState(false);

  const { loading, error, data, stopPolling, startPolling, refetch } = useAuthedQuery(
    gql`
      query ProjectGitController_checkGitLock($projectId: Int!) {
        ProjectGitController_checkGitLock(projectId: $projectId)
        ProjectController_getProject(projectId: $projectId) {
          gitInitialized
        }
        ProjectGitController_getGitConfiguration(projectId: $projectId) {
          gitToken
          gitUrl
          gitIntegrationType
          gitProjectPath
          gitBranch
          gitAuthType
        }
      }
    `,
    { variables: { projectId: projectId }, pollInterval: 10000 },
  );
  startPolling(10000);

  const git = data?.ProjectGitController_getGitConfiguration;
  let selectedAuthType = authType || git?.gitAuthType || 'github-oauth';

  const readyForGitOperations = git && git.gitUrl && git.gitBranch;
  const readyForGitPushOperations = git && git.gitUrl && git.gitBranch && git.gitToken;
  const repoIsLinked = !reLink && git?.gitUrl && git?.gitBranch;
  const gitInitialized = data?.ProjectController_getProject?.gitInitialized;
  const isValidGithubToken = isValidGithubApiToken();

  useEffect(() => {
    git ? setDstBranch(git.gitBranch) : null;
    isValidGithubToken ? (async () => setGitRepos(await getGitHubAllRepos()))() : null;
    return stopPolling;
  }, [git]);

  const handleSubmit = async (e: { gitProjectPath: string; gitAuthType: string; gitUrl: string; gitBranch: any; gitToken: string }) => {
    const formData = e;

    if (formData.gitProjectPath === undefined) {
      formData.gitProjectPath = '';
    }

    if (formData.gitAuthType === 'github-oauth') {
      if (!selectedGitHubRepo) {
        notification.error({ message: `Please select repository` });
        return;
      }
      formData.gitUrl = String(selectedGitHubRepo?.clone_url).replace('http://', '').replace('https://', '');
      formData.gitBranch = dstBranch;
      formData.gitToken = getGitHubToken();
    } else {
      setDstBranch(formData.gitBranch);
    }
    formData.gitUrl = String(formData.gitUrl).replace('http://', '').replace('https://', '');

    const res = await projectService.saveProjectGitConfig(projectId, formData);
    refetch();
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    } else {
      if (props.onSave) {
        props.onSave();
      }
      notification.success({ message: `Ready` });
    }

    if (readyForGitOperations && gitInitialized === false) {
      await projectGitPull(projectId);
    }
  };

  const onSelectGithubRepo = async repoId => {
    const repo = gitReposArray.find(repo => repo.id === Number(repoId));
    const branches = await getGitHubRepoBranches(repo);
    setSelectedGitHubRepo(repo);
    if (Array.isArray(branches)) {
      setGitRepoBranches(branches);
    } else {
      debugger;
    }
  };

  if (!git) return <Skeleton active={true} loading={true} />;

  let gitOAuthLink = `/api/github/oauth/repo-authorize?params=${encodeURIComponent(`gitconfig-${projectId}`)}`;
  if (window.location.hostname === 'localhost') {
    gitOAuthLink = `http://localhost:4001${gitOAuthLink}`;
  }

  const logo = (
    <>
      &nbsp; <ArrowRightOutlined /> &nbsp;
    </>
  );

  const gitTokenInfo = (
    <Space direction="vertical">
      <Title level={5}> Steps to get Access token </Title>
      <Text>
        Github {logo} Setting {logo} Developer Setting {logo} Personal Access Token {logo} Token (Classic) {logo} Generate New Token.
      </Text>
      <Text> Copy this access token and paste it in Git. </Text>
      <Text> Provide Git repository link without http:// </Text>
    </Space>
  );

  const formFields = [
    {
      name: 'gitUrl',
      label: <Text strong> Git repository URL </Text>,
      placeholder: 'Enter URL (without `https://`) Here',
      rules: [{ required: true }],
      help: 'Your git repository url should be in the format `gitlab.com/username/repo` or `github.com/username/project.git`',
    },
    { name: 'gitToken', label: <Text strong> Access Token </Text>, placeholder: 'Enter Access Token Here', type: 'password', help: gitTokenInfo },
    {
      name: 'gitBranch',
      label: <Text strong> Branch </Text>,
      placeholder: 'Enter Branch Here',
      rules: [{ required: true }],
      help: 'Branch name should be the name of the branch you want to use for this project',
    },
  ];

  const gitIcon = (
    <>
      <GithubOutlined /> GitHub OAuth
    </>
  );

  const tokenIcon = (
    <>
      <KeyOutlined /> User & Password
    </>
  );

  const authButton = [
    { value: 'github-oauth', title: 'GitHub OAuth', icon: gitIcon },
    { value: 'token', title: 'Access token or password', icon: tokenIcon },
  ];

  const linkedUI = () =>
    repoIsLinked && (
      <>
        <Space direction="horizontal">
          <Text> Repository is linked with: </Text>
          <Tag color="green"> Branch {git?.gitBranch} </Tag>
          <Button type="dashed" size="small" onClick={() => setReLink(true)}>
            Unlink
          </Button>
          <Text strong>
            <Tooltip color="#115EA3" placement="rightTop" title={git?.gitUrl}>
              <a href={`https://${git?.gitUrl}`} target="_blank">
                Git link here
              </a>
            </Tooltip>
          </Text>
        </Space>
        {!props.showInDrawer && (
          <BottomButtons>
            <Space direction="horizontal">
              <ProjectGitBtn_push disabled={!readyForGitPushOperations} projectId={projectId} onClick={refetch} dstBranch={dstBranch} />
              <ProjectGitBtn_Pull disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
              <ProjectGitBtn_Clone disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
            </Space>
          </BottomButtons>
        )}
      </>
    );

  const emptySpace = () => (
    <Space>
      <Text />
    </Space>
  );

  const errorAlert = () => error && <Alert type="warning" showIcon key={'error'} message="Error" description={JSON.stringify(error)} />;

  const showInDrawerAlert = () =>
    !props.showInDrawer &&
    data &&
    data.ProjectGitController_checkGitLock && (
      <Alert showIcon type="warning" description="Project is locked by another user. Please wait until all other users finish the work with git" />
    );

  const repoIsLinkedFormshowInDrawerTrue = () => (
    <Space>
      <Button type="primary" htmlType="submit" style={buttonWidth}>
        Save
      </Button>
    </Space>
  );

  const repoIsLinkedFormshowInDrawerFalse = () => (
    <Space>
      <Button type="primary" htmlType="submit" style={buttonWidth}>
        Save Git Configuration
      </Button>
      <ProjectGitBtn_push disabled={!readyForGitPushOperations} projectId={projectId} onClick={refetch} dstBranch={dstBranch} />
      <ProjectGitBtn_Pull disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
      <ProjectGitBtn_Clone disabled={!readyForGitOperations} projectId={projectId} onClick={refetch} />
    </Space>
  );

  const repoIsLinkedForm = () =>
    !repoIsLinked && (
      <Form onFinish={handleSubmit} initialValues={git}>
        <Item name={`gitAuthType`} label={<Text strong> Git authentication type </Text>}>
          <Group buttonStyle="solid" onChange={e => setAuthType(e.target.value)}>
            {authButton.map(option => (
              <Tooltip key={option.value} color="#115EA3" title={option.title}>
                <Radio.Button value={option.value}> {option.icon} </Radio.Button>
              </Tooltip>
            ))}
          </Group>
        </Item>

        {selectedAuthType === 'github-oauth' ? (
          !isValidGithubToken ? (
            <a href={gitOAuthLink}>
              <Button type="primary" data-cy="git-repo-auth">
                Sign-in with GitHub <GithubOutlined />
              </Button>
            </a>
          ) : (
            <>
              <Button
                type="primary"
                onClick={async () => {
                  const repo = await createGitHubRepoForProject(props.project);
                  console.log(repo);
                  debugger;
                  const res = await projectService.saveProjectGitConfig(projectId, {
                    gitUrl: repo.clone_url,
                    gitBranch: repo.default_branch,
                    gitAuthType: 'github-oauth',
                    gitToken: getGitHubToken(),
                    gitProjectPath: '',
                  });
                  if (res.error) {
                    notification.error({ message: `Error - ${res.error}` });
                  } else {
                    if (props.onSave) {
                      props.onSave();
                    }
                    notification.success({ message: `Ready` });
                  }
                  refetch();
                }}
              >
                new repo for this project
              </Button>

              <Item label="Repository">
                <Select style={spaceWidth} onChange={onSelectGithubRepo}>
                  {gitReposArray.map(repo => (
                    <Option key={repo.id}>
                      {repo.full_name}
                      <Text italic style={{ color: '#999999' }}>
                        {repo.description}
                      </Text>
                    </Option>
                  ))}
                </Select>
              </Item>

              {gitReposBranches && (
                <Item label="Branch">
                  <Select style={spaceWidth} onChange={e => setDstBranch(e)}>
                    {gitReposBranches.map(branch => (
                      <Option key={branch.name}> {branch.name} </Option>
                    ))}
                  </Select>
                </Item>
              )}
            </>
          )
        ) : (
          formFields.map(field => (
            <Item key={field.name} name={field.name} label={field.label} rules={field.rules}>
              <Input
                placeholder={field.placeholder}
                style={divWidth}
                type={field.type}
                addonAfter={
                  <Popover placement="topLeft" title={field.help}>
                    <QuestionCircleTwoTone twoToneColor="#00A58E" />
                  </Popover>
                }
                onChange={e => {
                  const { value } = e.target;
                  e.target.value = value.startsWith('https://') ? value.slice(8) : value;
                }}
              />
            </Item>
          ))
        )}

        <BottomButtons> {props.showInDrawer ? repoIsLinkedFormshowInDrawerTrue() : repoIsLinkedFormshowInDrawerFalse()} </BottomButtons>
      </Form>
    );

  const readyForGitOperationsAlertAlertDescription = (
    <Space direction="vertical">
      <Text>
        Set up a Git Repository Connection to your Project and Click to the <Text strong> "git pull" </Text> button.
      </Text>
      <Text> Projects Store their configuration in Git. This allows you to use Version history. </Text>
    </Space>
  );

  const readyForGitOperationsAlertAlertAction = (
    <Button type="primary" onClick={() => projectGitPull(projectId)}>
      Pull files from Git
    </Button>
  );

  const readyForGitOperationsAlert = () =>
    readyForGitOperations &&
    !props.showInDrawer &&
    data?.ProjectController_getProject &&
    gitInitialized === false && (
      <Space direction="vertical" style={spaceWidth}>
        {emptySpace()}
        <Alert
          showIcon
          type="error"
          key={'gitInitialized'}
          message="Git is not Initialized"
          description={readyForGitOperationsAlertAlertDescription}
          action={readyForGitOperationsAlertAlertAction}
        />
      </Space>
    );

  const showInDrawerAlertDivMessage = (
    <Space direction="vertical">
      <Text>
        You can use Github and Gitlab Repositories. You can create token in your
        <Tooltip color="#115EA3" title="Click here to login through your GitHub Account">
          <Text strong>
            <a href={'https://github.com/settings/tokens?type=beta'} target="_blank">
              &nbsp;GitHub&nbsp;
            </a>
          </Text>
        </Tooltip>
        or
        <Tooltip color="#115EA3" title="Click here to login through your GitLab Account">
          <Text strong>
            <a href={'https://docs.gitlab.com/ee/user/project/settings/project_access_tokens.html#create-a-project-access-token'} target="_blank">
              &nbsp;GitLab&nbsp;
            </a>
          </Text>
        </Tooltip>
        accounts. Project will use write and read access.
      </Text>
    </Space>
  );

  const showInDrawerAlertDiv = () => (
    <div hidden={props.showInDrawer}>
      <Alert
        showIcon
        type="info"
        message={showInDrawerAlertDivMessage}
        action={<ProjectGitConfigurationHelp btnText={<QuestionCircleTwoTone twoToneColor="#00A58E" />} />}
      />
    </div>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      {errorAlert()}
      {showInDrawerAlert()}
      {linkedUI()}
      {showInDrawerAlertDiv()}
      <Text />
      {repoIsLinkedForm()}
      {readyForGitOperationsAlert()}
    </Space>
  );
};
