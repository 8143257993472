import { useState } from 'react';
import { iDeployment } from 'shared/deployment';
import { Form, InputNumber, Row, Switch, Typography } from 'antd';
import { ulStyleB, liStyleA, labelStyleA, switchStyleA, liStyleB, ulStyleA, rowStyleA, labelStyleB, formStyleA, inputStyleA } from 'utils/styles';
import { rowStyleB, ulStyleC, cardTextStyles, inputStyleB } from 'utils/styles';

const { Text } = Typography;
const { Item } = Form;

interface iServicesRequestsProps {
  deployment: iDeployment;
  value: any;
  UIshowRequestsConfig: any;
}

export function ServicesRequests(props: iServicesRequestsProps) {
  const { deployment, value, UIshowRequestsConfig } = props;

  const services: any = deployment.services || {};

  const [stateService, setServiceState] = useState(!!services[value]?.enabled);
  const [stateRequestCpu, setRequestCpuState] = useState(services[`${value}`]?.requests?.cpu);
  const [stateRequestMemory, setRequestMemoryState] = useState(services[`${value}`]?.requests?.memory);
  const [stateLimitCpu, setLimitCpuState] = useState(services[`${value}`]?.limits?.cpu);
  const [stateLimitMemory, setLimitMemoryState] = useState(services[`${value}`]?.limits?.memory);
  const [stateReplicas, setReplicasState] = useState(services[`${value}`]?.replicas);
  const cpuRec = 0;

  return (
    <ul style={ulStyleB}>
      <li style={liStyleA}>
        <label htmlFor={value.replace('service_', 'Service ')} style={labelStyleA}>
          {value.replace('service_', 'Service ')}
        </label>
        <Item key={value} name={`service.${value}.enabled`} valuePropName="checked">
          <Switch
            id={value.replace('service_', 'Service ')}
            onChange={(checked: any) => setServiceState(checked)}
            checkedChildren="On"
            unCheckedChildren="OFF"
            defaultChecked={stateService}
            style={switchStyleA}
          />
        </Item>
      </li>

      {stateService && UIshowRequestsConfig && (
        <li style={liStyleB}>
          <ul style={ulStyleA}>
            <Text> Requests: </Text>

            <li>
              <Row style={rowStyleA}>
                <label htmlFor={`service.${value}.requests.cpu`} style={labelStyleB}>
                  cpu {cpuRec ? <span>({cpuRec})</span> : ''}
                </label>
                <Item name={`service.${value}.requests.cpu`} style={formStyleA}>
                  <InputNumber
                    id={`${value}.requests.cpu`}
                    step={stateRequestCpu === 10 ? 990 : 1000}
                    min={10}
                    max={stateLimitCpu}
                    defaultValue={stateRequestCpu}
                    onChange={(value: any) => setRequestCpuState(value)}
                    style={inputStyleA}
                  />
                </Item>
              </Row>
            </li>

            <li>
              <Row style={rowStyleA}>
                <label htmlFor={`service.${value}.requests.memory`} style={rowStyleB}>
                  memory
                </label>
                <Item name={`service.${value}.requests.memory`} style={formStyleA}>
                  <InputNumber
                    id={`${value}.requests.memory`}
                    step={stateRequestMemory === 10 ? 990 : 1000}
                    min={10}
                    max={stateLimitMemory}
                    defaultValue={stateRequestMemory}
                    onChange={(value: any) => setRequestMemoryState(value)}
                    style={inputStyleA}
                  />
                </Item>
              </Row>
            </li>
          </ul>

          <ul style={ulStyleA}>
            <Text> Limits: </Text>

            <li>
              <Row style={rowStyleA}>
                <label htmlFor={`service.${value}.limits.cpu`} style={labelStyleB}>
                  cpu &nbsp;
                </label>
                <Item name={`service.${value}.limits.cpu`} style={formStyleA}>
                  <InputNumber
                    id={`${value}.limits.cpu`}
                    step={stateLimitCpu === 10 ? 990 : 1000}
                    min={10}
                    max={128000}
                    defaultValue={stateLimitCpu}
                    onChange={(value: any) => setLimitCpuState(value)}
                    style={inputStyleA}
                  />
                </Item>
              </Row>
            </li>

            <li>
              <Row style={rowStyleA}>
                <label htmlFor={`service.${value}.limits.memory`} style={labelStyleB}>
                  memory &nbsp;
                </label>
                <Item name={`service.${value}.limits.memory`} style={formStyleA}>
                  <InputNumber
                    id={`${value}.limits.memory`}
                    step={stateLimitMemory === 10 ? 990 : 1000}
                    min={10}
                    max={128000}
                    defaultValue={stateLimitMemory}
                    onChange={(value: any) => setLimitMemoryState(value)}
                    style={inputStyleA}
                  />
                </Item>
              </Row>
            </li>
          </ul>

          <ul className={'replicas'} style={ulStyleC}>
            <li>
              <Text style={cardTextStyles}> Replicas: </Text>
              <Item name={`service.${value}.replicas`}>
                <InputNumber min={1} max={50} defaultValue={stateReplicas} onChange={(value: any) => setReplicasState(value)} style={inputStyleB} />
              </Item>
            </li>
          </ul>
        </li>
      )}
    </ul>
  );
}
