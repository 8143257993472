import _ from 'lodash';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApiQuery } from 'utils/common';
import { projectService } from 'services/project.service';
import { iProject } from 'interface/project';
import { iCloudHelmVarMappingRule } from 'shared/project.interface';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { TitleUIRow } from 'layout/TitleUI';
import { Alert, Button, Col, Collapse, Form, Input, Radio, Row, Select, Skeleton, Space, Switch, Tag, Tooltip, Typography, notification } from 'antd';
import { CloseOutlined, InfoCircleFilled, LinkOutlined, LockOutlined, QuestionCircleTwoTone, UnlockOutlined } from '@ant-design/icons';
import { buttonWidth, floatingRight, spaceWidth } from 'utils/styles';
import { iProjectModel } from 'shared/deployment';

export interface ProjectOneServiceSettingsFormProps {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
}

const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;
const { Group } = Radio;
const { Panel } = Collapse;
const { List } = Form;

const getTitle = (
  varName: string | number | boolean | ReactElement<string> | Iterable<ReactNode>,
  hardcodedDefaultValue: string | number | boolean | ReactElement<string> | Iterable<ReactNode>,
  varValueSrcType: string,
  UIVarTitle: string | number | boolean | ReactElement<string> | Iterable<ReactNode>,
) => {
  const title = (
    <>
      {UIVarTitle && varName != UIVarTitle && <Text strong> {UIVarTitle} </Text>}
      <Text italic> {varName} </Text>
      <div className="ServiceVariableValue"> {hardcodedDefaultValue} </div>
    </>
  );

  const dataUI = (
    <Tooltip color="#115EA3" placement="bottom" title="Value will be defined in deployment configuration UI">
      <Tag color="blue">
        <UnlockOutlined /> UI
      </Tag>
    </Tooltip>
  );

  const dataVar = (
    <Tooltip color="#115EA3" placement="bottom" title="It is a link to the other variable">
      <Tag color="cyan">
        <LinkOutlined /> var
      </Tag>
    </Tooltip>
  );

  const dataHardCoded = (
    <Tooltip color="#115EA3" placement="bottom" title="Hardcoded">
      <Tag>
        <LockOutlined /> Hardcoded
      </Tag>
    </Tooltip>
  );

  const varValueSrcTypeUI =
    varValueSrcType === 'src_deployment_ui'
      ? dataUI
      : varValueSrcType === 'src_deployment_var'
      ? dataVar
      : varValueSrcType === 'src_hardcoded'
      ? dataHardCoded
      : null;

  return { title, varValueSrcTypeUI };
};

export const OneVariable = props => {
  const { subField, subOpt, form, index, setTitle } = props;

  let item: iCloudHelmVarMappingRule = {
    varName: `Variable Name ${index + 1}`,
    varApplyMethod: 'method_replace',
    varValueType: 'any',
    varValueSrcType: 'src_hardcoded',
    hardcodedDefaultValue: '',
    refToSpecVar: '',
    UIVarTitle: '',
    UIVarInputType: '',
  };

  if (form.getFieldsValue()?.rules && form.getFieldsValue()?.rules[subField.key]) item = form.getFieldsValue()?.rules[subField.key];

  const [varName, Dset_varName] = useState(item?.varName);
  const [UIVarTitle, Dset_UIVarTitle] = useState(item?.UIVarTitle);
  const [varValueSrcType, Dset_varValueSrcType] = useState(item?.varValueSrcType);
  const [hardcodedDefaultValue, Dset_hardcodedDefaultValue] = useState(item?.hardcodedDefaultValue);
  const [varValueType, Dset_varValueType] = useState(item?.varValueType);

  const set_varName = _.debounce(Dset_varName, 500);
  const set_UIVarTitle = _.debounce(Dset_UIVarTitle, 500);
  const set_varValueSrcType = _.debounce(Dset_varValueSrcType, 500);
  const set_hardcodedDefaultValue = _.debounce(Dset_hardcodedDefaultValue, 500);
  const set_varValueType = _.debounce(Dset_varValueType, 500);

  useEffect(() => {
    setTitle(getTitle(varName, hardcodedDefaultValue, varValueSrcType, UIVarTitle));
  }, [varName, UIVarTitle, varValueSrcType, hardcodedDefaultValue, varValueType]);

  const varValueSrcTypeData = () => {
    const varValueSrcTypeOptions = [
      { value: 'src_deployment_ui', label: 'Deployment Configuration' },
      { value: 'src_deployment_var', label: 'Deployment Specific Variables' },
      { value: 'src_hardcoded', label: 'Values defined at a Project Level' },
    ];
    return (
      <Col span={17}>
        <Space direction="horizontal">
          <Item name={[subField.name, 'varValueSrcType']} shouldUpdate>
            <Group style={spaceWidth} defaultValue={varValueSrcType || 'src_hardcoded'} onChange={e => set_varValueSrcType(e.target.value)}>
              {varValueSrcTypeOptions.map(option => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Group>
          </Item>
        </Space>
      </Col>
    );
  };

  const varValueTypeData = () => {
    const varValueTypeOptions = [
      { value: 'any', label: 'any' },
      { value: 'number', label: 'number' },
      { value: 'string', label: 'string' },
      { value: 'boolean', label: 'boolean' },
      { value: 'object', label: 'object' },
      { value: 'array', label: 'array' },
    ];
    return (
      <Col span={17}>
        <Item name={[subField.name, 'varValueType']} shouldUpdate>
          <Select style={spaceWidth} defaultValue={varValueType || 'any'} onChange={set_varValueType} placeholder="Enter Input Type Here">
            {varValueTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
    );
  };

  const uiVarInputTypeData = () => {
    const uiVarInputTypeOptions = [
      { value: 'text', label: 'Text' },
      { value: 'number', label: 'Number' },
      { value: 'password', label: 'Password' },
      { value: 'textarea', label: 'Textarea' },
      { value: 'boolean', label: 'Boolean' },
      { value: 'json', label: 'JSON' },
      { value: 'yaml', label: 'YAML' },
      { value: 'color', label: 'Color' },
      { value: 'email', label: 'Email' },
      { value: 'array', label: 'Array' },
    ];
    return (
      <Col span={17}>
        <Item name={[subField.name, 'UIVarInputType']} shouldUpdate>
          <Select style={spaceWidth} defaultValue={item.UIVarInputType || 'text'} placeholder="Enter UI Variable Input Type Here">
            {uiVarInputTypeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
    );
  };

  const varApplyMethodData = () => {
    const varApplyMethodOptions = [
      { value: 'method_replace', label: 'Replace - Remove old value and set New value' },
      { value: 'method_merge', label: 'Merge if possible (for JSON and Array types) and Replace for other types' },
    ];
    return (
      <Col span={17}>
        <Item shouldUpdate name={[subField.name, 'varApplyMethod']} label="How to set value">
          <Select style={spaceWidth} defaultValue={item.varApplyMethod || 'method_replace'}>
            {varApplyMethodOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>
      </Col>
    );
  };

  const removeButton = () => {
    const handleRemoveButton = () => {
      setTitle(null);
      subOpt.remove(subField.name);
      return false;
    };
    return (
      <Row>
        <Col>
          <Button onClick={handleRemoveButton} style={floatingRight}>
            Remove this Variable
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <div style={{ marginLeft: 10 }}>
      <Row>
        <Col span={1} />
        <Col span={6}>
          <Space direction="horizontal">
            <Text strong> Variable Path </Text>
            <Button type="link">
              <Tooltip color="#115EA3" title="Please Enter Variable Path Here to access the Information in the given Input Area">
                <InfoCircleFilled />
              </Tooltip>
            </Button>
          </Space>
        </Col>
        <Col span={17}>
          <Item name={[subField.name, 'varName']} shouldUpdate>
            <Input placeholder="Enter Variable Path Here" onChange={e => set_varName(e?.target?.value)} />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={1} />
        <Col span={6}>
          <Space direction="horizontal">
            <Text strong> Variable Defined In </Text>
            <Button type="link">
              <Tooltip color="#115EA3" title=" Select the Variable to configure according to the requirement">
                <InfoCircleFilled />
              </Tooltip>
            </Button>
          </Space>
        </Col>
        {varValueSrcTypeData()}
      </Row>
      <Row>
        <Col span={1} />
        <Col span={6}>
          <Space direction="horizontal">
            <Text strong> Input type </Text>
          </Space>
        </Col>
        {varValueTypeData()}
      </Row>
      <Row>
        <Col span={1} />
        <Col span={6}>
          <Space direction="horizontal">
            <Text strong> Default Value </Text>
          </Space>
        </Col>
        <Col span={17}>
          <Item name={[subField.name, 'hardcodedDefaultValue']} shouldUpdate>
            <Input placeholder="Enter Default Value Here" onChange={e => set_hardcodedDefaultValue(e?.target?.value)} />
          </Item>
        </Col>
      </Row>
      <Row hidden={varValueSrcType !== 'src_deployment_ui'}>
        <Col span={1} />
        <Col span={6}>
          <Space direction="horizontal">
            <Text strong> UI Variable Title </Text>
          </Space>
        </Col>
        <Col span={17}>
          <Item name={[subField.name, 'UIVarTitle']} shouldUpdate>
            <Input placeholder="Enter UI Variable Title Here" onChange={e => set_UIVarTitle(e?.target?.value)} />
          </Item>
        </Col>
      </Row>
      <Row hidden={varValueSrcType !== 'src_deployment_ui'}>
        <Col span={1} />
        <Col span={6}>
          <Space direction="horizontal">
            <Text strong> UI Variable Input Type </Text>
          </Space>
        </Col>
        {uiVarInputTypeData()}
      </Row>
      <Row hidden={varValueSrcType !== 'src_deployment_var'}>
        <Col span={1} />
        <Col span={6}>
          <Space direction="horizontal">
            <Text strong> Ref to Deployment internal variable For example ref to deployment name "deployment.name" </Text>
          </Space>
        </Col>
        <Col span={17}>
          <Item name={[subField.name, 'refToSpecVar']} shouldUpdate>
            <Input placeholder="Enter Ref Here" />
          </Item>
        </Col>
      </Row>
      <Row hidden={!['object', 'array'].includes(varValueType)}>
        <Col span={1} />
        <Col span={6}>
          <Space direction="horizontal">
            <Text strong> How to set value </Text>
          </Space>
        </Col>
        {varApplyMethodData()}
      </Row>
      {removeButton()}
    </div>
  );
};

export const ProjectOneServiceVariables = (props: ProjectOneServiceSettingsFormProps) => {
  const projectId = props.project.id;
  const serviceName = props.serviceName;
  const tabType = props.tabType;

  const [form] = Form.useForm();
  const [service, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(projectId, serviceName),
    [projectId, serviceName, tabType],
  );

  useEffect(() => {
    service?.rules
      ? (form.setFieldsValue({ rules: service.rules || [] }),
        set_titlesArr(service.rules.map(rule => getTitle(rule.varName, rule.hardcodedDefaultValue, rule.varValueSrcType, rule.UIVarTitle))))
      : null;
  }, [service]);

  const [titlesArr, set_titlesArr] = useState([]);
  const [showFullVars, setShowFullVars] = useState(false);

  if (serviceError || serviceLoader) return <Skeleton active={true} loading={true} />;

  const alertData = () => {
    const alertDescription = () => {
      const linkOptions = [
        { text: 'Custom YAML Template', link: 'custom-yaml' },
        { text: 'Routes', link: 'route' },
        { text: 'Kube Details', link: 'deployment-configuration' },
        { text: 'Post Processing', link: 'post-processing-rules' },
      ];
      return (
        <>
          <Text>
            You can add new variables for the service <Text strong> {serviceName} </Text> - just add new variables to the schema.
          </Text>
          <Text> If you use Helm Chart - you can redefine all Helm Chart variables here. </Text>
          <Space direction="horizontal">
            <li>
              <Text> You can use these variables in the Helm Chart templates: </Text>
              {linkOptions.map(({ text, link }, index, array) => (
                <Text key={link} strong>
                  <Link to={`/project/${projectId}/settings/services/${serviceName}/${link}`}> {text} </Link>
                  {index < array.length - 1 && ', '}
                </Text>
              ))}
              .
            </li>
          </Space>
        </>
      );
    };

    const alertAction = (
      <Tooltip color="#115EA3" placement="left" title="Click here to know more...">
        <a href="https://www.unifie.cloud/post/variables" target="_blank">
          <Button type="text">
            <QuestionCircleTwoTone twoToneColor="#00A58E" />
          </Button>
        </a>
      </Tooltip>
    );

    return (
      <Alert
        showIcon
        type="info"
        message={`Variables for service ${serviceName}`}
        description={alertDescription()}
        action={alertAction}
        style={{ marginBottom: 20 }}
      />
    );
  };

  const mainFormData = () => {
    const handleSubmit = async data => {
      const res = await projectService.setProjectServices(projectId, { tabType: tabType, name: serviceName, data: data });
      res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
    };

    return (
      <Form
        data-qa="ProjectOneServiceVariables"
        className={showFullVars ? `ServiceVariablesFullValues` : `ServiceVariablesShortValues`}
        onFinish={handleSubmit}
        form={form}
        name="dynamic_form_complex"
        autoComplete="off"
        initialValues={{ rules: service?.rules || [] }}
      >
        <List name="rules">
          {(subFields, subOpt) => {
            const handleOnClickAdd = () =>
              subOpt.add({
                varName: `NewVariableName${subFields.length + 1}`,
                varApplyMethod: 'method_replace',
                varValueType: 'any',
                varValueSrcType: 'src_hardcoded',
                hardcodedDefaultValue: '',
                refToSpecVar: '',
                UIVarTitle: '',
                UIVarInputType: '',
              });
            return (
              <>
                <Collapse size="small" style={{ backgroundColor: 'transparent' }}>
                  {subFields.map((subField, index) => {
                    const panelExtra = () => {
                      const commonTag = () => {
                        const handleOnClickTag = () => {
                          titlesArr[index] = null;
                          set_titlesArr([...titlesArr.filter(v => v !== null)]);
                          subOpt.remove(subField.name);
                          return false;
                        };
                        return (
                          <Tag onClick={handleOnClickTag}>
                            <CloseOutlined />
                          </Tag>
                        );
                      };
                      return (
                        <Space>
                          {titlesArr[index]?.varValueSrcTypeUI}
                          {/* {commonTag()} */}
                        </Space>
                      );
                    };

                    const setTitleData = titleObj => {
                      titlesArr[index] = titleObj;
                      set_titlesArr([...titlesArr.filter(v => v !== null)]);
                    };

                    return (
                      <Panel header={titlesArr[index]?.title || `NewVariableName${index + 1}`} key={index} extra={panelExtra()}>
                        <OneVariable key={index} subField={subField} subOpt={subOpt} form={form} index={index} setTitle={setTitleData} />
                      </Panel>
                    );
                  })}
                </Collapse>
                <Item>
                  <BottomButtons>
                    <Space>
                      <Button type="primary" htmlType="submit" style={buttonWidth}>
                        Save
                      </Button>
                      <Button type="primary" onClick={handleOnClickAdd} style={buttonWidth}>
                        Add a Variable
                      </Button>
                    </Space>
                  </BottomButtons>
                </Item>
              </>
            );
          }}
        </List>
      </Form>
    );
  };

  return (
    <>
      <TitleUIRow title={`Variables configuration for ${serviceName}`} />
      <Space style={{ marginTop: 20, marginBottom: 20 }}>
        <Text> Variable values: </Text>
        <Switch checkedChildren="Large" unCheckedChildren="Short" checked={showFullVars} onChange={setShowFullVars} />
      </Space>
      {alertData()}
      {mainFormData()}
    </>
  );
};
