import { Flex } from 'antd';
import { connect } from 'react-redux';
import { mapDispatchToProps } from 'services/actions';
import { IMainStoreProps, mapStateToProps } from 'services/reducer';

export const WidgetArea = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: any) => {
  // console.log('WidgetArea props', props);
  const store: IMainStoreProps = props?.store;
  return <Flex justify={'flex-end'}>{store?.page?.widgets}</Flex>;
});
