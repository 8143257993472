import { iRegionModel } from 'shared/deployment';
import { apiQuery, iBaseAjaxAnswer } from '../utils/common';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';

// RegionsController_getRegionById

export const useRegionsController_getRegionById = (
  regionId: number,
): { loading: boolean; error: any; region: iRegionModel; refetch: any; stopPolling: any; startPolling: any } => {
  const query = useAuthedQuery(
    gql`
      query RegionsController_getRegionById($regionId: Int!) {
        RegionsController_getRegionById(regionId: $regionId) {
          id
          tenant
          name
          regionName
          title
          kubernetesVersion
          cloudProvider
          isPublic
          plugins
          deactivatedPlugins
          isFreezed
          loadBalancerURL
          loadBalancerType
          tags
          pluginOpencostHost
          usePluginOpencost
          configureStatus
          integrationsStatus
          useCostExplorerApi
          AWS_Account_ID
          CE_AWS_ACCESS_KEY_ID
          deletedAt
          lastSyncTime
          noSyncTime
          allowToAddDeployments
          selectedIntegrations
          useTerraform
          TF_AWS_SECRET_ACCESS_KEY
          TF_AWS_ACCESS_KEY_ID
        }
      }
    `,
    {
      skip: !Number(regionId),
      variables: { regionId: Number(regionId) },
    },
  );

  const region: iRegionModel = query?.data?.RegionsController_getRegionById;
  const r: any = { ...query, region };
  return r;
};

class RegionsService {
  async newRegion(region: iRegionModel): Promise<iBaseAjaxAnswer<iRegionModel>> {
    const res = await apiQuery('post', `/api/regions/createRegion/new`, region);
    return res;
  }

  /**
   */
  async updateOwnRegion(regionId: number, region: iRegionModel): Promise<iBaseAjaxAnswer<iRegionModel>> {
    const res = await apiQuery('post', `/api/regions/updateOwnRegion/${regionId}`, region);
    return res;
  }
}

const regionsService = new RegionsService();
export { regionsService, RegionsService };
