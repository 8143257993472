import moment from 'moment';
import gql from 'graphql-tag';
import { useState } from 'react';
import { useAuthedQuery } from 'utils/qlAuth';
import { iRegionModel } from 'shared/deployment';
import { Alert, Button, Col, Flex, Row, Select, Skeleton, Space, Table, Tooltip, Typography } from 'antd';
import { ReloadOutlined, RollbackOutlined } from '@ant-design/icons';
import { spaceWidth } from 'utils/styles';

const { Text } = Typography;
const { Option } = Select;

interface iClusterEventsProps {
  region: iRegionModel;
}

export function ClusterEvents(props: iClusterEventsProps) {
  const [hovered, setHovered] = useState(false);

  const { loading, error, data, refetch } = useAuthedQuery(
    gql`
      query ApiAgentController_listEventForAllNamespaces($regionId: Int!) {
        ApiAgentController_listEventForAllNamespaces(regionId: $regionId) {
          status
          message
          events
        }
      }
    `,
    { variables: { regionId: props.region.id } },
  );

  const [filter, setFilter] = useState<string | null>(null);

  if (loading) return <Skeleton active={true} loading={true} />;

  const ClusterEventsList: any[] = data?.ApiAgentController_listEventForAllNamespaces?.events || [];
  const filteredEvents = filter ? ClusterEventsList.filter(item => item.involvedObject.name === filter) : ClusterEventsList;
  const handleFilterChange = (value: string) => setFilter(value === 'RESET' ? null : value);

  const eventActions = () => {
    const eventFilter = () => (
      <Col span={22}>
        <Select placeholder="Filter by pod name" showSearch value={filter || undefined} onChange={handleFilterChange} style={spaceWidth}>
          <Option value="RESET">
            <Flex justify="flex-end">
              <Text strong type="warning">
                Reset <RollbackOutlined />
              </Text>
            </Flex>
          </Option>
          {Array.from(new Set(ClusterEventsList.map(item => item.involvedObject.name))).map((name, index) => (
            <Option key={index.toString()} value={name}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
    );

    const eventRefresh = () => (
      <Col span={2}>
        <Flex justify="flex-end">
          <Tooltip color="#115EA3" placement="left" title="Click here to refresh the events">
            <Button
              type="dashed"
              icon={<ReloadOutlined spin={hovered} />}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={async () => await refetch()}
            >
              Refresh
            </Button>
          </Tooltip>
        </Flex>
      </Col>
    );

    return (
      <Row>
        {eventFilter()}
        {eventRefresh()}
      </Row>
    );
  };

  const eventData = () => {
    const timeFormat = (timestamp: string) => moment(timestamp).format('DD-MM-YYYY ~ hh:mm:ss A');
    const columns = [
      { title: 'Pod name', dataIndex: ['metadata', 'name'], key: 'name' },
      { title: 'Namespace', dataIndex: ['metadata', 'namespace'], key: 'namespace' },
      { title: 'Status', dataIndex: 'type', key: 'type' },
      { title: 'Reason', dataIndex: 'reason', key: 'reason' },
      { title: 'Event message', dataIndex: 'message', key: 'message' },
      { title: 'Time', dataIndex: 'lastTimestamp', key: 'lastTimestamp', render: timeFormat },
    ];
    return <Table columns={columns} dataSource={filteredEvents} rowKey={record => record.involvedObject.uid} />;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Alert showIcon type="info" message="Here you can see the Cluster Events." />
      <Skeleton active={true} loading={loading && !error && !ClusterEventsList}>
        <Space direction="vertical" style={spaceWidth}>
          {eventActions()}
          {eventData()}
          <Text />
        </Space>
      </Skeleton>
    </Space>
  );
}
