import { useAuthedMutationWithNotification, useAuthedQuery } from '../utils/qlAuth';
import gql from 'graphql-tag';
import { authService } from './auth.service';

export const projectVersionsList = async (projectId: number, filter: object) => {
  return await authService.getApolloClient().query({
    query: gql`
      query GraphqlVersionsController_projectVersionsList($projectId: Int!, $filter: JSON!) {
        GraphqlVersionsController_projectVersionsList(projectId: $projectId, filter: $filter) {
          id
          channel
          name
          build
          title
          description
          isAvailable
          images
          updatedAt
          createdAt
        }
      }
    `,
    variables: { projectId: projectId, filter: filter },
  });
};

export const createNewVersion = async (projectId: number, data: object) => {
  return await authService.getApolloClient().query({
    query: gql`
      mutation GraphqlVersionsController_addProjectVersions($projectId: Int!, $data: JSON!) {
        GraphqlVersionsController_addProjectVersions(projectId: $projectId, data: $data) {
          result
        }
      }
    `,
    variables: { projectId: projectId, data: data },
  });
};

export const updateVersion = async (Id: number, data: object) => {
  return await authService.getApolloClient().query({
    query: gql`
      mutation GraphqlVersionsController_updateProjectVersions($id: Int!, $data: JSON!) {
        GraphqlVersionsController_updateProjectVersions(id: $id, data: $data) {
          result
        }
      }
    `,
    variables: { id: Id, data: data },
  });
};

export const deleteVersion = async (Id: number) => {
  return await authService.getApolloClient().query({
    query: gql`
      mutation GraphqlVersionsController_deleteProjectVersions($id: Int!) {
        GraphqlVersionsController_deleteProjectVersions(id: $id) {
          result
        }
      }
    `,
    variables: { id: Id },
  });
};
