import gql from 'graphql-tag';
import { ReactElement, ReactNode, ReactPortal, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { authService } from 'services/auth.service';
import { Row, Col, Typography, Button, Modal, Skeleton, Tag, Card, Space, Tooltip, Flex } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { buttonWidth, spaceWidth, templateMargin } from 'utils/styles';
import { NewProjectForm } from 'components/Projects/NewProjectForm';

interface iExistingContent {
  name: any;
  id: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  title: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const { Text } = Typography;

/**
 * Create deployment from (/new route)
 * @returns
 */
export default function DeploymentFromTemplate() {
  const params: any = useParams();
  const preSelectedProject = Number(params?.projectId) || null;

  const [activeProject, setProject] = useState(preSelectedProject || '');
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const history = useHistory();
  const user = useUser();

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        PreconfiguredProjectsController_getPreconfiguredProjects {
          id
          name
          title
          description
          logo
          tags
        }
      }
    `,
    {},
  );

  const [createDeployment, newDeployment] = useAuthedMutationWithNotification(gql`
    mutation DeploymentsController_createDeployment($projectId: Int!, $name: String!, $tags: [Int]) {
      DeploymentsController_createDeployment(projectId: $projectId, deploymentName: $name, deploymentTags: $tags) {
        id
      }
    }
  `);

  if (error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  if (loading) return <Skeleton active={true} loading={true} />;

  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;

  const handleCreateFromTemplate = async (projectId, name) => {
    // const similarProjects = (data?.ProjectController_getProjectList || []).filter(project => project.id === projectId);
    // @todo: Add call to ProjectController_findSimilarProjects(projectId)
    // Can solve: https://nanoheal.atlassian.net/browse/DP-635
    // And if it returns a project, ask user if they want to use that project instead of creating a new one.
    // It needed for avoinding duplicating projects.

    const result = await authService.getApolloClient().query({
      query: gql`
        query ProjectController_findSimilarProjects($projectId: Int!) {
          ProjectController_findSimilarProjects(projectId: $projectId) {
            id
            name
            title
            description
            logo
            tags
          }
        }
      `,
      variables: { projectId: projectId },
    });

    const similarProjects = result?.data?.ProjectController_findSimilarProjects || [];

    const existingContent = (
      <>
        A similar project already exists. Do you want to use the existing project or create a new copy?
        {similarProjects.map((elem: iExistingContent) => {
          const handleButtonClick = async () =>
            await createDeployment({ variables: { projectId: elem.id, name: `New deployment ${elem.name}`, tags: selectedTags } });
          return (
            <Button onClick={handleButtonClick}>
              Copy from ({elem.id}) {elem.title}
            </Button>
          );
        })}
      </>
    );

    const existingProjects = {
      title: 'Use Existing Project',
      icon: <InfoCircleOutlined />,
      content: existingContent,
      cancelText: 'Cancel',
      okText: 'Create a new copy',
      async onOk() {
        await createDeployment({ variables: { projectId, name: `New deployment ${name}`, tags: selectedTags } });
      },
    };

    const createContent = (
      <Space direction="vertical">
        <Text> The project template will be automatically copied from the general template library and added to your own project list. </Text>
        <Text> You can find it on the Projects tab and edit it if you wish. </Text>
      </Space>
    );

    const creatingProjects = {
      title: 'Creating a deployment from a project template',
      icon: <InfoCircleOutlined />,
      content: createContent,
      async onOk() {
        await createDeployment({ variables: { projectId, name: `New deployment ${name}`, tags: selectedTags } });
      },
      onCancel() {
        console.log('Cancel');
      },
    };

    Modal.confirm(similarProjects.length > 0 ? existingProjects : creatingProjects);
  };

  const newDeploymentId = newDeployment?.data?.DeploymentsController_createDeployment?.id;
  if (newDeploymentId) {
    history.push(`/app/${newDeploymentId}/setting/details`);
    return <Skeleton active={true} loading={true} />;
  }

  const extraButton = () => (
    <Flex justify="flex-end">
      <NewProjectForm
        openBtnUI={
          <Tooltip color="#115EA3" placement="left" title="Click here to create a template">
            <Button type="primary" style={buttonWidth}>
              Create a template
            </Button>
          </Tooltip>
        }
      />
    </Flex>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      {/* {extraButton()} */}
      <Row gutter={[16, 16]}>
        {data.PreconfiguredProjectsController_getPreconfiguredProjects.map(elem => {
          const cardExtra = (
            <Tooltip
              color="#115EA3"
              placement="leftTop"
              title="You can deploy preconfigured ready-made project. It allow to deploy any software in your cluster in several minutes."
            >
              <Button data-qa="PreconfiguredProjects-Deploy-btn" onClick={() => handleCreateFromTemplate(elem.id, elem.name)}>
                Deploy
              </Button>
            </Tooltip>
          );

          const deployTags = () => (
            <Space direction="horizontal">
              {elem.tags &&
                elem.tags.map(tag => (
                  <Tag color="green" key={tag}>
                    {tag}
                  </Tag>
                ))}
            </Space>
          );

          const templateDeployData = () => (
            <Col key={elem.id} xs={24} sm={12} md={8}>
              <Card size="small" type="inner" title={elem.title} extra={cardExtra} style={templateMargin}>
                <Space direction="vertical">
                  <Space direction="horizontal">
                    <LogoAvatar logo={elem.logo} name={elem.title} />
                    <Text type="secondary"> {elem.description} </Text>
                  </Space>
                  <Text />
                  <Text />
                  {deployTags()}
                </Space>
              </Card>
            </Col>
          );

          return templateDeployData();
        })}
      </Row>
    </Space>
  );
}
