import { ReactElement, useState } from 'react';
import { Button, Form, Input, notification, Space, Typography } from 'antd';
import { CopyServiceButton } from './CopyService';
import { projectService } from 'services/project.service';
import { useHistory } from 'react-router-dom';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { iDeployment } from 'shared/deployment';

const { Text, Title } = Typography;

export const NewService = (props: { deployment: iDeployment }): ReactElement => {
  const projectId = props.deployment?.projectId;
  const history = useHistory();
  const [serviceType, setServiceType] = useState(null);

  const handleCreateService = async (e: any) => {
    if (e.name === 'new') {
      // name `new` is reserved for our routes
      return notification.error({ message: `Error - use another service name` });
    }

    const newService: iCloudProjectServiceYamlSpecs = {
      name: e.name,
      uiType: serviceType,
    };

    const res = await projectService.setNewService(projectId, newService);
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    } else {
      notification.success({ message: `Ready` });
    }

    history.push(`/app/${props.deployment.id}/configuration/services/${e.name}/main`);
  };

  return (
    <>
      <h1>Add a Service</h1>
      Select Service Type to add or Use Prebuilt Template
      <br />
      <br />
      <Space direction="horizontal">
        <Button type={serviceType === `docker` ? 'primary' : 'default'} onClick={() => setServiceType(`docker`)}>
          Docker image
        </Button>
        <Button type={serviceType === `helm` ? 'primary' : 'default'} onClick={() => setServiceType(`helm`)}>
          Helm chart
        </Button>
        <Button type={serviceType === `k8s` ? 'primary' : 'default'} onClick={() => setServiceType(`k8s`)}>
          Kubernetes yaml file
        </Button>
        <CopyServiceButton project={props?.deployment?.ProjectModel} />
      </Space>
      <br />
      <br />
      {serviceType && (
        <Form onFinish={handleCreateService}>
          <Space direction="vertical">
            <Form.Item key={`name`} name={`name`} label={<Text strong> Service name </Text>} rules={[{ required: true }]}>
              <Input placeholder={`helloworld`} type={`text`} />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Space>
        </Form>
      )}
      {/*
        {serviceType === `docker` && <>docker</>}
        {serviceType === `helm` && <>helm based</>}
        {serviceType === `k8s` && <>k8s</>}
       */}
    </>
  );
};
