import Editor from '@monaco-editor/react';
import { deploymentService } from '../../../../services/deployment.service';
import { useState } from 'react';
import { useApiQuery } from '../../../../utils/common';
import { iDeployment } from 'shared/deployment';
import { SpecsRenderError } from 'shared/yaml';
import { Alert, Col, Collapse, Result, Row, Skeleton, Space, Switch, Tag, Typography } from 'antd';
import { bottomMargin, spaceWidth } from 'utils/styles';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';

export interface iYamlTabProps {
  deployment: iDeployment;
}

const { Text } = Typography;
const { Panel } = Collapse;

export const YamlErrorsUI = (props: { renderErrors: SpecsRenderError[] }) => {
  const [showErrors, setShowErrors] = useState(true);
  const [showWarning, setShowWarning] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const errorsCount = props.renderErrors.filter(err => err.type === 'error').length;
  const warningCount = props.renderErrors.filter(err => err.type === 'warning').length;
  const infoCount = props.renderErrors.filter(err => err.type === 'info').length;
  const colorMap = { error: 'red', warning: 'orange', info: 'blue' };

  return (
    <>
      <Space style={bottomMargin}>
        <Switch checkedChildren={`Errors ${errorsCount}`} unCheckedChildren={`Errors ${errorsCount}`} checked={showErrors} onChange={setShowErrors} />
        <Switch
          checkedChildren={`Warnings ${warningCount}`}
          unCheckedChildren={`Warnings ${warningCount}`}
          checked={showWarning}
          onChange={setShowWarning}
        />
        <Switch checkedChildren={`Info ${infoCount}`} unCheckedChildren={`Info ${infoCount}`} checked={showInfo} onChange={setShowInfo} />
        {errorsCount > 0 && <Tag color="red"> Yaml was created with errors. Synchronisation stopped due to errors. </Tag>}
      </Space>

      <Collapse size="small" defaultActiveKey={[]} style={bottomMargin}>
        {props.renderErrors
          .filter(err =>
            (err.type === 'error' && !showErrors) || (err.type === 'warning' && !showWarning) || (err.type === 'info' && !showInfo) ? false : true,
          )
          .map((error: SpecsRenderError, index) => {
            const color = colorMap[error.type] || 'red';
            const errData = error.error;
            let errorBody: any = <pre> {JSON.stringify(error.error, null, 4)} </pre>;

            if (Array.isArray(errData) && error.groups.includes('shared-cluster-diff')) {
              errorBody = errData.map((item, index) => {
                if (item.kind === 'D') {
                  const specPath = (item.path || []).join('.');
                  return (
                    <>
                      <br />
                      <Text strong> Path: {specPath} </Text>
                      <pre key={index}> {JSON.stringify(item.lhs, null, 4)} </pre>
                    </>
                  );
                }
                return <pre key={index}> {JSON.stringify(item, null, 4)} </pre>;
              });
            }

            const panelHeader = (
              <Space>
                {error.kind && <Tag> kind={error.kind} </Tag>}
                {error.name && <Tag> name={error.name} </Tag>}
                {error.message}
              </Space>
            );

            return (
              <Panel header={panelHeader} key={index} extra={<Tag color={color}> {error.type} </Tag>}>
                {error.kind && <Tag> Kind {error.kind} </Tag>}
                {error.name && <Tag> Name {error.name} </Tag>} <Text strong> {error.message} </Text>
                <br />
                {errorBody}
                {(error.groups || []).map((group, index) => (
                  <Tag key={index}> {group} </Tag>
                ))}
              </Panel>
            );
          })}
      </Collapse>
    </>
  );
};

export const YamlTab = (props: iYamlTabProps) => {
  const [deploymentYAML, error, loading] = useApiQuery(() => deploymentService.debugUI_deploymentYAML(Number(props.deployment.id)));
  const renderErrors: SpecsRenderError[] = deploymentYAML?.renderErrors || [];
  const [isFullscreen, setIsFullscreen] = useState(false);

  if (loading || !deploymentYAML) return <Skeleton active={true} loading={true} />;
  if (error) return <Alert style={bottomMargin} message="" description={`Can not load data: ${error}`} type="error" />;

  const viewButton = () => (
    <Row>
      <Col span={23}>
        <YamlErrorsUI renderErrors={renderErrors} />
      </Col>
      <Col span={1}>
        <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      </Col>
    </Row>
  );

  const infoDescription = `Here you can see result YAML for this Deployment. It is read only.`;
  const errorDescription = `Error in YAML generation. Please check your Deployment and Project settings.`;
  const alertConfigs = { info: { type: 'info', description: infoDescription }, error: { type: 'error', description: errorDescription } };

  const deployementError = () => (
    <>
      {Object.keys(alertConfigs).map(key => (
        <Alert key={key} message="" description={alertConfigs[key].description} type={alertConfigs[key].type} style={bottomMargin} />
      ))}
      {viewButton()}
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        <Editor
          height={isFullscreen ? '100vh' : 'calc(100vh - 200px)'}
          width={`100%`}
          language={`json`}
          value={deploymentYAML.error}
          theme={`GitHub`}
          defaultValue=""
        />
      </FullScreenEditor>
      <br />
      <br />
    </>
  );

  if (deploymentYAML.errorType === 'not-ready')
    return <Result status="404" title="Not ready" subTitle={String(deploymentYAML.error)} style={bottomMargin} />;
  else if (deploymentYAML.errorType === 'render-error') return deployementError();

  const deploymentEditor = () => (
    <Space direction="vertical" style={spaceWidth}>
      <Alert showIcon type="info" key={'info'} message={`Here you can see result YAML for this Deployment. It is read only.`} style={bottomMargin} />
      {viewButton()}
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        <Editor
          height={isFullscreen ? '100vh' : 'calc(100vh - 200px)'}
          width={`100%`}
          language={`yaml`}
          value={deploymentYAML.yaml}
          theme={`GitHub`}
          defaultValue=""
        />
      </FullScreenEditor>
    </Space>
  );

  return deploymentEditor();
};
