import { Tabs, Typography } from 'antd';
import { iRegionModel } from 'shared/deployment';
import { InstallationFile } from '../new-cluster/InstallationFile';

export interface iRegionInstallCommandsProps {
  region: iRegionModel;
}

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

export const RegionUpdateCommands = (props: iRegionInstallCommandsProps) => {
  const installationContent = (
    <Paragraph>
      <Title level={5}> Installing `api-agent` </Title>
      <InstallationFile action="install" hasMetacontroller={true} region={props.region} />
    </Paragraph>
  );

  const removeContent = (
    <Paragraph>
      <Title level={5}> Remove `api-agent` </Title>
      <InstallationFile action="uninstall" hasMetacontroller={true} region={props.region} />
    </Paragraph>
  );

  const updateContent = (
    <Paragraph>
      <Title level={5}> Update `api-agent` </Title>
      <InstallationFile action="update" hasMetacontroller={true} region={props.region} />
    </Paragraph>
  );

  const tabConfigs = [
    { key: 'installation', title: 'Installation', content: installationContent },
    { key: 'update', title: 'Update', content: updateContent },
    { key: 'remove', title: 'Remove', content: removeContent },
  ];

  return (
    <Tabs defaultActiveKey={tabConfigs[0].key}>
      {tabConfigs.map(tab => (
        <TabPane key={tab.key} tab={tab.title}>
          {tab.content}
        </TabPane>
      ))}
    </Tabs>
  );
};
