import './Authentication.scss';
import { Fragment, useState } from 'react';
import { authService } from '../../services/auth.service';
import { Button, Input, message, Typography, Space, Alert, Row } from 'antd';
import { formInput, formButton, cardStyle, textJustify } from 'utils/styles';
import {
  AuthenticationForm,
  AuthImageC,
  BrandLogo,
  ChangePasswordHeading,
  SubText,
  SubTextChange,
  SubTextHeader,
} from '../SharedComponents/AuthComp/AuthComp';

const { Text } = Typography;
const { Password } = Input;

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordRepeat, setpasswordRepeat] = useState('');
  const [password, setPassword] = useState('');

  const changePasswordFormData = () => {
    const formInputs = () => {
      const fields = [
        {
          label: 'Current Password',
          placeholder: 'Enter Current Password Here',
          dataCy: 'currentPassword',
          value: currentPassword,
          setter: setCurrentPassword,
        },
        { label: 'Create New Password', placeholder: 'Enter New Password Here', dataCy: 'password', value: password, setter: setPassword },
        {
          label: 'Confirm New Password',
          placeholder: 'Re-enter New Password Here',
          dataCy: 'passwordRepeat',
          value: passwordRepeat,
          setter: setpasswordRepeat,
        },
      ];
      return fields.map(({ label, placeholder, dataCy, value, setter }) => (
        <Fragment key={dataCy}>
          <Text strong> {label} </Text>
          <Password type="password" placeholder={placeholder} data-cy={dataCy} value={value} onChange={e => setter(e.currentTarget.value)} />
        </Fragment>
      ));
    };

    const formAlert = () => (
      <Alert
        showIcon
        type="info"
        message="Create a password with at least 8 characters, including a mix of uppercase, lowercase, numbers, and special characters. Avoid common words and personal information. Keep your account safe by choosing a unique password"
        style={{ ...formInput, ...cardStyle, ...textJustify }}
      />
    );

    const formButtons = () => {
      const handleResetButton = async () => {
        const res = await authService.setNewPassword(password, currentPassword);
        return password.length < 8 || currentPassword.length < 8 || passwordRepeat.length < 8
          ? message.error('Password is not valid')
          : password !== password.replace(/[^\x00-\x7F]+/gi, '')
          ? message.error('Password is not valid')
          : password !== passwordRepeat
          ? message.error('Password mismatch')
          : !currentPassword
          ? null
          : res.data.status !== 'ok'
          ? message.error(res.status)
          : (message.success('Success'), setCurrentPassword(''), setpasswordRepeat(''), setPassword(''));
      };

      const handleCancelButton = () => window.history.back();

      return (
        <Space direction="horizontal">
          <Button style={formButton} type="primary" data-cy="resetSubmit" onClick={handleResetButton}>
            Reset Password
          </Button>
          <Button style={formButton} type="primary" data-cy="cancelChangePassword" onClick={handleCancelButton}>
            Cancel
          </Button>
        </Space>
      );
    };

    return (
      <AuthenticationForm>
        {ChangePasswordHeading()}
        <form>
          <Space direction="vertical" style={formInput}>
            {formInputs()}
            {formAlert()}
            {formButtons()}
          </Space>
        </form>
      </AuthenticationForm>
    );
  };

  return (
    <>
      <AuthImageC />
      <Row>
        <BrandLogo />
      </Row>
      {changePasswordFormData()}
      <SubText>
        <SubTextHeader /> <SubTextChange />
      </SubText>
    </>
  );
}
