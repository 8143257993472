export const BottomButtons = ({ children }) => {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="bottomButtons"> {children} </div>
    </>
  );
};
