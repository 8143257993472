import { ReactElement, useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { iProject } from 'interface/project';
import { TitleUIRow } from 'layout/TitleUI';
import { ProjectDeploymentContext } from 'components/Projects/ProjectDeploymentContext';
import { ProjectWidgetsRow } from 'components/Projects/ProjectWidgetsRow';
import { TemplateSettingsRoutes } from './TemplateSettingsRoutes';
import { useGqlDeploymentById } from 'services/deployment.service';
import { iProjectModel } from 'shared/deployment';

/**
 * ProjectSetting tabs component
 * @returns
 */

export const Configuration = (): ReactElement => {
  // @ts-ignore
  const params: any = useParams();
  const { deploymentId, tabName, serviceName } = params;
  const [deploymentContext, setDeploymentContext] = useState({ deployment: null });

  const qlDp = useGqlDeploymentById(Number(deploymentId));
  const project: iProjectModel = qlDp.data?.DeploymentsController_getDeployment?.ProjectModel;

  useEffect(() => {
    return qlDp.stopPolling;
  }, []);

  useEffect(() => {
    if (qlDp.data?.DeploymentsController_getDeployment) {
      setDeploymentContext({ deployment: qlDp.data?.DeploymentsController_getDeployment });
    }
  }, [qlDp.data?.DeploymentsController_getDeployment]);

  if (project?.gitInitialized === false) {
    qlDp.startPolling(10000);
  }

  if (qlDp.loading || !project) {
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <ProjectDeploymentContext.Provider value={deploymentContext}>
      <TitleUIRow title={`Template: ${project.name}`} />
      <ProjectWidgetsRow project={project} deployment={qlDp.data?.DeploymentsController_getDeployment} setDeploymentContext={setDeploymentContext} />
      <TemplateSettingsRoutes
        tabName={tabName}
        serviceName={serviceName}
        project={project}
        deployment={qlDp.data?.DeploymentsController_getDeployment}
      />
    </ProjectDeploymentContext.Provider>
  );
};
