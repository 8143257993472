import { ReactElement } from 'react';
import { OneFile } from '../FileEditor/OneFile';
import { PROJECT_CODE_NAME } from 'interface/common';
import { Alert, Typography, Space } from 'antd';
import { spaceWidth } from 'utils/styles';
import { iProject } from 'interface/project';
import { iProjectModel } from 'shared/deployment';

const { Text, Link } = Typography;

export const ClusterPermissions = (props: { project: iProjectModel }): ReactElement => {
  const alertMessage = () => {
    const listItems = [
      'This tab allows you to provide additional permissions for Cluster control, it only makes sense for apps that were designed to work with Kubernetes API.',
      'This is dangerous if you do not trust to the app code.',
      'It will probably allow to application affect to other Deployment in the Cluster.',
    ];
    return (
      <ul>
        {listItems.map((item, index) => (
          <li key={index}> {item} </li>
        ))}
      </ul>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Text>
        You can add here your
        <Text strong>
          <Link target="_blank"> ClusterRole </Link>
        </Text>
        and
        <Text strong>
          <Link target="_blank"> ClusterRoleBinding </Link>
        </Text>
        ,
        <Text strong>
          <Link target="_blank"> Role </Link>
        </Text>
        and
        <Text strong>
          <Link target="_blank"> RoleBinding </Link>
        </Text>
      </Text>
      <Alert type={props.project.allowCustomClusterRoles ? 'warning' : 'info'} closable={true} message={alertMessage()} />
      {props.project.allowCustomClusterRoles ? (
        <OneFile fileName={`${PROJECT_CODE_NAME}/ClusterRole.yaml`} project={props.project} />
      ) : (
        <Alert type="warning" showIcon message="This function not enabled in AdminUI for this Project" closable={true} />
      )}
    </Space>
  );
};
