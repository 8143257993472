import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { deploymentService } from '../../../../services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { useApiQuery } from '../../../../utils/common';
import { Alert, Button, Result, Skeleton, Space, Typography } from 'antd';
import { bottomMargin, buttonSize, spaceWidth } from 'utils/styles';
import { FullScreenButton, FullScreenEditor } from 'components/SharedComponents/FullScreenView/FullScreenView';

const { Text } = Typography;

export interface iRoutesTabProps {
  deployment: iDeployment;
}

export const RoutesTab = (props: iRoutesTabProps) => {
  const [deploymentYAML, error, loading] = useApiQuery(() => deploymentService.debugUI_deploymentRoutes(Number(props.deployment.id)));
  const [isFullscreen, setIsFullscreen] = useState(false);

  if (loading || !deploymentYAML) return <Skeleton active={true} loading={true} />;
  if (error) return <Alert message="" description={`Can not load data: ${error}`} type="error" style={bottomMargin} />;

  const deployementError = () => (
    <>
      <Alert message="" description={`Here you can see routes defenitions. It is read only.`} type="info" key={'info'} style={bottomMargin} />
      <Alert
        message=""
        description={`Error in yaml generation. Please check your deployment and project settings.`}
        type="error"
        key={'error'}
        style={bottomMargin}
      />
      <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      <Text />
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        <Editor
          height={isFullscreen ? '100vh' : 'calc(100vh - 215px)'}
          width={`100%`}
          language={`json`}
          value={deploymentYAML.error}
          theme={`GitHub`}
          defaultValue=""
        />
      </FullScreenEditor>
    </>
  );

  if (deploymentYAML.errorType === 'not-ready')
    return <Result status="404" title="Not ready" subTitle={String(deploymentYAML.error)} style={bottomMargin} />;
  else if (deploymentYAML.errorType === 'render-error') return deployementError();

  const deployementRoutesMessage = (
    <Space direction="vertical">
      <Text> This Deployment do not have any Routes defined. </Text>
      <Text>
        If you want to make it Public, you can add routes in the Project service settings on
        <Text strong> Routes </Text>
        tab.
      </Text>
    </Space>
  );

  const deployementRoutesAction = (
    <Link to={`/project/${props.deployment.projectId}/settings/services`}>
      <Button style={buttonSize} type="primary">
        Add Routes
      </Button>
    </Link>
  );

  if (deploymentYAML.routes === '') {
    return (
      <Space direction="vertical" style={spaceWidth}>
        <Alert showIcon message={deployementRoutesMessage} type="info" action={deployementRoutesAction} />
      </Space>
    );
  }

  return (
    <>
      <Alert message="Here you can see routes defenitions. It is read only" type="info" key={'info'} style={bottomMargin} />
      <FullScreenButton isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      <Text />
      <FullScreenEditor isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen}>
        <Editor
          height={isFullscreen ? '100vh' : 'calc(100vh - 215px)'}
          width={`100%`}
          language={`yaml`}
          value={deploymentYAML.routes}
          theme={`GitHub`}
          defaultValue=""
        />
      </FullScreenEditor>
    </>
  );
};
