import { iProject } from 'interface/project';
import { HelpNoteAboutVars } from './HelpNotes';
import { YamlEditor } from 'components/Projects/YamlEditor/YamlEditor';
import { Alert, Space, Tabs, Typography } from 'antd';
import { PROJECT_CODE_NAME } from 'interface/common';
import { spaceWidth } from 'utils/styles';
import TabPane from 'antd/es/tabs/TabPane';
import { DockerWizard } from 'components/Projects/YamlEditor/wizard/DockerWizard';
import { useState } from 'react';
import { iProjectModel } from 'shared/deployment';

export interface iProjectOneServiceDeploymentConfigurationTab {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
}

/**
 * Show custom.yaml file for service
 * Usfull for create some additional kubernetes entities related to service
 * @param props
 * @returns
 */

export const ProjectOneServiceDeploymentConfigurationTab = (props: iProjectOneServiceDeploymentConfigurationTab) => {
  const serviceName = props.serviceName;

  const [tab, setTab] = useState(`wizard`);

  return (
    <Tabs onChange={setTab}>
      <TabPane tab={`Wizard`} key={`wizard`}>
        {tab === `wizard` && (
          <DockerWizard
            key={`wizard`}
            serviceName={serviceName}
            fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/template.yaml`}
            project={props.project}
          />
        )}
      </TabPane>
      <TabPane tab={`YAML advanced configuration`} key={`yaml`}>
        {tab === `yaml` && (
          <>
            <YamlEditor key={`yaml-editor`} fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/template.yaml`} project={props.project} />
          </>
        )}
      </TabPane>
    </Tabs>
  );
};
