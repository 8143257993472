import { AppstoreAddOutlined, AppstoreOutlined, BulbOutlined, BellOutlined, DashboardOutlined } from '@ant-design/icons';
import { logoAvatar, shadowStyle } from 'utils/styles';
import { Avatar } from 'antd';

const iconComponents: Record<string, JSX.Element | null> = {
  AppstoreAddOutlined: <AppstoreAddOutlined />,
  AppstoreOutlined: <AppstoreOutlined />,
  BulbOutlined: <BulbOutlined />,
  BellOutlined: <BellOutlined />,
  DashboardOutlined: <DashboardOutlined />,
};

export const LogoAvatar = (props: { logo: string; name: string }) => {
  const primaryAvatar = () => (
    <Avatar size={20} style={{ ...logoAvatar, ...shadowStyle }}>
      {props.name}
    </Avatar>
  );

  const secondaryAvatar = () => (
    <Avatar size={20} style={{ ...logoAvatar, ...shadowStyle }}>
      {iconComponents[props.logo]}
    </Avatar>
  );

  const tertiaryAvatar = () => <Avatar crossOrigin="anonymous" size={15} src={props.logo} />;

  return !props.logo ? primaryAvatar() : props.logo.startsWith('http') ? tertiaryAvatar() : secondaryAvatar();
};
