import { Alert, Space } from 'antd';
import { iProject } from 'interface/project';
import { OneFile } from '../FileEditor/OneFile';
import { PROJECT_CODE_NAME } from 'interface/common';
import { bottomMargin, spaceWidth } from 'utils/styles';
import { useState } from 'react';
import { iProjectModel } from 'shared/deployment';

interface iProjectDocsProps {
  project: iProjectModel;
}

export const ProjectDocs = (props: iProjectDocsProps) => {
  const project = props.project;
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Alert type="info" showIcon message={`You can add here any information. It will show in Deployments overview.`} style={bottomMargin} />
      <OneFile fileName={`${PROJECT_CODE_NAME}/deployment-docs.md`} project={project} height={isFullscreen ? 'calc(100vh - 345px)' : '100vh'} />
    </Space>
  );
};
