import { Button, Input, Modal, notification, Select, Skeleton, Space, Steps, Switch, Tag, Typography } from 'antd';
import _ from 'lodash';
import * as yaml from 'js-yaml';

import { iProject } from 'interface/project';
import { useContext, useState } from 'react';
import { projectService } from 'services/project.service';
import { stringToYaml } from 'shared/yaml';
import { useApiQuery } from 'utils/common';
import { buttonWidth } from 'utils/styles';
import { iCloudProjectServiceYamlSpecs, iIngressRoute, iIngressRouteOnePath } from 'shared/project.interface';
import { JsonFormModalUI } from '../JsonFormModalUI';
import { form_env_schema, form_env_schema_ui } from '../schema/ContainerEnv';
import { ProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { EditOutlined } from '@ant-design/icons';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { iCodeObjectBasic, PatchYamlAction, patchYamlCode } from '../utils/yaml-tools';
import { containerVolumeMountsDynamicSchema } from '../schema/ContainerVolumeMounts';
import { VolumesUI } from '../VolumesUI';
import { SecretsMainOptionsUI } from '../SecretsMainOptionsUI';
import { ConfigMapMainOptionsUI } from '../ConfigMapMainOptionsUI';
import { PersistentVolumeClaimMainOptionsUI } from '../PersistentVolumeClaimMainOptionsUI';
import { iProjectModel } from 'shared/deployment';
const { Text } = Typography;

type wRoleType = 'deployment' | 'statefulset' | 'service' | 'none';

const DefaultUrlTemplate = `{{ .UnifieVar.deployment.domain }}`;

interface iWCodeObjectArray extends iCodeObjectBasic {
  index: number;
  role: wRoleType;
}

interface iDockerWizardProps {
  fileName: string;
  serviceName: string;
  project: iProjectModel;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
}

function getDeploymentObj(yamlCodeObjectArray: iWCodeObjectArray[]) {
  const deploymentObj: iWCodeObjectArray =
    yamlCodeObjectArray.find(item => item.role === 'deployment') || yamlCodeObjectArray.find(item => item.role === 'statefulset') || null;
  return deploymentObj;
}

export const DockerWizard = (props: iDockerWizardProps) => {
  const { project, fileName } = props;
  const [content, fError, fLoader] = useApiQuery(() => projectService.getFileContent(project.id, fileName), [fileName]);
  const [isChanged, setChanged] = useState<boolean>(false);
  const [newCode, setNewCode] = useState<string>(null);
  const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);
  const [showVolumes, setShowVolumes] = useState<boolean>(false);
  const [customUrlTemplate, setCustomUrlTemplate] = useState<boolean>(false);
  const [addedDomain, setDomain] = useState<string>(undefined);

  const [service, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(props.project.id, props.serviceName),
    [props.project.id, props.serviceName],
  );

  if (fLoader || fError || serviceLoader || serviceError) {
    return <Skeleton active={true} loading={true} />;
  }
  const projService: iCloudProjectServiceYamlSpecs = service;

  const routes: iIngressRoute[] = projService?.routes || [];

  const yamlCodeString: string = newCode || content?.data || '';
  const yamlCodeObject: any[] = stringToYaml(yamlCodeString);

  const yamlCodeObjectArray: iWCodeObjectArray[] = (Array.isArray(yamlCodeObject) ? yamlCodeObject : []).map(
    (yamlObj, indexInArray): iWCodeObjectArray => {
      const role = (yamlObj?.metadata?.annotations?.['unifie/role'] || yamlObj.kind || `none`).toLowerCase();
      return {
        obj: yamlObj,
        index: indexInArray,
        role: role,
      };
    },
  );
  const deploymentObj: iWCodeObjectArray = getDeploymentObj(yamlCodeObjectArray);

  const saveChanges = (yamlCodeObjectArray: iWCodeObjectArray[]) => {
    const newValue = yamlCodeObjectArray
      .filter(y => y.toRemove !== true)
      .map(yamlObj => yaml.dump(yamlObj.obj))
      .join('\n---\n');
    setChanged(true);
    setNewCode(newValue);
    console.log('newValue: ', newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  /**
   * Function to change any property in the current yaml object
   * @param key - path in the object
   * @param newObj - object to set or undefined if we want to unset
   * @param action - push, splice or undefined (set or unset)
   */
  const onChangeCurrentProperty = (indexInArray, key: string, newObj: any, action?: PatchYamlAction) => {
    yamlCodeObjectArray[indexInArray] = patchYamlCode<iWCodeObjectArray>(yamlCodeObjectArray[indexInArray], key, newObj, action);
    saveChanges(yamlCodeObjectArray);
  };
  const addNewItemIfNeeded = (newYaml: any) => {
    let obj = yamlCodeObjectArray.find(
      item => item.obj.metadata.name === newYaml.metadata.name && item.obj.kind?.toLowerCase() === newYaml.kind?.toLowerCase(),
    );
    if (obj) {
      // Skip adding the same object twice
      return obj;
    }
    obj = {
      obj: newYaml,
      index: yamlCodeObjectArray.length,
      role: newYaml.kind,
    };
    yamlCodeObjectArray.push(obj);
    saveChanges(yamlCodeObjectArray);
    return obj;
  };

  const removeItemIfNeeded = (kind: string, name: string) => {
    let toRemove = yamlCodeObjectArray.find(item => item.obj.metadata.name !== name && item.obj.kind?.toLowerCase() !== kind?.toLowerCase());
    if (!toRemove) {
      // Skip adding the same object twice
      return false;
    }

    yamlCodeObjectArray[toRemove.index].toRemove = true;
    saveChanges(yamlCodeObjectArray);
    return true;
  };

  if (!deploymentObj) {
    // If we do not have deployment object - we will create it here
    addNewItemIfNeeded({
      apiVersion: 'apps/v1',
      kind: 'Deployment',
      metadata: {
        name: props.serviceName,
        labels: {
          app: props.serviceName,
        },
      },
      spec: {
        selector: {
          matchLabels: {
            app: props.serviceName,
          },
        },
        template: {
          metadata: {
            labels: {
              app: props.serviceName,
            },
          },
          spec: {
            containers: [
              {
                name: props.serviceName,
                image: 'karthequian/helloworld:latest',
                ports: [
                  {
                    containerPort: 80,
                    name: 'http',
                  },
                ],
              },
            ],
          },
        },
      },
    });
    return <Skeleton active={true} loading={true} />;
  }

  const yName = deploymentObj?.obj?.metadata?.name || props.serviceName;
  const yContainer = deploymentObj?.obj?.spec?.template?.spec?.containers?.[0];
  const yInitContainer = deploymentObj?.obj?.spec?.template?.spec?.initContainers?.[0];
  const showVolumesUi = showVolumes || deploymentObj?.obj?.spec?.template?.spec?.volumes?.length > 0;

  const volumesDefintionUI = (deploymentObj?.obj?.spec?.template?.spec?.volumes || [])
    .filter(volume => {
      // Skip checks for emptyDir object types - due they do not have any configuration
      return volume.persistentVolumeClaim !== undefined || volume.configMap !== undefined || volume.secret !== undefined;
    })
    .map((current_volume, i) => {
      const skipConfig = deploymentObj?.obj?.metadata?.annotations?.[`unifie_volume_${current_volume.name}`] === 'skip';

      let configKind = ``;
      let configUI = null;
      let configObj = null;
      if (current_volume.persistentVolumeClaim !== undefined) {
        // PersistentVolumeClaim volume type
        configKind = `persistent volume`;
        configObj = addNewItemIfNeeded({
          apiVersion: 'v1',
          kind: 'PersistentVolumeClaim',
          metadata: {
            name: current_volume.persistentVolumeClaim.claimName,
          },
          spec: {
            accessModes: ['ReadWriteOnce'],
            resources: {
              requests: {
                storage: '1Gi',
              },
            },
            // storageClassName: 'standard',
          },
        });

        configUI = (
          <PersistentVolumeClaimMainOptionsUI
            lockName={true}
            yamlObj={configObj.obj}
            onChange={(key: string, newObj: any, action: PatchYamlAction) => {
              onChangeCurrentProperty(configObj.index, key, newObj, action);
            }}
          />
        );
      } else if (current_volume.configMap !== undefined) {
        // ConfigMap volume type
        configKind = `config map`;
        configObj = addNewItemIfNeeded({
          apiVersion: 'v1',
          kind: 'ConfigMap',
          metadata: {
            name: current_volume.configMap.name,
          },
          data: {
            'example.txt': `hello`,
          },
        });

        configUI = (
          <ConfigMapMainOptionsUI
            lockName={true}
            yamlObj={configObj.obj}
            onChange={(key: string, newObj: any, action: PatchYamlAction) => {
              onChangeCurrentProperty(configObj.index, key, newObj, action);
            }}
          />
        );
      } else if (current_volume.secret !== undefined) {
        // Secret volume type
        configKind = `secret`;

        configObj = addNewItemIfNeeded({
          apiVersion: 'v1',
          kind: 'Secret',
          metadata: {
            name: current_volume.secret.secretName,
          },
          type: 'Opaque',
          stringData: {},
        });

        configUI = (
          <SecretsMainOptionsUI
            lockName={true}
            yamlObj={configObj.obj}
            onChange={(key: string, newObj: any, action: PatchYamlAction) => {
              onChangeCurrentProperty(configObj.index, key, newObj, action);
            }}
          />
        );
      }

      return {
        title: `Mount: ${configKind} ${current_volume.name}`,
        description: (
          <Space direction="vertical">
            <Switch
              checkedChildren={`Define ${configKind}`}
              unCheckedChildren="Skip configuration"
              defaultChecked={!skipConfig}
              onChange={val => {
                if (!val) {
                  // Add annotation to skip this volume configuration here.
                  // configObj.toRemove = true;
                  onChangeCurrentProperty(deploymentObj.index, `.metadata.annotations.unifie_volume_${current_volume.name}`, `skip`);
                } else {
                  onChangeCurrentProperty(deploymentObj.index, `.metadata.annotations.unifie_volume_${current_volume.name}`, undefined);
                }
              }}
            />
            <Text hidden={!skipConfig}>
              If this volume already configured in other part of the yaml file or created in cluster by other tool - you can skip configuration here.
            </Text>

            {!skipConfig && configUI}
          </Space>
        ),
      };
    });

  // Use `app` label to find the service object
  let k8sService = yamlCodeObjectArray.find(
    item => item.obj?.kind?.toLowerCase() === `service` && item.obj?.spec?.selector?.app === deploymentObj.obj?.spec?.selector?.matchLabels?.app,
  );

  const autoAddServiceAndDomain = ![null, undefined, 'false', 'no'].includes(addedDomain);
  const customUrlTemplateUI = customUrlTemplate || addedDomain !== DefaultUrlTemplate;

  /**
   * Save all config to the file on the server backend
   * @param value
   */
  const handleSaveFile = async value => {
    setChanged(false);
    if (props.onSubmit) {
      props.onSubmit(value);
    }

    const newValue = yamlCodeObjectArray
      .filter(y => y.toRemove !== true)
      .map(yamlObj => yaml.dump(yamlObj.obj))
      .join('\n---\n');
    if (newValue) {
      const res = await projectService.setFileContent(project.id, fileName, newValue);
      if (res.error) {
        notification.error({ message: `Error - ${res.error}` });
        return;
      }
    }

    const targetPort = Number(k8sService?.obj?.spec?.ports?.[0]?.port) || 0;
    let newRoutes = routes;
    if (!addedDomain || yContainer?.ports.length <= 0 || targetPort <= 0) {
      // remove route if no domain is set
      newRoutes = routes
        .map(route => {
          route.http.paths = route.http.paths.filter(path => path.backend.service.name !== k8sService?.obj?.metadata?.name);
          return route;
        })
        .filter(route => route.http.paths.length > 0);
    } else {
      let findRoute = false;
      newRoutes = routes.map((route: iIngressRoute) => {
        const main: iIngressRouteOnePath = route.http.paths.find(path => path.backend.service.name === k8sService?.obj?.metadata?.name);
        if (main) {
          route.http.paths = route.http.paths.map((path: iIngressRouteOnePath): iIngressRouteOnePath => {
            if (path.backend.service.name !== k8sService?.obj?.metadata?.name) {
              // Skip other services
              return path;
            }
            if (path.path !== '/') {
              // Skip custom path configured on `Routes` tab
              return path;
            }
            findRoute = true;
            path.backend.service.port.number = targetPort;
            return path;
          });

          route.host = addedDomain;
        }
        return route;
      });

      if (!findRoute) {
        newRoutes.push({
          host: addedDomain,
          http: {
            paths: [
              {
                path: '/',
                pathType: 'Prefix',
                backend: {
                  service: {
                    name: k8sService?.obj?.metadata?.name,
                    port: {
                      number: targetPort,
                    },
                  },
                },
              },
            ],
          },
        });
      }
    }

    const serviceObj = {
      name: props.serviceName,
      data: {
        routes: newRoutes,
      },
    };

    const res = await projectService.setProjectServices(project.id, serviceObj);
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    }

    notification.success({ message: `Saved` });
  };

  return (
    <>
      <Steps
        direction="vertical"
        items={[
          {
            title: `Name: ` + yName,
            description: (
              <Space direction="vertical">
                <Input
                  placeholder="Name"
                  value={yName}
                  onChange={e => {
                    onChangeCurrentProperty(deploymentObj.index, `.metadata.name`, e.target.value);
                  }}
                />
              </Space>
            ),
          },
          {
            title: `Service type`,
            description: (
              <Space direction="vertical">
                <Select
                  defaultValue="stateless"
                  style={{ width: 300 }}
                  onChange={value => {
                    onChangeCurrentProperty(deploymentObj.index, `.kind`, value);
                  }}
                >
                  <Select.Option value="Deployment">
                    StateLess <i>(kubernetes Deployment)</i>
                  </Select.Option>
                  <Select.Option value="StatefulSet">
                    Stateful <i>(kubernetes StatefulSet)</i>
                  </Select.Option>
                </Select>
                <ul>
                  <li>Use StateLess service type if you don't need to store any data on the service.</li>
                  <li>Use Stateful service type if you need to store data.</li>
                </ul>
                <Text>
                  StateLess type is preferd due it is simple to scale and deploy, but you can't store any data on the service. Can be deployed on SPOT
                  nodes for safe costs.
                </Text>
              </Space>
            ),
          },
          {
            title: (
              <>
                Add disk volumes {` `}
                <span hidden={deploymentObj?.obj?.spec?.template?.spec?.volumes?.length > 0}>
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    defaultChecked={showVolumesUi}
                    onChange={val => {
                      setShowVolumes(val);
                    }}
                  />
                </span>
              </>
            ),
            description: (
              <div hidden={!showVolumesUi}>
                <VolumesUI
                  yamlObj={deploymentObj.obj}
                  onChange={(key: string, newObj: any, action: PatchYamlAction) => {
                    if (key.includes('.secret.secretName')) {
                      const oldValue = _.get(deploymentObj.obj, key.replace('.', ''));

                      const linkedSecret = yamlCodeObjectArray.find(
                        item => item.obj.metadata.name === oldValue && item.obj?.kind?.toLowerCase() === `secret`,
                      );

                      if (linkedSecret) {
                        onChangeCurrentProperty(linkedSecret.index, `.metadata.name`, newObj);
                      }
                    } else if (key.includes('.configMap.name')) {
                      const oldValue = _.get(deploymentObj.obj, key.replace('.', ''));

                      const linkedSecret = yamlCodeObjectArray.find(
                        item => item.obj.metadata.name === oldValue && item.obj?.kind?.toLowerCase() === `configmap`,
                      );

                      if (linkedSecret) {
                        onChangeCurrentProperty(linkedSecret.index, `.metadata.name`, newObj);
                      }
                    } else if (key.includes('.persistentVolumeClaim.claimName')) {
                      const oldValue = _.get(deploymentObj.obj, key.replace('.', ''));

                      const linkedSecret = yamlCodeObjectArray.find(
                        item => item.obj.metadata.name === oldValue && item.obj?.kind?.toLowerCase() === `persistentvolumeclaim`,
                      );

                      if (linkedSecret) {
                        onChangeCurrentProperty(linkedSecret.index, `.metadata.name`, newObj);
                      }
                    }
                    onChangeCurrentProperty(deploymentObj.index, key, newObj, action);
                  }}
                />
              </div>
            ),
          },
          ...volumesDefintionUI,
          {
            title: `Docker image`,
            description: (
              <Space direction="vertical">
                <Space direction="horizontal">
                  Use image from versions api
                  <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    defaultChecked={yContainer?.image?.includes('{{.Values')}
                    onChange={val => {
                      if (val) {
                        onChangeCurrentProperty(
                          deploymentObj.index,
                          `.spec.template.spec.containers.[0].image`,
                          `{{.Values.image.${props.serviceName}}}`,
                        );
                      } else {
                        onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].image`, `karthequian/helloworld:latest`);
                      }
                    }}
                  />
                </Space>
                <Input
                  hidden={yContainer?.image?.includes('{{.Values')}
                  placeholder="Image"
                  value={yContainer?.image}
                  onChange={e => {
                    onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].image`, e.target.value);
                  }}
                />
                Start command
                <Input
                  placeholder="Start command"
                  value={yContainer?.command}
                  onChange={e => {
                    if (e.target.value === '') {
                      onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].command`, undefined);
                      return;
                    }
                    onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].command`, e.target.value);
                  }}
                />
                <Space direction="horizontal" hidden={!deploymentObj?.obj?.spec?.template?.spec?.volumes?.length}>
                  Mount volumes
                  <Button
                    onClick={() => {
                      JsonFormModalUI({
                        formData: yContainer?.volumeMounts || [],
                        formConfigs: containerVolumeMountsDynamicSchema(deploymentObj.obj, yContainer),
                        SyncVarsContext: syncVarsContext,
                        onChange: v => {
                          onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].volumeMounts`, v);
                        },
                      });
                    }}
                  >
                    <EditOutlined />
                  </Button>
                  {(deploymentObj.obj?.spec?.template?.spec?.containers?.[0]?.volumeMounts || []).length ? (
                    <Tag color="green"> {deploymentObj.obj?.spec?.template?.spec?.containers?.[0]?.volumeMounts?.length} volumes </Tag>
                  ) : (
                    <Tag> Not configured </Tag>
                  )}
                </Space>
                <Space direction="horizontal">
                  Add Default env vars here
                  <Button
                    onClick={() => {
                      JsonFormModalUI({
                        formData: yContainer?.env || [],
                        formConfigs: { schema: form_env_schema, ui: form_env_schema_ui },
                        SyncVarsContext: syncVarsContext,
                        onChange: (v: any) => {
                          onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].env`, v);
                        },
                      });
                    }}
                  >
                    <EditOutlined />
                  </Button>
                  {(yContainer?.env || []).length ? <Tag color="green"> {yContainer?.env?.length} env vars </Tag> : <Tag> Not configured </Tag>}
                </Space>
              </Space>
            ),
          },
          {
            title: (
              <>
                Add init container{' '}
                <Switch
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  defaultChecked={yInitContainer?.image !== undefined}
                  onChange={val => {
                    if (val) {
                      onChangeCurrentProperty(
                        deploymentObj.index,
                        `.spec.template.spec.initContainers.[0].image`,
                        `{{.Values.image.${props.serviceName}}}`,
                      );
                    } else {
                      onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers`, undefined);
                    }
                  }}
                />
              </>
            ),
            description:
              yInitContainer?.image === undefined ? null : (
                <Space direction="vertical">
                  <Text>
                    You can also add a Init container to your application. Main application will start only after the Init container is finished.{' '}
                  </Text>
                  <Text>It can be used for example to run migrations or other tasks before the main application starts.</Text>
                  <Space direction="horizontal">
                    Use image from versions api
                    <Switch
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      defaultChecked={yInitContainer?.image?.includes('{{.Values')}
                      onChange={val => {
                        if (val) {
                          onChangeCurrentProperty(
                            deploymentObj.index,
                            `.spec.template.spec.initContainers.[0].image`,
                            `{{.Values.image.${props.serviceName}}}`,
                          );
                        } else {
                          onChangeCurrentProperty(
                            deploymentObj.index,
                            `.spec.template.spec.initContainers.[0].image`,
                            `karthequian/helloworld:latest`,
                          );
                        }
                      }}
                    />
                  </Space>
                  <Input
                    hidden={yInitContainer?.image?.includes('{{.Values')}
                    placeholder="Image"
                    value={yInitContainer?.image}
                    onChange={e => {
                      onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].image`, e.target.value);
                    }}
                  />
                  Start command
                  <Input
                    placeholder="Start command"
                    value={yInitContainer?.command}
                    onChange={e => {
                      if (e.target.value === '') {
                        onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].command`, undefined);
                        return;
                      }
                      onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].command`, e.target.value);
                    }}
                  />
                  <Space direction="horizontal">
                    Add Default env vars here for init container
                    <Button
                      onClick={() => {
                        JsonFormModalUI({
                          formData: yInitContainer?.env || [],
                          formConfigs: { schema: form_env_schema, ui: form_env_schema_ui },
                          SyncVarsContext: syncVarsContext,
                          onChange: (v: any) => {
                            onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].env`, v);
                          },
                        });
                      }}
                    >
                      <EditOutlined />
                    </Button>
                    {(yInitContainer?.env || []).length ? (
                      <Tag color="green"> {yInitContainer?.env?.length} env vars </Tag>
                    ) : (
                      <Tag> Not configured </Tag>
                    )}
                  </Space>
                  <Space direction="horizontal" hidden={!deploymentObj?.obj?.spec?.template?.spec?.volumes?.length}>
                    Mount volumes
                    <Button
                      onClick={() => {
                        JsonFormModalUI({
                          formData: yInitContainer?.volumeMounts || [],
                          formConfigs: containerVolumeMountsDynamicSchema(deploymentObj.obj, yInitContainer),
                          SyncVarsContext: syncVarsContext,
                          onChange: v => {
                            onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.initContainers.[0].volumeMounts`, v);
                          },
                        });
                      }}
                    >
                      <EditOutlined />
                    </Button>
                    {(deploymentObj.obj?.spec?.template?.spec?.initContainers?.[0]?.volumeMounts || []).length ? (
                      <Tag color="green"> {deploymentObj.obj?.spec?.template?.spec?.initContainers?.[0]?.volumeMounts?.length} volumes </Tag>
                    ) : (
                      <Tag> Not configured </Tag>
                    )}
                  </Space>
                </Space>
              ),
          },
          {
            title: 'Open ports',
            description: (
              <Space direction="vertical">
                <Input
                  placeholder="Port"
                  defaultValue={yContainer?.ports
                    .map(port => port?.containerPort)
                    .filter(v => !!v)
                    .join(',')}
                  onBlur={e => {
                    const val = (e.target.value || '')
                      .split(/[ ,]/gm)
                      .map(v => parseInt(v, 10))
                      .filter(v => !isNaN(v))
                      .map(v => ({ containerPort: v, name: `port${v}` }));

                    onChangeCurrentProperty(deploymentObj.index, `.spec.template.spec.containers.[0].ports`, val);
                    const newPorts = yContainer?.ports.map(port => {
                      return {
                        name: `port${port.containerPort}`,
                        port: port.containerPort,
                        protocol: 'TCP',
                        targetPort: port.containerPort,
                      };
                    });
                    if (newPorts.length === 0) {
                      if (k8sService) {
                        k8sService.toRemove = true;
                      }
                    } else {
                      k8sService = addNewItemIfNeeded({
                        apiVersion: 'v1',
                        kind: 'Service',
                        metadata: {
                          name: yName,
                          labels: {
                            app: yName,
                          },
                        },
                        spec: {
                          selector: {
                            app: yName,
                          },
                          ports: newPorts,
                        },
                      });
                      k8sService.toRemove = false;
                      onChangeCurrentProperty(k8sService.index, `.spec.ports`, newPorts);
                    }
                  }}
                />
              </Space>
            ),
          },
          yContainer?.ports.length > 0 &&
            k8sService && {
              title: `Http route for port ${yContainer?.ports[0]?.containerPort}`,
              description: (
                <Space direction="vertical">
                  <Space direction="horizontal">
                    Add url for port{' '}
                    <Switch
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onChange={val => {
                        if (val) {
                          setDomain(DefaultUrlTemplate);
                          setChanged(true);
                          // onChangeCurrentProperty(k8sService.index, `.metadata.annotations['unifie/add-domain']`, DefaultUrlTemplate);
                        } else {
                          setDomain(undefined);
                          setChanged(true);
                          // onChangeCurrentProperty(k8sService.index, `.metadata.annotations['unifie/add-domain']`, undefined, 'unset');
                        }
                        setCustomUrlTemplate(false);
                      }}
                      defaultChecked={autoAddServiceAndDomain}
                    />
                  </Space>

                  <div hidden={!autoAddServiceAndDomain}>
                    Use custom url template{' '}
                    <Switch
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onChange={val => {
                        if (val) {
                          setCustomUrlTemplate(true);
                        } else {
                          setCustomUrlTemplate(false);
                          setDomain(DefaultUrlTemplate);
                          setChanged(true);
                          // onChangeCurrentProperty(k8sService?.index, `.metadata.annotations['unifie/add-domain']`, DefaultUrlTemplate);
                        }
                      }}
                      checked={customUrlTemplateUI}
                    />
                  </div>

                  <Input
                    hidden={!autoAddServiceAndDomain || !customUrlTemplateUI}
                    placeholder="Domain name template"
                    defaultValue={addedDomain || DefaultUrlTemplate}
                    onBlur={e => {
                      const val = e.target.value || '';
                      setDomain(val);
                      setChanged(true);
                      // onChangeCurrentProperty(k8sService?.index, `.metadata.annotations['unifie/add-domain']`, val);
                    }}
                  />
                </Space>
              ),
            },
        ]}
      />
      <BottomButtons>
        <Button type="primary" htmlType="submit" onClick={handleSaveFile} disabled={!isChanged} style={buttonWidth}>
          Save
        </Button>
      </BottomButtons>
    </>
  );
};
