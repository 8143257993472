import { ReactElement } from 'react';
import { ProjectServices } from './Settings/ProjectServices';
import { DeleteProject } from './Settings/DeleteProject';
import { ProjectFiles } from './FileEditor/ProjectFiles';
import { ClusterPermissions } from './ClusterPermissions/ClusterPermissions';
import { ProvisionerConfiguration } from './Provisioner/Provisioner';
import { ProjectGeneral } from './Settings/ProjectGeneral';
import { ProjectGitSettings } from './Settings/Git/ProjectGitSettings';
import { ProjectEnvSettings } from './Settings/ProjectEnvSettings';
import { ProjectContainerStorage } from './ProjectContainerStorage/ProjectContainerStorage';
import { ProjectCiCd } from './ProjectCiCd/ProjectCiCd';
import { iProject } from 'interface/project';
import { Tabs, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { ProjectPage } from './ProjectPage';
import { GitLogs } from './Settings/Git/gitLogs';
import { ProjectDocs } from './Settings/ProjectDocs';
import { ShareProject } from './Settings/ShareProject';
import { ProjectVersions } from './Settings/ProjectVersion';
import { iProjectModel } from 'shared/deployment';

interface iProjectSettingsRoutes {
  tabName: string;
  project: iProjectModel;
}

/**
 * ProjectSettingsRoutes tabs component
 * @returns
 */

const { TabPane } = Tabs;

export const ProjectSettingsRoutes = (props: iProjectSettingsRoutes): ReactElement => {
  const history = useHistory();

  const project = props.project;
  let tabName = props.tabName;
  tabName !== 'git' && tabName !== 'danger-zone' && !project.gitInitialized
    ? (() => {
        history.push(`/project/${project.id}/settings/git`);
        notification.error({
          key: 'gitInitialized',
          message: `Git configuration is required`,
          description: `Please configure git settings for this project`,
        });
        return null;
      })()
    : null;

  if (!tabName) {
    history.push(`/project/${project.id}/settings/general`);
    return null;
  }

  // const settingsTabs = ['services', 'env', `ci-cd`, 'project-files', 'container-storage'];
  const overviewTabs = ['general', 'git', `danger-zone`, `git-audit`, `docs`, `share`];
  const clusterLevelTabs = ['provisioner', 'permissions'];
  const logsTabs = ['logs'];

  let baseMenuTab = `settings`;

  const handleTabClick = (key: any) => history.push(`/project/${project.id}/${baseMenuTab}/${key}`);

  const tabPrimary = () => {
    const settingsTabsData = [
      { key: 'provisioner', tab: 'Provisioner', component: <ProvisionerConfiguration project={project} /> },
      { key: 'permissions', tab: 'Permissions', component: <ClusterPermissions project={project} /> },
    ];
    const settingsTabs = settingsTabsData.map(tab => (
      <TabPane key={tab.key} tab={tab.tab}>
        {tab.component}
      </TabPane>
    ));
    return (
      <Tabs defaultActiveKey={tabName || 'namespace'} onChange={handleTabClick}>
        {settingsTabs}
      </Tabs>
    );
  };

  const tabSecondary = () => {
    const gitTabs = [
      { key: 'general', tab: 'General', component: <ProjectGeneral project={project} />, disabled: !project.gitInitialized },
      { key: 'git', tab: 'GIT Settings', component: <ProjectGitSettings project={project} /> },
      { key: 'git-audit', tab: 'GIT Audit', component: <GitLogs project={project} />, disabled: !project.gitInitialized },
      { key: 'docs', tab: 'Docs', component: <ProjectDocs project={project} />, disabled: !project.gitInitialized },
      { key: 'share', tab: 'Share', component: <ShareProject project={project} />, disabled: !project.gitInitialized },
      { key: 'danger-zone', tab: 'Danger Zone', component: <DeleteProject project={project} /> },
    ];
    const renderedTabs = gitTabs.map(tab => (
      <TabPane key={tab.key} tab={tab.tab} disabled={tab.disabled}>
        {tab.component}
      </TabPane>
    ));
    return (
      <Tabs defaultActiveKey={tabName || 'namespace'} onChange={handleTabClick}>
        {renderedTabs}
      </Tabs>
    );
  };

  const tabData = () => (
    <>
      {tabName == 'services' && <ProjectServices project={project} />}
      {tabName == 'env' && <ProjectEnvSettings project={project} />}
      {tabName == 'project-files' && <ProjectFiles project={project} />}
      {tabName == 'pull-secrets' && <ProjectContainerStorage project={project} />}
      {tabName == 'ci-cd' && <ProjectCiCd project={project} />}
      {tabName == 'versions' && <ProjectVersions project={project} />}
    </>
  );

  if (logsTabs.includes(tabName)) return <ProjectPage project={project} />;
  else if (clusterLevelTabs.includes(tabName)) return tabPrimary();
  else if (overviewTabs.includes(tabName)) return tabSecondary();
  return tabData();
};
