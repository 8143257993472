import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/antd';
import { iRegionModel } from 'shared/deployment';
import { regionsService } from 'services/regions.service';
import { CustomWidgets } from 'form-components';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Button, notification } from 'antd';
import { buttonWidth } from 'utils/styles';

export interface iAwsPriceProps {
  region: iRegionModel;
}

const EditRegionSchemaForm: any = {
  type: 'object',
  properties: {
    useCostExplorerApi: {
      type: 'boolean',
      title: 'UseCostExplorerApi',
      default: false,
      description: `Use Cost Explorer API to get cost data. If you don't have Cost Explorer enabled, you can use the OpenCost plugin. It is paid api. Be careful.`,
    },

    AWS_Account_ID: {
      type: 'string',
      title: 'AWS Account ID',
      default: '',
    },
    CE_AWS_ACCESS_KEY_ID: {
      type: 'string',
      title: 'AWS_ACCESS_KEY_ID',
      default: '',
      description: `Be sure that you have enabled Cost Explorer API in your AWS account. And this user has access to it.`,
    },
    CE_AWS_SECRET_ACCESS_KEY: {
      type: 'string',
      title: 'AWS_SECRET_ACCESS_KEY',
      default: '',
      description: `Be sure that you have enabled Cost Explorer API in your AWS account. And this user has access to it.`,
    },
  },
};

const EdisRegionSchemaFormUI = {
  CE_AWS_SECRET_ACCESS_KEY: { 'ui:widget': 'password' },
  CE_AWS_ACCESS_KEY_ID: { 'ui:widget': 'password' },
};

export const AwsPrice = (props: iAwsPriceProps) => {
  const handleSubmit = async (e: any) => {
    const data = e.formData;
    const res = await regionsService.updateOwnRegion(props.region.id, data);
    if (res.error) notification.error({ message: `Error - ${res.error}` });
    else notification.success({ message: `Ready` });
  };

  return (
    <Form
      widgets={CustomWidgets}
      formData={props.region}
      schema={EditRegionSchemaForm}
      uiSchema={EdisRegionSchemaFormUI}
      validator={validator}
      onSubmit={handleSubmit}
      onError={e => console.log('Error from submit: ', e)}
    >
      <BottomButtons>
        <Button type="primary" htmlType="submit" style={buttonWidth}>
          Save
        </Button>
      </BottomButtons>
    </Form>
  );
};
