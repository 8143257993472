//import ant design elements
import { Skeleton } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { DeleteTab } from './General/DeleteTab';
import { iDeployment } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';

const DeploymentSettingRoute = (props: { tabName: string; deployment: iDeployment }) => {
  const { tabName, deployment } = props;
  return tabName == 'delete' && <DeleteTab id={deployment.id} />;
};

export default function DeploymentSettingPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();

  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  if (!tabName) history.push(`/app/${id}/settings/general`);
  if (dpQuery.error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }
  if (dpQuery.loading || !deployment) return <Skeleton active={true} loading={true} />;
  if (!deployment.isReady) {
    history.push(`/app/${deployment.id}/setting/details`);
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <>
      <TitleUIRow title={`Deployment: ${deployment.name}`} />
      <DeploymentWidgetsRow deployment={deployment} />
      <DeploymentSettingRoute tabName={tabName} deployment={deployment} />
    </>
  );
}
