import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TitleUIRow } from 'layout/TitleUI';
import { InstallationFile } from './InstallationFile';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { HelpDrawerTpl } from 'components/Help/HelpDrawerTpl';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { PROJECT_NAME, PROJECT_CODE_NAME } from 'interface/common';
import { ClusterDeleteButton } from '../ClusterDangerZone';
import { TerraformClasterCreation } from './TerraformCreation';
import { regionsService, useRegionsController_getRegionById } from 'services/regions.service';
import { Alert, Button, Divider, Form, Image, Input, notification, Select, Skeleton, Space, Tabs, Tag, Tooltip, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { spaceWidth } from 'utils/styles';
import { getUrlHashParams } from 'utils/common';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { Item } = Form;

export const ClusterCreaionStep2 = () => {
  const history = useHistory();
  const addOptions = getUrlHashParams();
  const params: any = useParams();

  const { loading, region, stopPolling, startPolling, refetch } = useRegionsController_getRegionById(params.regionId);
  const [hasCluster, setHasCluster] = useState(addOptions?.hasCluster === 'true' || false);
  const [useTerraform, setUseTerraform] = useState(null);
  const [k8sVersion, setK8sVersion] = useState(null);
  const [cloudProvider, setCloudProvider] = useState(null);
  const [hasMetacontroller, setHasMetacontroller] = useState<boolean>(false);
  const [probablyInstalled, setProbablyInstalled] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    let _hasCluster = hasCluster;
    if (region?.useTerraform) {
      setUseTerraform(region?.useTerraform);
      if (_hasCluster === null && region?.useTerraform === true) {
        setHasCluster(false);
        _hasCluster = false;
      }
    }
    if (region?.cloudProvider) {
      setCloudProvider(region.cloudProvider);
      if (_hasCluster === null) {
        setHasCluster(true);
        _hasCluster = true;
      }
    }
    if (region?.kubernetesVersion) {
      setK8sVersion(region.kubernetesVersion);
      if (_hasCluster === null) {
        setHasCluster(true);
        _hasCluster = true;
      }
    }
    if (region?.id && (hasCluster || probablyInstalled)) startPolling(5000);
    else {
      stopPolling();
      return;
    }
    return () => stopPolling();
  }, [region, hasCluster, probablyInstalled]);

  const clusterSaveConfig = async values => await regionsService.updateOwnRegion(region.id, values);

  if (loading) return <Skeleton active={true} loading={true} />;

  if (region?.configureStatus === 'configured' && region?.integrationsStatus === 'configured') {
    history.push(`/clusters/${region.id}/overview`);
    return <Skeleton active={true} loading={true} />;
  }

  if (region?.configureStatus === 'one-sync-done') {
    history.push(`/cluster/new/${region.id}/step3`);
    return <Skeleton active={true} loading={true} />;
  }

  if (region?.lastSyncTime !== 0 && region?.configureStatus === 'configured' && region?.integrationsStatus === 'not-configured') {
    history.push(`/cluster/new/${region.id}/integrations`);
    return <Skeleton active={true} loading={true} />;
  }

  const probablyInstalledUI = () => {
    const handleOnClick = () => {
      refetch();
      setProbablyInstalled(true);
    };

    const alertData = () => {
      const alertDescription = `As soon ${PROJECT_CODE_NAME} agent in will be installed in your Cluster without errors you will be able to continue the configuration of the Cluster.`;
      const alertAction = <Button onClick={() => refetch()}> Check Installation Status </Button>;
      return <Alert showIcon type="info" description={alertDescription} action={alertAction} style={{ paddingBottom: '80px' }} />;
    };

    return (
      <>
        <BottomButtons>
          <Space>
            <Button type="primary" onClick={handleOnClick}>
              Install this YAML file to my Cluster
            </Button>
            <ClusterDeleteButton region={region} />
          </Space>
        </BottomButtons>
        {probablyInstalled && alertData()}
      </>
    );
  };

  const clusterCreate = () => {
    const handleOnFinish = async (values: any) => {
      if (addOptions.provider) values.cloudProvider = addOptions.provider;
      const res = await regionsService.newRegion(values);
      res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
      history.push(res.error ? '/' : `/clusters/${res.data.id}${addOptions?.hasCluster ? `?hasCluster=${addOptions.hasCluster}` : ``}`);
    };
    return (
      <Form form={form} initialValues={{}} layout="vertical" onFinish={handleOnFinish}>
        <Item name="name" label="Cluster name" rules={[{ required: true, message: 'Please input the cluster name!' }]}>
          <Input placeholder="Enter cluster name here" />
        </Item>
      </Form>
    );
  };

  const clusterExistence = () => {
    const helpLinks = [
      {
        url: 'https://www.unifie.cloud/post/how-add-your-own-cluster-to-unifie-dashboard',
        text: `How add your own cluster to ${PROJECT_NAME} dashboard`,
      },
      {
        url: 'https://www.unifie.cloud/post/try-unifie-with-k3s-in-5-min-on-a-local-machine-or-small-linux-server',
        text: `Try ${PROJECT_NAME} with K3S in 5 min on a local machine or small linux server`,
      },
    ];
    return (
      <Text strong>
        Do you have a Cluster?
        <HelpDrawerTpl>
          <Title level={5}> {PROJECT_NAME} </Title>
          <ul>
            <li> It provide great UI for managing clusters. </li>
            <li> If you want to deploy something, you need your own Kubernetes cluster. </li>
          </ul>
          <Divider />
          <Title level={5}> Additional information: </Title>
          <ul>
            {helpLinks.map(({ url, text }) => (
              <li key={url}>
                <Text strong>
                  <a href={url} target="_blank">
                    {text}
                  </a>
                </Text>
              </li>
            ))}
          </ul>
        </HelpDrawerTpl>
      </Text>
    );
  };

  const createCluster = () => {
    const clusterOptions = [
      { value: true, label: 'Yes, I have kubernetes cluster' },
      { value: false, label: 'No, I want to create a new cluster' },
    ];

    const handleOnChange = v => {
      setHasCluster(v);
      if (!v) {
        setProbablyInstalled(false);
      }
    };

    return (
      <Select placeholder="Please confirm how you want to create a cluster" value={hasCluster} onChange={handleOnChange} style={spaceWidth}>
        {clusterOptions.map(({ value, label }) => (
          <Option value={value} key={value ? 'Has-my-cluster' : 'Create-new-cluster'}>
            {label}
          </Option>
        ))}
      </Select>
    );
  };

  const hasClusterFalse = () => {
    const generateCloudTooltip = (logoSrc: string, cloudName: string | number | boolean | ReactElement<string> | Iterable<ReactNode>) => (
      <Tooltip color="#FFFFFF" placement="topLeft" title={<Image src={logoSrc} preview={false} height={40} />}>
        {cloudName}
      </Tooltip>
    );

    const awsTabpane = () => {
      const awsLinks = [
        {
          title: 'Click here to view AWS EKS Docs',
          href: 'https://docs.aws.amazon.com/eks/latest/userguide/create-cluster.html',
          text: 'AWS EKS Docs',
        },
        { title: 'Click here to view AWS EKS Panel', href: 'https://console.aws.amazon.com/eks/home#/clusters', text: 'AWS EKS Panel' },
      ];

      return (
        <TabPane key="aws" tab={generateCloudTooltip('branding/logoAWS.svg', 'AWS')}>
          <Space direction="vertical">
            <Text>
              <Image src="branding/logoAWS.svg" preview={false} height={30} />
              {'\u00A0'.repeat(3)}
              <Text strong>
                <a href="https://www.unifie.cloud/post/how-to-create-aws-eks-cluster" target="_blank">
                  How to create AWS EKS cluster?
                </a>
              </Text>
              &nbsp;using&nbsp;
              {awsLinks.map(({ title, href, text }) => (
                <Tooltip color="#115EA3" title={title} key={href}>
                  <Text strong>
                    <a href={href} target="_blank">
                      {text}
                    </a>
                  </Text>
                </Tooltip>
              ))}
            </Text>
            <InstallationFile {...{ action: 'install', hasMetacontroller: !(!hasMetacontroller || hasCluster), region }} />
            {probablyInstalledUI()}
          </Space>
        </TabPane>
      );
    };

    const gcpTabpane = () => (
      <TabPane key="gcp" tab={generateCloudTooltip('branding/logoGCP.svg', 'GCP')}>
        <Space direction="vertical">
          <Text>
            <Image src="branding/logoGCP.svg" preview={false} height={30} />
            {'\u00A0'.repeat(3)}
            <Tooltip color="#115EA3" title="Click here to view Google Cloud Platform Docs">
              <Text strong>
                <a href="https://cloud.google.com/" target="_blank">
                  Google Cloud Platform
                </a>
              </Text>
            </Tooltip>
          </Text>
        </Space>
      </TabPane>
    );

    const azureTabpane = () => (
      <TabPane key="azure" tab={generateCloudTooltip('branding/logoAzure.svg', 'Azure')}>
        <Space direction="vertical">
          <Text>
            <Image src="branding/logoAzure.svg" preview={false} height={30} />
            {'\u00A0'.repeat(3)}
            <Tooltip color="#115EA3" title="Click here to view Azure Docs">
              <Text strong>
                <a href="https://azure.microsoft.com/" target="_blank">
                  Azure
                </a>
              </Text>
            </Tooltip>
          </Text>
        </Space>
      </TabPane>
    );

    const k3sTabpane = () => {
      const k3sHelp = [
        'K3S - It is good if you only have one small server. It is the cheapest way to get a small kubernetes cluster.',
        'Lightweight Kubernetes. Production ready, easy to install, half the memory, all in a binary less than 100 MB.',
      ];
      const k3sCode = [
        { command: 'curl -sfL https://get.k3s.io | sh -', description: 'Copy this if you want to connect to your server with ssh and run' },
        { command: 'kubectl get nodes', description: 'Copy this if you want to test k3s run' },
      ];
      const k3sLink = [
        {
          href: 'https://www.unifie.cloud/post/try-unifie-with-k3s-in-5-min-on-a-local-machine-or-small-linux-server',
          text: `Try ${PROJECT_NAME} with K3S in 5 minutes on a local machine or small linux server`,
        },
        {
          href: 'https://github.com/k3s-io/k3s?tab=readme-ov-file#quick-start---install-script',
          text: 'K3S Quick Start documentation',
        },
      ];
      return (
        <TabPane key="k3s" tab={generateCloudTooltip('branding/logoK3S.svg', 'K3S')}>
          <Space direction="vertical">
            <Space direction="horizontal">
              <Image src="branding/logoK3S.svg" preview={false} height={30} />
              {'\u00A0'.repeat(3)}
              <Space direction="vertical">
                {k3sHelp.map(text => (
                  <Text key={text}> {text} </Text>
                ))}
              </Space>
            </Space>
            <Text />
            <Text />
            <Title level={4}> How to install? </Title>
            {k3sCode.map(({ command, description }) => (
              <Text key={command}>
                <CodeLine> {command} </CodeLine> &nbsp; <ArrowLeftOutlined /> &nbsp; {description}
              </Text>
            ))}
            <Text />
            <Text> If any issues, please check our blog post. </Text>
            <Text strong>
              {k3sLink.map(({ href, text }) => (
                <Fragment key={href}>
                  <a href={href} target="_blank">
                    {text}
                  </a>
                  &nbsp;or&nbsp;
                </Fragment>
              ))}
            </Text>
            <InstallationFile {...{ action: 'install', hasMetacontroller: !(!hasMetacontroller || hasCluster), region }} />
            {probablyInstalledUI()}
          </Space>
        </TabPane>
      );
    };

    const othersTabpane = () => (
      <TabPane key="other" tab={generateCloudTooltip('branding/logoExtra.svg', 'Other')}>
        <Space direction="vertical">
          <Text>
            <Title level={4}> You can use other Kubernetes providers. </Title>
            <Text> {PROJECT_NAME} uses standard kubernetes API to manage the cluster. No specific requirements for Kubernetes providers. </Text>
            <Text strong> You can run {PROJECT_NAME} in any Kubernetes cloud. </Text>
          </Text>
          <InstallationFile action="install" hasMetacontroller={!(!hasMetacontroller || hasCluster)} region={region} />
          {probablyInstalledUI()}
        </Space>
      </TabPane>
    );

    const tabpaneData = () => (
      <Tabs defaultActiveKey={cloudProvider}>
        {awsTabpane()}
        {gcpTabpane()}
        {azureTabpane()}
        {k3sTabpane()}
        {othersTabpane()}
      </Tabs>
    );

    const handleOnChange = v => {
      setUseTerraform(v);
      clusterSaveConfig({ useTerraform: v });
    };

    return (
      hasCluster === false && (
        <>
          <Text strong>How do you want to create a new cluster?</Text>
          <Select placeholder="Select how you want to continue" defaultValue={useTerraform} onChange={handleOnChange} style={spaceWidth}>
            <Option value={true}>
              <Tag color={`green`}> Simple way </Tag> Create a new cluster with terraform in AWS
            </Option>
            <Option value={false}>
              <Tag color={`red`}> For advanced users </Tag> No, I want to create a new cluster manually
            </Option>
          </Select>
          {/* If useTerraform === undefined then do not show next steps */}
          {useTerraform === true && <TerraformClasterCreation region={region} />}
          {useTerraform === false && (
            <Space direction="vertical">
              <Text>
                <Text strong> {PROJECT_NAME} </Text>
                provide great UI for managing clusters and if you want to deploy something, you need your own Kubernetes cluster.
              </Text>
              <Text />
              <Text />
              <Text strong> Select a Cloud platform for your cluster </Text>
              {tabpaneData()}
            </Space>
          )}
        </>
      )
    );
  };

  const hasClusterTrue = () => {
    const cloudProviders = [
      { label: 'Amazon Web Services', logo: 'branding/logoAWS.svg', value: 'aws' },
      { label: 'Google Cloud Platform', logo: 'branding/logoGCP.svg', value: 'gcp' },
      { label: 'Azure', logo: 'branding/logoAzure.svg', value: 'azure' },
      { label: 'K3s', logo: 'branding/logoK3S.svg', value: 'k3s' },
      { label: 'Other', logo: 'branding/logoExtra.svg', value: 'onprem' },
    ];

    const k8sVersions = ['1.25', '1.26', '1.27', '1.28', '1.29', '1.30'];

    const metacontrollerOptions = [
      { label: 'Yes, I have Metacontroller', value: true },
      { label: 'No, I do not have Metacontroller', value: false },
    ];

    const handleOnChangeSelectA = v => {
      setCloudProvider(v);
      clusterSaveConfig({ cloudProvider: v });
    };

    const handleOnChangeSelectB = v => {
      setK8sVersion(v);
      clusterSaveConfig({ kubernetesVersion: v });
    };

    return (
      hasCluster === true && (
        <>
          <Text strong> Cloud Service Provider: </Text>
          <Select style={spaceWidth} onChange={handleOnChangeSelectA} defaultValue={cloudProvider}>
            {cloudProviders.map(({ value, logo, label }) => (
              <Option value={value} key={value}>
                <Image src={logo} preview={false} height={20} />
                {'\u00A0'.repeat(3)}
                {label}
              </Option>
            ))}
          </Select>
          {cloudProvider && (
            <>
              <Text strong> Kubernate version: </Text>
              <Select placeholder="Select Kubernate version Here" defaultValue={k8sVersion} style={spaceWidth} onChange={handleOnChangeSelectB}>
                {k8sVersions.map(version => (
                  <Option value={version} key={version}>
                    {version}
                  </Option>
                ))}
              </Select>
              <Text strong>
                Do you have metacontroller service in your Cluster?
                <HelpDrawerTpl>
                  <Title level={5}> {PROJECT_NAME} </Title>
                  <ul>
                    <li> It uses metacontroller to manage the cluster. </li>
                    <li> If you do not have metacontroller, {PROJECT_NAME} will install it </li>
                    <li> If you have metacontroller, {PROJECT_NAME} will use your version instead of install own version. </li>
                  </ul>
                  <Divider />
                  <Title level={5}> Additional information: </Title>
                  <Text strong>
                    <a href="https://metacontroller.github.io/metacontroller/intro.html" target="_blank">
                      What is a Metacontroller ?
                    </a>
                  </Text>
                  <ul>
                    <li> Metacontroller is an add-on for Kubernetes that makes it easy to write and deploy custom controllers. </li>
                    <li> Although the open-source project was started at Google, the add-on works the same in any Kubernetes Cluster. </li>
                  </ul>
                </HelpDrawerTpl>
              </Text>
              <Select placeholder="Select Your Choice Here" onChange={setHasMetacontroller} defaultValue={hasMetacontroller} style={spaceWidth}>
                {metacontrollerOptions.map(({ value, label }) => (
                  <Option value={value} key={value ? 'yes' : 'no'}>
                    {label}
                  </Option>
                ))}
              </Select>
            </>
          )}
          {cloudProvider && hasMetacontroller !== undefined && k8sVersion && (
            <>
              <InstallationFile {...{ action: 'install', hasMetacontroller, region }} />
              {probablyInstalledUI()}
            </>
          )}
        </>
      )
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={`Configure a cluster`} />
      {/* {clusterCreate()} */}
      {clusterExistence()}
      {createCluster()}
      {hasClusterFalse()}
      {hasClusterTrue()}
    </Space>
  );
};
