import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useAuthedQuery } from 'utils/qlAuth';
import { useMutation } from '@apollo/client';
import { iDeployment } from 'shared/deployment';
import { iPvcItem } from 'shared/pvc';
import { Button, Col, message, Modal, Result, Skeleton, Table, Tooltip, Typography } from 'antd';
import { DeleteOutlined, WarningFilled } from '@ant-design/icons';
import { buttonBorder } from 'utils/styles';

interface iDeploymentPvcListProps {
  deployment: iDeployment;
}

const { Title, Text } = Typography;

export function DeploymentPvcList(props: iDeploymentPvcListProps) {
  const { loading, error, data, stopPolling, startPolling } = useAuthedQuery(
    gql`
      query ApiAgentController_listNamespacedPersistentVolumeClaim($deploymentId: Int!) {
        ApiAgentController_listNamespacedPersistentVolumeClaim(deploymentId: $deploymentId) {
          status
          message
          PvcList
        }
      }
    `,
    { variables: { deploymentId: props.deployment.id }, pollInterval: 25000 },
  );
  startPolling(25000);

  useEffect(() => {
    return stopPolling;
  }, []);

  let DeploymentPvcList: iPvcItem[] = data?.ApiAgentController_listNamespacedPersistentVolumeClaim?.PvcList;

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPvc, setSelectedPvc] = useState<iPvcItem | null>(null);

  const DELETE_PVC_MUTATION = gql`
    mutation DeletePvc($deploymentId: Int!, $pvcName: String!) {
      ApiAgentController_deleteNamespacedPersistentVolumeClaim(deploymentId: $deploymentId, pvcName: $pvcName) {
        status
        answer
      }
    }
  `;

  const [deletePvcMutation] = useMutation(DELETE_PVC_MUTATION, {
    onCompleted: () => {
      message.success('PVC deletion successful');
      setModalVisible(false);
    },
    onError: (error: Error) => message.error('Failed to delete PVC'),
  });

  if (DeploymentPvcList && DeploymentPvcList.length === 0) {
    return <Result status="404" title="No PVCs found" subTitle="No PVCs found for this application" />;
  }

  const showModal = (pvc: iPvcItem) => {
    setSelectedPvc(pvc);
    setModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deletePvcMutation({ variables: { deploymentId: props.deployment.id, pvcName: selectedPvc?.metadata_name || '' } });
    } catch (error) {
      message.error('Failed to delete PVC');
    }
  };

  const labelData = (text: any) => (
    <table>
      {Object.keys(text).map((key, index) => (
        <tr key={index}>
          <td> {key} </td>
          <td> {text[key]} </td>
        </tr>
      ))}
    </table>
  );

  const deleteButton = (text: any, record: iPvcItem) => (
    <Tooltip color="#115EA3" placement="left" title="Click here to delete the entire row">
      <Button danger type="primary" icon={<DeleteOutlined />} onClick={() => showModal(record)} style={buttonBorder} />
    </Tooltip>
  );

  const columns = [
    { title: 'Name', dataIndex: 'metadata_name', key: 'metadata_name' },
    { title: 'Phase', dataIndex: 'status_phase', key: 'status_phase' },
    { title: 'Storage class name', dataIndex: 'spec_storageClassName', key: 'spec_storageClassName' },
    { title: 'Volume mode', dataIndex: 'spec_volumeMode', key: 'spec_volumeMode' },
    { title: 'Access modes', dataIndex: 'spec_accessModes', key: 'spec_accessModes' },
    { title: 'Capacity', dataIndex: 'status_capacity', key: 'status_capacity' },
    { title: 'Created', dataIndex: 'metadata_creationTimestamp', key: 'metadata_creationTimestamp' },
    { title: 'Labels', dataIndex: 'metadata_labels', key: 'metadata_labels', render: labelData },
    { title: 'Action', dataIndex: '', key: '', render: deleteButton },
  ];

  const tableData = () => (
    <Skeleton active={true} loading={loading && !error && !DeploymentPvcList}>
      {DeploymentPvcList && DeploymentPvcList.length > 0 ? <Table columns={columns} dataSource={DeploymentPvcList} /> : null}
    </Skeleton>
  );

  const modalData = () => {
    const modalTitle = (
      <Title type="danger" level={5}>
        <WarningFilled /> &nbsp; Are you sure you want to delete this volume?
      </Title>
    );
    const modalFooter = [
      <Button key="cancel" onClick={() => setModalVisible(false)}>
        Cancel
      </Button>,
      <Button key="delete" type="primary" danger onClick={handleDelete} style={buttonBorder}>
        Delete
      </Button>,
    ];
    const canceled = () => setModalVisible(false);
    return (
      <Modal title={modalTitle} open={modalVisible} onOk={handleDelete} onCancel={canceled} okText="Delete" cancelText="Cancel" footer={modalFooter}>
        <Text> This action is irreversible, and deletion will lead to the loss of data. </Text>
      </Modal>
    );
  };

  return (
    <Col className="gutter-row" span={24}>
      {tableData()}
      {modalData()}
    </Col>
  );
}
