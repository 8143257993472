import { useEffect, useState } from 'react';
import { deploymentService } from 'services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Col, Typography, Switch, Button, Row, notification, Input, Alert, Space } from 'antd';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { spaceWidth, buttonWidth } from 'utils/styles';
import { NullSpaces } from 'components/SharedComponents/NullSpaces/NullSpaces';

interface CronJobsUIProps {
  deployment: iDeployment;
}

const { Text } = Typography;

const FillingHR = () => <hr style={{ margin: 'auto 10px 5px 10px', flex: 1, borderColor: 'transparent' }} />;

const cronJobDefault = {
  SN_CLOSED_EVENTS: { enabled: true, every: 300 },
  SN_NOTIFICATION: { enabled: true, every: 300 },
  SQLDAILY: { enabled: true, every: 300 },
  ITSMCC_RETRY: { enabled: true, every: 300 },
  INSERT_QUERIES: { enabled: true, every: 300 },
  ADHOC_QUERIES: { enabled: true, every: 300 },
  CMDB_HARDWARE_PUSH: { enabled: true, every: 300 },
  CMDB_SOFTWAREPUSH: { enabled: true, every: 300 },
  CLEAR_EVENTS: { enabled: false, every: 300 },
  EXPUNGE_NOT_ACTIVE_DEVICES: { enabled: false, every: 300, params: { EXPUNGE_IF_DEVICE_NOT_ACTIVE: 20000 } },
};

const CronJobItem = ({ name, values, setStore }) => {
  const [open, setOpen] = useState(false);

  const updEnabled = (name: string | number, enabled: any) =>
    setStore((s: { [x: string]: any }) => ({ ...s, ...{ [name]: { ...s[name], enabled } } }));

  const updParams = (nameJob: string | number, nameParam: string | number, valueParam: string) => {
    setStore((s: { [x: string]: { [x: string]: any } }) => ({
      ...s,
      ...{ [nameJob]: { ...s[nameJob], params: { ...s[nameJob][nameParam], [nameParam]: valueParam } } },
    }));
  };

  const icon = open ? <UpCircleOutlined /> : <DownCircleOutlined />;

  return (
    <Col className={'pl-0 mb-3'}>
      <Row className={open ? 'border rounded-top border-1 border-bottom-0 border-dark' : ''} justify={'space-between'} align={'middle'}>
        <Button type={'text'} className="mr-2" icon={icon} onClick={() => setOpen(s => !!!s)} />
        <Text> {name} </Text>
        <FillingHR />
        <Switch defaultChecked={values.enabled} checked={values.enabled} onClick={checked => updEnabled(name, checked)} />
      </Row>
      {open && values?.params && (
        <Col className="border rounded-bottom border-1 border-top-0 border-dark py-3">
          {Object.entries(values.params).map(([nameParam, valParam]: any) => (
            <Row justify={'space-between'} align={'middle'}>
              <Text> {nameParam} </Text>
              <FillingHR />
              <Input value={valParam} onChange={e => updParams(name, nameParam, e.target.value)} />
            </Row>
          ))}
        </Col>
      )}
    </Col>
  );
};

export const CronJobsUI = (props: CronJobsUIProps) => {
  const [form, setForm] = useState({});
  const deployment = props.deployment;

  const DEPLOYMENT_CRON_TASKS = deployment?.env?.CRON_JOBS ? JSON.parse(deployment?.env?.CRON_JOBS) : {};
  const CRON_JOBS = {};

  for (const key of Object.keys(cronJobDefault)) CRON_JOBS[key] = DEPLOYMENT_CRON_TASKS[key] ? DEPLOYMENT_CRON_TASKS[key] : cronJobDefault[key];

  useEffect(() => {
    setForm(CRON_JOBS);
  }, [JSON.stringify(CRON_JOBS)]);

  const save = async () => {
    await deploymentService.updateDeployment(deployment.id, { ...deployment.env, CRON_JOBS: JSON.stringify(form) });
    notification.success({ description: `Saved`, message: '' });
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Text>
        <Text strong> Enable / Disable Jobs </Text> &nbsp; Logs, Info, etc..
      </Text>
      <Alert showIcon type="info" message="Some settings are configured on the Services config page." />
      {deployment?.id && form && Object.entries(form).map(([name, values]) => <CronJobItem name={name} values={values} setStore={setForm} />)}
      <NullSpaces />
      <BottomButtons>
        <Button block type="primary" onClick={save} style={buttonWidth}>
          Save
        </Button>
      </BottomButtons>
    </Space>
  );
};
