import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Modal, Checkbox, List, Flex, Tooltip, Typography, Space, message } from 'antd';
import { ResourcesStates, Pod } from '../../Deployments/ResourcesStates/ResourcesStates';
import { buttonBorder, spaceWidth } from 'utils/styles';
import { DeleteFilled } from '@ant-design/icons';
import { useGqlDeploymentsList } from 'services/deployment.service';

interface ResourcesCardProps {
  deploymentId: number;
}

const { Text } = Typography;
const { Item } = List;

export const ResourcesCard: React.FC<ResourcesCardProps> = ({ deploymentId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPods, setSelectedPods] = useState<string[]>([]);
  const [pods, setPods] = useState<Pod[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const showModal = () => setIsModalVisible(true);

  const commonModal = () => {
    setIsModalVisible(false);
    setSelectedPods([]);
  };

  const handleOk = () => {
    commonModal();
    const podsToDelete = selectedPods.length;
    messageApi[podsToDelete === 0 ? 'warning' : 'success'](
      podsToDelete === 0 ? 'No pods selected for deletion.' : `${podsToDelete} pod${podsToDelete > 1 ? 's' : ''} deleted successfully!`,
    );

    // Call the delete pods mutation here. ( DeploymentsController_deletePod )
    // data is the response from the delete pods mutation.
    // If the response is successful, show the success message.
    // If the response is an error, show the error message.
  };

  const handleCancel = () => commonModal();

  const handleSelectPod = (podId: string) => {
    setSelectedPods(prevSelectedPods =>
      prevSelectedPods.includes(podId) ? prevSelectedPods.filter(id => id !== podId) : [...prevSelectedPods, podId],
    );
  };

  const deleteItems = (pod: Pod) => (
    <Item key={pod.id}>
      <Checkbox checked={selectedPods.includes(pod.id)} onChange={() => handleSelectPod(pod.id)}>
        {pod.name}
      </Checkbox>
    </Item>
  );

  const deleteButton = (
    <Flex justify={'flex-end'}>
      <Tooltip color="#115EA3" placement="right" title="Click here to delete the pods">
        <Button danger type="primary" icon={<DeleteFilled />} onClick={showModal} style={buttonBorder} />
      </Tooltip>
    </Flex>
  );

  const resourcesData = () => (
    <Space direction="vertical" style={spaceWidth}>
      <ResourcesStates deploymentId={deploymentId} onPodsFetched={setPods} /> <Text />
      <Text strong>
        <Link to={`/app/${deploymentId}/logs/pods`}> View Cloud Monitoring Dashboard </Link>
      </Text>
    </Space>
  );

  const resourcesModal = () => (
    <Modal title="Select Pods to Delete" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText="Delete" cancelText="Cancel">
      <Space direction="vertical" style={spaceWidth}>
        <Text strong> Are you sure you want to delete them? </Text>
        <Text> These pods will be deleted forever in this deployment. </Text>
        <List dataSource={pods} renderItem={deleteItems} />
      </Space>
    </Modal>
  );

  return (
    <>
      <Card title="Resources" extra={deleteButton} bordered={false}>
        {resourcesData()}
        {resourcesModal()}
      </Card>
      {contextHolder}
    </>
  );
};
