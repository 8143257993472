import { Space, Tag, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { formatUserName } from 'utils/nameFormat';

const { Text, Paragraph } = Typography;

export default function ItemUpdateComponent(props: any) {
  const keyIgnore = ['action', 'createdAt', 'user'];
  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <Text strong> Username: </Text>
        <Text> {formatUserName(props.values.user)} </Text>
        <Text type="secondary"> ({props.values.user})</Text>
      </Space>

      <Space direction="vertical">
        {Object.keys(props.values).map(key => {
          if (!keyIgnore.includes(key)) {
            return (
              <Paragraph>
                <Tag color="magenta" title="Property name">
                  {key}
                </Tag>
                <ArrowRightOutlined /> &nbsp;
                <Tag color="green" title="New value">
                  {props.values[key].new}
                </Tag>
              </Paragraph>
            );
          }
        })}
      </Space>
    </Space>
  );
}
