import { Divider, Steps, Typography } from 'antd';

const { Text } = Typography;

const DeploymentSteps = ({ current, descriptions }) => {
  const stepTitles = ['Basic Details', 'Selection', 'Customization', 'Deploy'];
  const stepItems = stepTitles.map((title, index) => ({
    title: <Text> {title} </Text>,
    description: descriptions[index] ? <Text type="secondary"> {descriptions[index]} </Text> : null,
  }));

  const DeploymentStepsData = () => (
    <>
      <Text />
      <Steps size="small" labelPlacement="vertical" current={current} items={stepItems} />
      <Divider />
    </>
  );

  return DeploymentStepsData();
};

const help = [
  ['Select Project and Tags', null, null, null],
  [null, 'Select Name and Clusters', null, null],
  [null, null, 'Select Domain and Services', null],
  [null, null, null, 'Ready to Deploy this Deployment'],
];

export const DeploymentStep1 = () => <DeploymentSteps current={0} descriptions={help[0]} />;
export const DeploymentStep2 = () => <DeploymentSteps current={1} descriptions={help[1]} />;
export const DeploymentStep3 = () => <DeploymentSteps current={2} descriptions={help[2]} />;
export const DeploymentStep4 = () => <DeploymentSteps current={3} descriptions={help[3]} />;
