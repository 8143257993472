import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { iRegionModel } from 'shared/deployment';
import { regionsService } from 'services/regions.service';
import { CustomWidgets } from 'form-components';
import { Alert, Button, notification } from 'antd';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { buttonWidth } from 'utils/styles';

export interface iTerraformJobsConfigProps {
  region: iRegionModel;
}

export const TerraformJobsConfig = (props: iTerraformJobsConfigProps) => {
  const handleSubmit = async (e: any) => {
    const data = e.formData;
    const res = await regionsService.updateOwnRegion(props.region.id, data);
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
  };
  const EditRegionSchemaForm: any = {
    required: ['name'],
    type: 'object',
    properties: {
      /* Warning: Change cluster name is dangeros operation if cluster created with terraform. */
      name: { type: 'string', title: 'Cluster name', description: `Changing cluster name is dangerous operation if cluster created with terraform.` },
      regionName: { type: 'string', title: 'Region name', description: 'Cloud provider region name', enum: awsRegionNames },
      TF_AWS_ACCESS_KEY_ID: { type: 'string', title: 'AWS_ACCESS_KEY_ID' },
      TF_AWS_SECRET_ACCESS_KEY: { type: 'string', title: 'AWS_SECRET_ACCESS_KEY' },
    },
  };
  const EdisRegionSchemaFormUI = {
    name: { 'ui:readonly': props.region?.useTerraform },
    regionName: { 'ui:readonly': props.region?.useTerraform },
    TF_AWS_ACCESS_KEY_ID: { 'ui:widget': 'password', 'ui:help': 'AWS_ACCESS_KEY_ID' },
    TF_AWS_SECRET_ACCESS_KEY: { 'ui:widget': 'password', 'ui:help': 'AWS_SECRET_ACCESS_KEY' },
  };

  return (
    <>
      {props.region?.useTerraform && (
        <Alert
          showIcon
          type="warning"
          message="Warning"
          description="Change cluster name is dangerous operation if cluster was created with terraform."
        />
      )}
      <Form
        widgets={CustomWidgets}
        formData={props.region}
        schema={EditRegionSchemaForm}
        uiSchema={EdisRegionSchemaFormUI}
        validator={validator}
        onSubmit={handleSubmit}
        onError={e => console.log('Error from submit: ', e)}
      >
        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonWidth}>
            Save
          </Button>
        </BottomButtons>
      </Form>
    </>
  );
};

export const awsRegionNames = [
  'us-east-2',
  'us-east-1',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-south-2',
  'ap-southeast-3',
  'ap-southeast-4',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'ca-west-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-south-2',
  'eu-north-1',
  'eu-central-2',
  'il-central-1',
  'me-south-1',
  'me-central-1',
  'sa-east-1',
];
