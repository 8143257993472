import gql from 'graphql-tag';
import { ReactElement, ReactNode, ReactPortal, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthedMutationWithNotification, useAuthedQuery } from 'utils/qlAuth';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { TitleUIRow } from 'layout/TitleUI';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { NewProjectForm } from 'components/Projects/NewProjectForm';
import { DeploymentStep1 } from 'components/SharedComponents/CreateSteps/CreateSteps';
import { authService } from 'services/auth.service';
import { Row, Col, Typography, Button, Select, Modal, Skeleton, Tag, Card, Alert, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { buttonWidth, spaceWidth, templateMargin } from 'utils/styles';

interface iExistingContent {
  id: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
  name: any;
  title: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
}

const { Text } = Typography;
const { Option } = Select;
const { Meta } = Card;

/**
 * Create deployment from (/new route)
 * @returns
 */

export default function DeploymentKeyDropDown() {
  const params: any = useParams();
  const preSelectedProject = Number(params?.projectId) || null;

  const [activeProject, setProject] = useState(preSelectedProject || '');
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const history = useHistory();
  const user = useUser();

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList {
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
          tags
        }
        PreconfiguredProjectsController_getPreconfiguredProjects {
          id
          name
          title
          description
          logo
          tags
        }
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    {},
  );

  const [createDeployment, newDeployment] = useAuthedMutationWithNotification(gql`
    mutation DeploymentsController_createDeployment($projectId: Int!, $name: String!, $tags: [Int]) {
      DeploymentsController_createDeployment(projectId: $projectId, deploymentName: $name, deploymentTags: $tags) {
        id
      }
    }
  `);

  if (error) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  if (loading) return <Skeleton active={true} loading={true} />;

  const handleProjectSelect = (e: SetStateAction<string | number>) => setProject(e);

  const handleInputChange = (inputValue: string) => {
    const filtered = (data?.ProjectController_getProjectList || []).filter((project: { title: string }) =>
      project.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
    setFilteredProjects(filtered);
  };

  const onCreateDeployment = async () => {
    const projectId = data?.ProjectController_getProjectList.find((project: { title: string | number }) => project.title === activeProject)?.id;
    return activeProject || projectId
      ? await createDeployment({ variables: { projectId, name: `New deployment proj-${activeProject}`, tags: selectedTags } })
      : undefined;
  };

  if (!userHasPermission(user, UserPermissions.DeploymentCreate)) return <UserNoPermissions permission={UserPermissions.DeploymentCreate} />;

  const handleCreateFromTemplate = async (projectId, name) => {
    // const similarProjects = (data?.ProjectController_getProjectList || []).filter(project => project.id === projectId);
    // @todo: Add call to ProjectController_findSimilarProjects(projectId)
    // Can solve: https://nanoheal.atlassian.net/browse/DP-635
    // And if it returns a project, ask user if they want to use that project instead of creating a new one.
    // It needed for avoinding duplicating projects.

    const result = await authService.getApolloClient().query({
      query: gql`
        query ProjectController_findSimilarProjects($projectId: Int!) {
          ProjectController_findSimilarProjects(projectId: $projectId) {
            id
            name
            title
            description
            logo
            tags
          }
        }
      `,
      variables: { projectId: projectId },
    });

    const similarProjects = result?.data?.ProjectController_findSimilarProjects || [];

    const existingContent = (
      <>
        A similar project already exists. Do you want to use the existing project or create a new copy?
        {similarProjects.map((elem: iExistingContent) => {
          const handleButtonClick = async () =>
            await createDeployment({ variables: { projectId: elem.id, name: `New deployment ${elem.name}`, tags: selectedTags } });
          return (
            <Button onClick={handleButtonClick}>
              Copy from ({elem.id}) {elem.title}
            </Button>
          );
        })}
      </>
    );

    const existingProjects = {
      title: 'Use Existing Project',
      icon: <InfoCircleOutlined />,
      content: existingContent,
      cancelText: 'Cancel',
      okText: 'Create a new copy',
      async onOk() {
        await createDeployment({ variables: { projectId, name: `New deployment ${name}`, tags: selectedTags } });
      },
    };

    const createContent = (
      <Space direction="vertical">
        <Text> The project template will be automatically copied from the general template library and added to your own project list. </Text>
        <Text> You can find it on the Projects tab and edit it if you wish. </Text>
      </Space>
    );

    const creatingProjects = {
      title: 'Creating a deployment from a project template',
      icon: <InfoCircleOutlined />,
      content: createContent,
      async onOk() {
        await createDeployment({ variables: { projectId, name: `New deployment ${name}`, tags: selectedTags } });
      },
      onCancel() {
        console.log('Cancel');
      },
    };

    Modal.confirm(similarProjects.length > 0 ? existingProjects : creatingProjects);
  };

  const newDeploymentId = newDeployment?.data?.DeploymentsController_createDeployment?.id;
  if (newDeploymentId) {
    history.push(`/app/${newDeploymentId}/setting/details`);
    return <Skeleton active={true} loading={true} />;
  }

  const tags = data?.TagsController_tagsList || [];

  const projectsFilter = () => {
    return (filteredProjects.length > 0 ? filteredProjects : data?.ProjectController_getProjectList || []).map(elem => (
      <Option key={elem.id} value={elem.title}>
        <Space>
          {elem.logo && <LogoAvatar logo={elem.logo} name={elem.title} />}
          {elem.title}
          <Text italic style={{ color: '#CCCCCC' }}>
            {elem.description}
          </Text>
          {elem.tags &&
            elem.tags.map(tag => (
              <Tag color="green" key={tag}>
                {tag}
              </Tag>
            ))}
        </Space>
      </Option>
    ));
  };

  const createNewProject = () => {
    const createButton = (
      <Tooltip color="#115EA3" placement="left" title="You can create your own first project here.">
        <Button size="large" type="primary">
          Create a New Project
        </Button>
      </Tooltip>
    );
    return <NewProjectForm openBtnUI={createButton} />;
  };

  const nullAlert = () => (
    <>
      {userHasPermission(user, UserPermissions.ProjectsMange) ? (
        <Alert
          type="info"
          message={`You don't have any own projects yet. You can create one or use one of Pre-configured projects.`}
          action={createNewProject()}
        />
      ) : null}
    </>
  );

  const chooseProjects = () => (
    <Row>
      <Col span={12}>
        <Text strong> Choose template </Text>
      </Col>
      <Col span={12}>
        <Select
          showSearch
          placeholder="Choose template here"
          style={spaceWidth}
          onChange={handleProjectSelect}
          onSearch={handleInputChange}
          filterOption={false}
        >
          {projectsFilter()}
        </Select>
      </Col>
    </Row>
  );

  const chooseTags = () => (
    <>
      {tags.length !== 0 ? (
        <Row>
          <Col span={12}>
            <Text strong> Tags </Text>
          </Col>
          <Col span={12}>
            <Select placeholder="Choose Tags Here" mode="multiple" onChange={v => setSelectedTags(v)} style={spaceWidth}>
              {tags.map(elem => (
                <Option value={elem.id} key={elem.id}>
                  <Tag color={elem.color}> {elem.name} </Tag>
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      ) : null}
    </>
  );

  const dropdownData = () => (
    <>
      {chooseProjects()}
      {chooseTags()}
    </>
  );

  const continueButton = () => (
    <>
      {data.ProjectController_getProjectList.length !== 0 ? (
        <Row>
          <Col span={24}>
            <Button type="primary" onClick={onCreateDeployment} disabled={!activeProject} style={buttonWidth}>
              Continue
            </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );

  const templateInfo = () => <Alert showIcon type="info" message={`You can use one of Pre-configured project templates (Deploy additional apps).`} />;

  const templateData = () => (
    <Row gutter={[16, 16]}>
      {data.PreconfiguredProjectsController_getPreconfiguredProjects.map(elem => {
        const cardExtra = (
          <Tooltip
            color="#115EA3"
            placement="leftTop"
            title="You can deploy preconfigured ready-made project. It allow to deploy any software in your cluster in several minutes."
          >
            <Button data-qa="PreconfiguredProjects-Deploy-btn" onClick={() => handleCreateFromTemplate(elem.id, elem.name)}>
              Deploy
            </Button>
          </Tooltip>
        );

        const deployTags = () => (
          <Space direction="horizontal">
            {elem.tags &&
              elem.tags.map(tag => (
                <Tag color="green" key={tag}>
                  {tag}
                </Tag>
              ))}
          </Space>
        );

        const templateDeployData = () => (
          <Col key={elem.id} xs={24} sm={12} md={8}>
            <Card size="small" type="inner" title={elem.title} extra={cardExtra} style={templateMargin}>
              <Space direction="vertical">
                <Meta avatar={elem.logo && <LogoAvatar logo={elem.logo} name={elem.title} />} description={elem.description} />
                <Text /> <Text />
                {deployTags()}
              </Space>
            </Card>
          </Col>
        );

        return templateDeployData();
      })}
    </Row>
  );

  const createDeploymentStep1 = () => (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title="Create a new deployment based on template" />
      <DeploymentStep1 />
      {data.ProjectController_getProjectList.length === 0 ? nullAlert() : dropdownData()}
      <Text />
      {continueButton()}
      {/* <Text /> <Text />
      {templateInfo()}
      <Text />
      {templateData()} */}
    </Space>
  );

  return createDeploymentStep1();
}
