import DashboardItem from 'components/ChartRenderer/DashboardItem';
import { useHistory, useParams } from 'react-router-dom';
import { ChartRenderer } from 'components/ChartRenderer/ChartRenderer';
import { stats_availability_day_time, stats_availability_hour_time, stats_availability_long_time } from './stats_availability';
import { stats_aws_ce } from './stats_aws_ce';
import { stats_opencost } from './stats_opencost';
import { Col, Space, Tabs } from 'antd';
import { clusterHeight, spaceWidth } from 'utils/styles';

const { TabPane } = Tabs;

const tabsStats = [
  { tab: 'AWS Fee', key: 'aws-ce', stats: stats_aws_ce },
  { tab: 'OpenCost', key: 'opencost', stats: stats_opencost },
  { tab: 'Availability', key: 'availability-long-time', stats: stats_availability_long_time },
  { tab: 'Availability (Day)', key: 'availability-day', stats: stats_availability_day_time },
  { tab: 'Availability (Hour)', key: 'availability-hour', stats: stats_availability_hour_time },
];

export const TenantCharts = () => {
  // @ts-ignore
  const { tabName } = useParams();
  const history = useHistory();
  const handleTabClick = (key: string) => history.push(`/charts/${key}`);

  if (!tabName) history.push(`/charts/aws-ce`);
  return (
    <Space direction="vertical" style={spaceWidth}>
      <Tabs defaultActiveKey={tabName || 'main'} onChange={handleTabClick}>
        {tabsStats.map((TabStats, index) => (
          <TabPane tab={TabStats.tab} key={TabStats.key}>
            {TabStats.stats.map((stat, index) => (
              <Col span={stat.span || 24} key={stat.id || index} style={clusterHeight}>
                <DashboardItem title={stat.name}>
                  <ChartRenderer vizState={stat.vizState} />
                </DashboardItem>
              </Col>
            ))}
          </TabPane>
        ))}
      </Tabs>
    </Space>
  );
};
