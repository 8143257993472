import { Typography, Tooltip, Button, Space, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

export const CodeLine = ({ children }) => {
  const { Paragraph } = Typography;

  const handleCopy = () => navigator.clipboard.writeText(children).then(() => message.success('Snippet copied successfully...'));

  return (
    <Space direction="horizontal">
      <Tooltip color="#115EA3" placement="right" title="Click to copy this snippet">
        <Button type="link" icon={<CopyOutlined />} onClick={handleCopy} />
      </Tooltip>
      <Paragraph>
        <pre>{children}</pre>
      </Paragraph>
    </Space>
  );
};
