import { Link } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import { Tooltip, Tag } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { orangeColor, redColor } from 'utils/styles';

interface iDeploymentErrorsProps {
  deployment: iDeployment;
}

/**
 * Render deployment tags and project tags for the deployment
 * @param props
 * @returns
 */

export const DeploymentErrors = (props: iDeploymentErrorsProps) => {
  const deployment = props.deployment;
  const specsErrors = deployment?.specsErrors;
  const specsWarns = deployment?.specsWarns;
  let YamlIcons = null;

  const YamlIconsSpecsErrors = () => (
    <Link to={`/app/${deployment.id}/specs/yaml`}>
      <Tag color="error" icon={<WarningOutlined twoToneColor={'red'} style={redColor} />}>
        <Tooltip color="#115EA3" title="This deployment has errors in the yaml specs" placement="bottom">
          {specsErrors} errors
        </Tooltip>
      </Tag>
    </Link>
  );

  const YamlIconsSpecsWarns = () => (
    <Link to={`/app/${deployment.id}/specs/yaml`}>
      <Tag color="warning" icon={<WarningOutlined twoToneColor={'orange'} style={orangeColor} />}>
        <Tooltip color="#115EA3" title="This deployment has warnings in the yaml specs" placement="bottom">
          {specsWarns} warnings
        </Tooltip>
      </Tag>
    </Link>
  );

  if (specsErrors) YamlIcons = YamlIconsSpecsErrors();
  else if (specsWarns) YamlIcons = YamlIconsSpecsWarns();

  return YamlIcons;
};
