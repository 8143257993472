import gql from 'graphql-tag';
import Meta from 'antd/es/card/Meta';
import { useState } from 'react';
import { useAuthedQuery } from 'utils/qlAuth';
import { LogoAvatar } from 'components/SharedComponents/LogoAvatar/LogoAvatar';
import { getDescription } from 'components/Projects/Projects';
import { Alert, Card, Col, Flex, Row, Skeleton, Tag, Input, Tooltip, Space, Button, Result, Typography } from 'antd';
import { UnorderedListOutlined, AppstoreOutlined, CaretLeftFilled } from '@ant-design/icons';
import { topMargin, integrationCardHeight, spaceWidth } from 'utils/styles';

const { Text } = Typography;
const { Search } = Input;

export const IntegrationsList = () => {
  const [isCompactView, setIsCompactView] = useState(false);
  const [activeButton, setActiveButton] = useState<'list' | 'card'>('list');
  const [searchQuery, setSearchQuery] = useState('');

  const { loading, error, data } = useAuthedQuery(
    gql`
      query PluginsController_getAllPlugins {
        PluginsController_getAllPlugins {
          id
          name
          title
          description
          logo
          tags
        }
      }
    `,
    {},
  );

  if (loading) return <Skeleton active={true} loading={true} />;

  const filteredData = data.PluginsController_getAllPlugins.filter((elem: { title: string }) =>
    elem.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const toggleView = (view: 'list' | 'card') => {
    setIsCompactView(view === 'card');
    setActiveButton(view);
  };

  const renderIntegrations = () => {
    if (filteredData.length === 0) {
      const noResults = (
        <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
          Back to Integrations
        </Button>
      );
      return (
        <Col span={24}>
          <Result
            status="404"
            subTitle="This Integration does not exist... Please search for appropriate Integration"
            extra={noResults}
            style={topMargin}
          />
        </Col>
      );
    }

    const cardExtra = (elem: { tags: string[] }) =>
      elem.tags && (
        <Flex justify={'flex-end'}>
          {elem.tags.map((tag: string, index: number) => (
            <Tag color="green" key={index}>
              {tag}
            </Tag>
          ))}
        </Flex>
      );

    return filteredData.map(elem => (
      <Col span={isCompactView ? 8 : 24} key={elem.id}>
        <Space direction="vertical" style={spaceWidth}>
          <Card
            size="small"
            type="inner"
            bordered={false}
            title={elem.title}
            extra={cardExtra(elem)}
            style={isCompactView ? integrationCardHeight : ''}
          >
            <Space direction="horizontal">
              <Meta avatar={elem.logo && <LogoAvatar logo={elem.logo} name={elem.title} />} />
              {getDescription(elem.description, isCompactView)}
            </Space>
          </Card>
        </Space>
      </Col>
    ));
  };

  const searchViews = () => (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <Search
          placeholder="Search using integration name"
          enterButton
          allowClear
          onChange={e => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
      </Col>
      <Col span={14}>
        <Flex justify={'flex-end'}>
          <Space direction="horizontal">
            <Tooltip color="#115EA3" title="List View" placement="top">
              <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('list')} type={activeButton === 'list' ? 'primary' : 'default'} />
            </Tooltip>
            <Tooltip color="#115EA3" title="Grid View" placement="top">
              <Button icon={<AppstoreOutlined />} onClick={() => toggleView('card')} type={activeButton === 'card' ? 'primary' : 'default'} />
            </Tooltip>
          </Space>
        </Flex>
      </Col>
    </Row>
  );

  const integrationData = () => (
    <Space direction="vertical" style={spaceWidth}>
      {searchViews()}
      <Alert
        closable
        showIcon
        type="info"
        message={`Here is a full list of available Integrations. Installed integrations you can find in Cluster configuration page.`}
        action={
          <Text strong type="secondary">
            {`Total of ${filteredData.length} Integrations.`}
          </Text>
        }
      />
      <Row gutter={[16, 16]}> {renderIntegrations()} </Row>
      <Text />
    </Space>
  );

  return integrationData();
};
