import { ReactElement, useContext, useEffect, useState } from 'react';
import { iProject } from 'interface/project';
import { Button, Col, Form, Input, Menu, notification, Row, Skeleton, Space, Tabs, Typography } from 'antd';
import { projectService, ProjectServiceList } from 'services/project.service';
import { Link, useHistory, useParams } from 'react-router-dom';
import { NewService } from './NewService';
import { useLazyQuery } from '@apollo/client';
import { ProjectDeploymentContext, ProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { ProjectCreateService } from 'components/Projects/Settings/ProjectCreateService';
import { ProjectOneServiceSettingsMainTab } from 'components/Projects/Settings/ProjectService/MainTab';
import { ProjectOneServiceSettingsPostProcessing } from 'components/Projects/Settings/ProjectService/PostProcessingTab';
import { ProjectOneServiceCustomYAML } from 'components/Projects/Settings/ProjectService/ProjectOneServiceCustomYAML';
import { ProjectOneServiceDeploymentConfigurationTab } from 'components/Projects/Settings/ProjectService/ProjectOneServiceDeploymentConfigurationTab';
import { ProjectOneServiceSettingsFormKEDA } from 'components/Projects/Settings/ProjectService/ProjectOneServiceSettingsFormKEDA';
import { ProjectOneServiceSettingsFormRoutes } from 'components/Projects/Settings/ProjectService/ProjectOneServiceSettingsFormRoutes';
import { ProjectOneServiceVariables } from 'components/Projects/Settings/ProjectService/ProjectOneServiceVariables';
import gql from 'graphql-tag';
import { useApiQuery } from 'utils/common';
import { topMargined, innerSidebar, siderStyle, buttonBorder, menuButton, innerSidebarButton } from 'utils/styles';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { ProjectOneServiceSettingsHelmTab } from 'components/Projects/Settings/ProjectService/HelmTab';
import { iCloudProjectServiceYamlSpecs } from 'shared/project.interface';
import { DockerWizard } from 'components/Projects/YamlEditor/wizard/DockerWizard';
import { PROJECT_CODE_NAME } from 'interface/common';
import { YamlEditor } from 'components/Projects/YamlEditor/YamlEditor';
import TabPane from 'antd/lib/tabs/TabPane';

const { SubMenu, Item } = Menu;
const { Text, Title } = Typography;

/**
 * http://localhost:3000/#/app/43/configuration/services
 *
 * @param props
 * @returns
 */
export const ConfigurationServices = (props: { deployment: iDeployment; serviceName: string }): ReactElement => {
  const history = useHistory();
  const project: iProjectModel = props.deployment?.ProjectModel;

  const params: any = useParams();
  const { serviceName, activeTabType } = params;

  const deploymentId = props.deployment?.id;
  const [getSyncVars, { loading, error, data }] = useLazyQuery(
    gql`
      query DeploymentsController_debugUI_deploymentSyncVars($deploymentId: Int!) {
        DeploymentsController_debugUI_deploymentSyncVars(deploymentId: $deploymentId) {
          syncVars
        }
      }
    `,
  );

  const [serviceData, serviceError, serviceLoader] = useApiQuery(
    () => projectService.getProjectService(project?.id, serviceName),
    [project?.id, serviceName],
  );
  const service: iCloudProjectServiceYamlSpecs = serviceData;

  useEffect(() => {
    if (deploymentId) getSyncVars({ variables: { deploymentId: deploymentId } });
  }, [deploymentId]);

  if (loading || serviceLoader) {
    return <Skeleton active={true} loading={true} />;
  }

  if (serviceName === 'new') {
    return <NewService deployment={props.deployment} />;
  }

  const uiType = service?.uiType || null;
  let defaultOpenKey = 'main';
  if (uiType === `helm`) {
    defaultOpenKey = 'helm';
  } else if (uiType === `docker`) {
    defaultOpenKey = 'deployment-configuration';
  } else if (uiType === `k8s`) {
    defaultOpenKey = 'custom-yaml';
  }

  const selectedTabType = activeTabType || defaultOpenKey;
  const projectFunctions = { service: service, project: project, serviceName: serviceName, tabType: selectedTabType };

  const handleTabClick = (key: any) => {
    history.push(`/app/${deploymentId}/configuration/services/${serviceName}/${key}`);
  };

  // Metrics
  // Service Details
  // Console
  // Logs
  const gitTabs = [
    [`helm`, null].includes(uiType) ? { key: 'main', tab: 'Settings', component: <ProjectOneServiceSettingsMainTab {...projectFunctions} /> } : null,
    [`helm`, null].includes(uiType)
      ? { key: 'post-processing-rules', tab: 'Post Processing', component: <ProjectOneServiceSettingsPostProcessing {...projectFunctions} /> }
      : null,
    [`helm`, `k8s`, null].includes(uiType)
      ? { key: 'custom-yaml', tab: 'Custom YAML Template', component: <ProjectOneServiceCustomYAML {...projectFunctions} /> }
      : null,
    [`docker`, null].includes(uiType)
      ? {
          key: 'deployment-configuration',
          tab: 'Wizard',
          component: (
            <DockerWizard
              key={`wizard`}
              serviceName={serviceName}
              fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/template.yaml`}
              project={project}
            />
          ),
        }
      : null,
    [`docker`, null].includes(uiType)
      ? {
          key: 'deployment-yaml',
          tab: 'YAML advanced configuration',
          component: <YamlEditor key={`yaml-editor`} fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/template.yaml`} project={project} />,
        }
      : null,
    [`helm`, null].includes(uiType) ? { key: 'helm', tab: 'HELM', component: <ProjectOneServiceSettingsHelmTab {...projectFunctions} /> } : null,
    { key: 'variables', tab: 'Variables', component: <ProjectOneServiceVariables {...projectFunctions} /> },
    { key: 'keda-autoscaling', tab: 'Autoscaling', component: <ProjectOneServiceSettingsFormKEDA {...projectFunctions} /> },
    { key: 'route', tab: 'Routes', component: <ProjectOneServiceSettingsFormRoutes {...projectFunctions} /> },
  ].filter(Boolean);

  return (
    <ProjectDeploymentSyncVarsContext.Provider
      value={{ serviceName: serviceName, syncVars: data?.DeploymentsController_debugUI_deploymentSyncVars?.syncVars }}
    >
      <Tabs defaultActiveKey={selectedTabType} onChange={handleTabClick}>
        {gitTabs.map(tab => (
          <TabPane key={tab.key} tab={tab.tab}>
            {tab.component}
          </TabPane>
        ))}
      </Tabs>
    </ProjectDeploymentSyncVarsContext.Provider>
  );
};
