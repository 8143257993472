import gql from 'graphql-tag';
import { layout } from 'components/Deployments/Setting/common';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';

const { Item } = Form;
const { Option } = Select;

export const EditJob = (props: any) => {
  const [updateJobFunction, updateJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_updateJob($jobId: Int!, $variables: JSON!) {
      JobsController_updateJob(jobId: $jobId, variables: $variables) {
        id
        status
        region
        createdAt
        updatedAt
        variables
        variables
      }
    }
  `);

  const [runJobFunction, runJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_runJob($jobId: Int!) {
      JobsController_runJob(jobId: $jobId) {
        id
      }
    }
  `);

  const editJob = props.editJob;
  const handleSubmit = async (values: any) => {
    try {
      await updateJobFunction({ variables: { jobId: editJob.id, variables: values } });
      await runJobFunction({ variables: { jobId: editJob.id } });
      props.closeModal();
    } catch (error) {
      console.error(`Error in updateJobFunction or runJobFunction`, error);
    }
  };

  const editJobForm = () => {
    const handleOnFinish = async value => await handleSubmit(value);
    const maxCapacityValue = editJob && editJob.variables && editJob.variables.max_capacity ? editJob.variables.max_capacity : 6;
    const minCapacityValue = editJob && editJob.variables && editJob.variables.min_capacity ? editJob.variables.min_capacity : 1;
    const desiredCapacityValue = editJob && editJob.variables && editJob.variables.desired_capacity ? editJob.variables.desired_capacity : 2;
    const instanceTypeValue = editJob && editJob.variables && editJob.variables.instance_types ? editJob.variables.instance_types : 'm4.xlarge';
    const clusterVersionValue = editJob && editJob.variables && editJob.variables.cluster_version ? editJob.variables.cluster_version : '1.30';
    const diskSizeValue = editJob && editJob.variables && editJob.variables.disk_size ? editJob.variables.disk_size : 80;

    const clusterVersionData = () => {
      const clusterVersionOptions = ['1.30', '1.29'];
      return (
        <Select defaultValue={clusterVersionValue}>
          {clusterVersionOptions.map(option => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      );
    };

    const formItems = [
      { name: 'max_capacity', label: 'Max capacity', component: <InputNumber defaultValue={maxCapacityValue} />, initialValue: maxCapacityValue },
      { name: 'min_capacity', label: 'Min capacity', component: <InputNumber defaultValue={minCapacityValue} />, initialValue: minCapacityValue },
      {
        name: 'desired_capacity',
        label: 'Desired capacity',
        component: <InputNumber defaultValue={desiredCapacityValue} />,
        initialValue: desiredCapacityValue,
      },
      {
        name: 'instance_types',
        label: 'Instance types',
        component: <Input defaultValue={instanceTypeValue} />,
        initialValue: instanceTypeValue,
        help: '(comma separated string list)',
      },
      { name: 'cluster_version', label: 'Cluster version', component: clusterVersionData(), initialValue: clusterVersionValue },
      { name: 'disk_size', label: 'Disk size (in GB)', component: <InputNumber defaultValue={diskSizeValue} />, initialValue: diskSizeValue },
    ];

    return (
      <Form onFinish={handleOnFinish}>
        {formItems.map(({ name, label, component, initialValue, help }) => (
          <Item key={name} {...layout} name={name} label={label} initialValue={initialValue} help={help}>
            {component}
          </Item>
        ))}
        <Button type="primary" htmlType="submit">
          Save & Run
        </Button>
      </Form>
    );
  };

  return editJobForm();
};
