import { Link, useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { YamlTab } from './General/YamlTab';
import { RoutesTab } from './General/RoutesTab';
import { DeploymentSyncVars } from './General/DeploymentSyncVars';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { Col, Menu, Row, Skeleton } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { innerSidebar, orangeColor, redColor, siderStyle, topMargined, topPadding } from 'utils/styles';

export default function DeploymentSpecsPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();

  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;

  // const [status, errorStatus, loadingStatus] = useApiQueryLoop(() => deploymentService.getDeploymentStatus(id));

  if (!tabName) history.push(`/app/${id}/specs/yaml`);

  if (error) {
    return (
      <Skeleton active={false} loading={false}>
        {JSON.stringify(error)}
      </Skeleton>
    );
  }

  if (loading || !deployment) return <Skeleton active={true} loading={true} />;

  if (!deployment.isReady) {
    history.push(`/app/${deployment.id}/specs/yaml`);
    return <Skeleton active={true} loading={true} />;
  }

  const specsErrors = deployment?.specsErrors;
  const specsWarns = deployment?.specsWarns;

  let YamlIcons = null;
  YamlIcons = specsErrors ? (
    <WarningOutlined twoToneColor={'red'} style={redColor} />
  ) : specsWarns ? (
    <WarningOutlined twoToneColor={'orange'} style={orangeColor} />
  ) : null;

  const menuData = () => {
    const handleClick = (e: { key: any }) => history.push(`/app/${id}/specs/${e.key}`);
    const menuItems = [
      { key: 'yaml', icon: YamlIcons, label: <Link to={`/app/${deployment.id}/specs/yaml`}> Yaml </Link> },
      { key: 'routes', label: <Link to={`/app/${deployment.id}/specs/routes`}> Routes </Link> },
      { key: 'sync-vars', label: <Link to={`/app/${deployment.id}/specs/sync-vars`}> Sync vars </Link> },
    ];
    return (
      <Col flex={'250px'} style={innerSidebar}>
        <Menu
          style={siderStyle}
          mode="inline"
          defaultSelectedKeys={[tabName]}
          defaultOpenKeys={[tabName, `pods`]}
          onClick={handleClick}
          items={menuItems}
        />
      </Col>
    );
  };

  const tabData = () => {
    const tabComponents = {
      yaml: <YamlTab deployment={deployment} />,
      routes: <RoutesTab deployment={deployment} />,
      'sync-vars': <DeploymentSyncVars deployment={deployment} />,
    };
    return (
      <Col flex="auto">
        <div style={topPadding}> {tabComponents[tabName]} </div>
      </Col>
    );
  };

  return (
    <>
      <TitleUIRow title={`Deployment: ${deployment.name}`} />
      <DeploymentWidgetsRow deployment={deployment} />
      <Row gutter={[0, 0]} wrap={false} style={topMargined}>
        {menuData()}
        {tabData()}
      </Row>
    </>
  );
}
