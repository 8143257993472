import gql from 'graphql-tag';
import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { iRegionModel } from 'shared/deployment';
import { regionsService } from 'services/regions.service';
import { CustomWidgets } from 'form-components';
import { Alert, Button, Skeleton, notification } from 'antd';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { buttonWidth } from 'utils/styles';
import { useAuthedQuery } from 'utils/qlAuth';

export interface iLoadBalancerProps {
  region: iRegionModel;
}

export const LoadBalancer = (props: iLoadBalancerProps) => {
  const { loading, error, data, stopPolling, startPolling, refetch } = useAuthedQuery(
    gql`
      query ApiAgentController_findExternalServices($regionId: Int!) {
        ApiAgentController_findExternalServices(regionId: $regionId) {
          status
          message
          services
        }
      }
    `,
    { variables: { regionId: props.region.id } /*, pollInterval: 5000*/ },
  );

  const handleSubmit = async (e: any) => {
    const data = e.formData;
    const res = await regionsService.updateOwnRegion(props.region.id, data);
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
  };

  if (loading) {
    return <Skeleton active={true} loading={true} style={{ width: '1000px' }} />;
  }

  const urlEnums = data?.ApiAgentController_findExternalServices?.services || [];
  const urlDescription = `If you have a load balancer, you can specify it here. It will be used for automated DNS configurations for deployments domains.`;
  const typeEnums = ['Nginx', 'Traefik', 'Other', 'None'];

  const services = data?.ApiAgentController_findExternalServices?.services || [];

  const loadBalancerInput = {
    loadBalancerURL: { type: 'string', default: services[0] || '', title: 'Load balancer URL', description: urlDescription, examples: urlEnums },
    loadBalancerType: { type: 'string', title: 'Load balancer type', description: `Load balancer type`, default: 'Nginx', enum: typeEnums },
  };

  const EditRegionSchemaForm: any = { type: 'object', properties: loadBalancerInput };
  const EditRegionSchemaForm_UIFormSchema = {
    loadBalancerURL: { 'ui:placeholder': services[0] },
    loadBalancerType: { 'ui:widget': 'select' },
  };

  const loadBalancerAlert = () => {
    const alertDescription = services.map((item, index) => <li> {item} </li>);
    return <Alert message="We found this loadbalancers in your cluster." description={alertDescription} type="info" showIcon />;
  };

  const loadBalancerForm = () => (
    <Form
      widgets={CustomWidgets}
      formData={props.region}
      uiSchema={EditRegionSchemaForm_UIFormSchema}
      schema={EditRegionSchemaForm}
      validator={validator}
      onSubmit={handleSubmit}
      onError={e => console.log('Error from submit: ', e)}
    >
      <BottomButtons>
        <Button type="primary" htmlType="submit" style={buttonWidth}>
          Save
        </Button>
      </BottomButtons>
    </Form>
  );

  return (
    <>
      {data?.ApiAgentController_findExternalServices?.services?.length > 0 && loadBalancerAlert()}
      {loadBalancerForm()}
    </>
  );
};
