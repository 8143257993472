import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { iRegionModel } from 'shared/deployment';
import { regionsService } from 'services/regions.service';
import { CustomWidgets } from 'form-components';
import { Button, notification } from 'antd';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { buttonWidth } from 'utils/styles';
import { PROJECT_CODE_NAME } from 'interface/common';

export interface iGeneralSettingsProps {
  region: iRegionModel;
}

export const GeneralSettings = (props: iGeneralSettingsProps) => {
  const EditRegionSchemaForm: any = {
    type: 'object',
    properties: {
      /* Warrning: Change cluster name is dangeros operation if cluster created with terraform. */
      name: {
        type: 'string',
        title: 'Cluster name',
        description: `Change cluster name is dangeros operation if cluster created with terraform.`,
      },
      title: {
        type: 'string',
        title: 'Cluster title',
        description: `Name for this cluster in ${PROJECT_CODE_NAME} ui.`,
      },
      cloudProvider: {
        type: 'string',
        title: 'Cloud provider',
        enum: ['aws', 'azure', 'gcp', 'k3s', 'onprem'],
        description: `You cloud provider. (AWS, Azure, Google cloud, ...)`,
      },
      /**
       * Cloud provider region name (For example: us-west-2, us-east-1, etc.)
       */
      regionName: {
        type: 'string',
        title: 'Region name',
        description: `Cloud provider region name (For example: us-west-2, us-east-1, etc.)`,
      },
      kubernetesVersion: {
        type: 'string',
        title: 'Kubernetes version',
        enum: ['1.25', '1.26', '1.27', '1.28', '1.29', '1.30'],
      },
    },
  };

  if (props.region.cloudProvider === 'aws') {
    delete EditRegionSchemaForm.properties.name;
    delete EditRegionSchemaForm.properties.regionName;
  }
  const EdisRegionSchemaFormUI = {
    cloudProvider: {
      'ui:readonly': props.region?.useTerraform,
    },
    kubernetesVersion: {
      'ui:readonly': props.region?.useTerraform,
    },
  };

  const handleSubmit = async (e: any) => {
    const data = e.formData;
    const res = await regionsService.updateOwnRegion(props.region.id, data);
    if (res.error) notification.error({ message: `Error - ${res.error}` });
    else notification.success({ message: `Ready` });
  };

  return (
    <Form
      widgets={CustomWidgets}
      formData={props.region}
      schema={EditRegionSchemaForm}
      uiSchema={EdisRegionSchemaFormUI}
      validator={validator}
      onSubmit={handleSubmit}
      onError={e => console.log('Error from submit: ', e)}
    >
      <BottomButtons>
        <Button type="primary" htmlType="submit" style={buttonWidth}>
          Save
        </Button>
      </BottomButtons>
    </Form>
  );
};
