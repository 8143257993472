import { Deploymentk8sEventsList } from './Deploymentk8sEventsList';
import { iDeployment } from 'shared/deployment';
import { TitleUIRow } from 'layout/TitleUI';
import { Alert, Space } from 'antd';
import { spaceWidth } from 'utils/styles';

export interface ik8sEventsProps {
  deployment: iDeployment;
}

export default function K8sEvents(props: ik8sEventsProps) {
  const deployment = props.deployment;
  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title="Deployment Events" />
      <Alert showIcon type="info" message="Kubernetes events." />
      <Deploymentk8sEventsList deployment={deployment} />
    </Space>
  );
}
