import { projectService } from '../../../../services/project.service';
import { useApiQuery } from '../../../../utils/common';
import { useHistory } from 'react-router-dom';
import { updateDeployment, layout } from '../common';
import { ServicesRequests } from './ServicesRequests';
import { iDeployment } from 'shared/deployment';
import { Form, Button, Skeleton, Typography } from 'antd';
import { buttonWidth, textCenter } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { NullSpaces } from 'components/SharedComponents/NullSpaces/NullSpaces';

interface iDeploymentServicesSettingsFormProps {
  deployment: iDeployment;
}

const { Title } = Typography;
const { Item } = Form;

export default function DeploymentServicesSettingsForm(props: iDeploymentServicesSettingsFormProps) {
  const history = useHistory();
  const [services, error, loading] = useApiQuery(() => projectService.getProjectServiceList(deployment.projectId));
  const deployment = props.deployment;
  const servicesArr = services;

  if (error || loading) return <Skeleton active={true} loading={true} />;
  if (error) history.push('/');

  return (
    <>
      {deployment.freezed ? (
        <Title level={5} style={textCenter}>
          This deployment is frozen, to edit the parameters, change the value of General -&gt; frozen to "false" element implicitly has type 'any'
        </Title>
      ) : (
        ''
      )}
      <Form onFinish={values => updateDeployment(deployment.id, values)} {...layout}>
        {servicesArr.map(key => (
          <ServicesRequests key={key.name} deployment={deployment} value={key.name} UIshowRequestsConfig={key.UIshowRequestsConfig} />
        ))}
        {deployment.freezed ? (
          ''
        ) : (
          <Item>
            <BottomButtons>
              <Button type="primary" htmlType="submit" style={buttonWidth}>
                Save
              </Button>
            </BottomButtons>
          </Item>
        )}
      </Form>
      <NullSpaces />
    </>
  );
}
