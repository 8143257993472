import { useEffect, useState } from 'react';
import { projectService } from '../../../services/project.service';
import { Console } from 'console-feed';
import { Skeleton } from 'antd';
import { logsStyle, logsText } from 'utils/styles';

export default function LogsBoard(props) {
  const projectId = props.projectId;
  const [projectLogs, setProjectLogs] = useState(null);

  async function getProjectLogs(projectId) {
    let logs = await projectService.getProjectLogs(projectId);
    logs = logs.data.projectLogs;
    let state: { logs: any[] };
    state = { logs: [] };
    Object.keys(logs).map(elem => {
      let method = '';
      ['error', 'info', 'debug', 'warn', 'log'].includes(logs[elem].type) ? (method = logs[elem].type) : (method = 'info');
      state.logs.unshift({ method: method, data: [logs[elem].createdAt, logs[elem].message, logs[elem].data] });
      // if (Object.keys(logs[elem].data).length) {
      //   const propsObj = Object.keys(logs[elem].data).reduce((acc, key) => {
      //     const val = logs[elem].data[key];
      //     acc[key] =
      //       (val && typeof val === 'object') || Array.isArray(val)
      //         ? Object.keys(val).reduce((subPropsObj, key2) => {
      //             subPropsObj[key2] =
      //               (val[key2] && typeof val[key2] === 'object') || Array.isArray(val[key2]) ? JSON.stringify(val[key2]) : val[key2];
      //             return subPropsObj;
      //           }, {})
      //         : val;
      //     return acc;
      //   }, {});
      //   data.push(propsObj);
      // }
    });
    return state.logs;
  }

  useEffect(() => {
    const getlogs = async () => {
      let new_logs = await getProjectLogs(projectId);
      setProjectLogs(new_logs);
      setTimeout(() => {
        // Scroll to bottom
        const e = document.getElementsByClassName('project-logs-board');
        e && e[0] ? (e[0].scrollTop = e[0].scrollHeight) : null;
      }, 50);
    };
    getlogs();
    // setInterval(async () => {
    //   let new_logs = await getProjectLogs(projectId);
    //   setProjectLogs(new_logs);
    //   setTimeout(() => {
    //     const e = document.getElementsByClassName('project-logs-board');
    //     e && e[0] ? (e[0].scrollTop = e[0].scrollHeight) : null;
    //   }, 50);
    // }, 30000);
  }, []);

  if (!projectLogs) return <Skeleton active={true} loading={true} />;

  return (
    <div style={logsStyle}>
      <Console logs={projectLogs} variant="light" styles={logsText} />
    </div>
  );
}
