import DashboardItem from 'components/ChartRenderer/DashboardItem';
import { Link } from 'react-router-dom';
import { iRegionModel } from 'shared/deployment';
import { ChartRenderer } from 'components/ChartRenderer/ChartRenderer';
import { Alert, Col, Tooltip, Typography } from 'antd';
import { clusterHeight } from 'utils/styles';

const { Text } = Typography;

export interface iClusterOpenCostPriceProps {
  region: iRegionModel;
}

export const ClusterOpenCostPrice = (props: iClusterOpenCostPriceProps) => {
  const region: iRegionModel = props.region;
  const stats_opencost = [
    {
      id: 0,
      lg: 24,
      span: `24`,
      name: 'Price for last 30 days',
      vizState: {
        query: {
          limit: 500,
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['hour'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['opencost'] },
            { member: 'CostHistory.regionId', operator: 'equals', values: [`${region.id}`] },
          ],
          dimensions: ['Deployments.id', 'Deployments.name'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', granularity: 'day', dateRange: 'Last 30 days' }],
          measures: ['CostHistory.sum'],
          order: { 'CostHistory.sum': 'desc' },
        },
        chartType: 'area',
      },
    },
  ];

  const alertData = () => {
    const alertMessage = (
      <Text>
        OpenCost is a tool for tracking and optimizing cloud costs. It will work only if you have OpenCost agent installed on your Cluster. You can
        check installation instructions on the
        <Text strong>
          <Tooltip color="#115EA3" title="Click here to view Integrations">
            <Link to={`/clusters/${region.id}/integrations/1`}> OpenCost Integration Page </Link>
          </Tooltip>
        </Text>
      </Text>
    );
    return <Alert showIcon type="info" message={alertMessage} />;
  };

  return (
    <>
      <Col span={24} key={'note'} style={clusterHeight} />
      {alertData()}
      {stats_opencost.map((stat, index) => {
        return (
          <Col span={stat.span || 24} key={stat.id || index} style={clusterHeight}>
            <DashboardItem title={stat.name}>
              <ChartRenderer vizState={stat.vizState} />
            </DashboardItem>
          </Col>
        );
      })}
    </>
  );
};
