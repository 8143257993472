import { iProject } from 'interface/project';
import gql from 'graphql-tag';
import { ReactElement, useState } from 'react';
import { projectService } from 'services/project.service';
import { useAuthedQuery } from 'utils/qlAuth';
import { createGitHubRepoForProject, getGitHubToken, isValidGithubApiToken } from 'services/githubApi';
import { Button, notification, Skeleton, Alert, Input, Space, Form, Typography, Popover, Dropdown, MenuProps, message, Menu, Tabs } from 'antd';
import { ArrowRightOutlined, GithubOutlined, KeyOutlined, QuestionCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { spaceWidth, divWidth, buttonWidth } from 'utils/styles';
import { ProjectGitBtn_Clone, ProjectGitBtn_Pull, ProjectGitBtn_push, projectGitPull } from 'components/Projects/Settings/Git/GitButtons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { iDeployment, iProjectModel } from 'shared/deployment';
import TabPane from 'antd/es/tabs/TabPane';
import { ClusterPermissions } from 'components/Projects/ClusterPermissions/ClusterPermissions';
import { ProjectFiles } from 'components/Projects/FileEditor/ProjectFiles';
import { ProjectCiCd } from 'components/Projects/ProjectCiCd/ProjectCiCd';
import { ProjectContainerStorage } from 'components/Projects/ProjectContainerStorage/ProjectContainerStorage';
import { ProjectPage } from 'components/Projects/ProjectPage';
import { ProvisionerConfiguration } from 'components/Projects/Provisioner/Provisioner';
import { DeleteProject } from 'components/Projects/Settings/DeleteProject';
import { GitLogs } from 'components/Projects/Settings/Git/gitLogs';
import { ProjectGitSettings } from 'components/Projects/Settings/Git/ProjectGitSettings';
import { ProjectDocs } from 'components/Projects/Settings/ProjectDocs';
import { ProjectEnvSettings } from 'components/Projects/Settings/ProjectEnvSettings';
import { ProjectGeneral } from 'components/Projects/Settings/ProjectGeneral';
import { ProjectServices } from 'components/Projects/Settings/ProjectServices';
import { ProjectVersions } from 'components/Projects/Settings/ProjectVersion';
import { ShareProject } from 'components/Projects/Settings/ShareProject';

interface iConfigurationSettings {
  deployment: iDeployment;
  settingsTab: string;
}

export const ConfigurationSettings = (props: iConfigurationSettings): ReactElement => {
  const history = useHistory();

  const project = props.deployment.ProjectModel;
  const deployment = props.deployment;
  let tabName = props.settingsTab;

  // tabName !== 'git' && tabName !== 'danger-zone' && !project.gitInitialized
  //   ? (() => {
  //       history.push(`/app/${deployment.id}/configuration/git`);
  //       notification.error({
  //         key: 'gitInitialized',
  //         message: `Git configuration is required`,
  //         description: `Please configure git settings for this project`,
  //       });
  //       return null;
  //     })()
  //   : null;

  if (!tabName) {
    history.push(`/app/${deployment.id}/configuration/general`);
    return null;
  }

  let baseMenuTab = `settings`;

  const handleTabClick = (key: any) => history.push(`/app/${deployment.id}/configuration/${baseMenuTab}/${key}`);

  const gitTabs = [
    // { key: 'provisioner', tab: 'Provisioner', component: <ProvisionerConfiguration project={project} /> },
    // { key: 'permissions', tab: 'Permissions', component: <ClusterPermissions project={project} /> },
    { key: 'general', tab: 'General', component: <ProjectGeneral project={project} />, disabled: !project.gitInitialized },
    { key: 'git', tab: 'GIT Settings', component: <ProjectGitSettings project={project} /> },
    { key: 'git-audit', tab: 'GIT Audit', component: <GitLogs project={project} />, disabled: !project.gitInitialized },
    { key: 'docs', tab: 'Docs', component: <ProjectDocs project={project} />, disabled: !project.gitInitialized },
    { key: 'share', tab: 'Share', component: <ShareProject project={project} />, disabled: !project.gitInitialized },

    { key: 'env', tab: 'Env', component: <ProjectEnvSettings project={project} />, disabled: !project.gitInitialized },
    { key: 'project-files', tab: 'Files', component: <ProjectFiles project={project} />, disabled: !project.gitInitialized },
    { key: 'pull-secrets', tab: 'Pull secrets', component: <ProjectContainerStorage project={project} />, disabled: !project.gitInitialized },
    { key: 'versions', tab: 'Versions', component: <ProjectVersions project={project} />, disabled: !project.gitInitialized },
    { key: 'logs', tab: 'Activity', component: <ProjectPage project={project} />, disabled: !project.gitInitialized },

    { key: 'danger-zone', tab: 'Danger Zone (to remove)', component: <DeleteProject project={project} /> },
  ];
  return (
    <Tabs defaultActiveKey={tabName || 'general'} onChange={handleTabClick}>
      {gitTabs.map(tab => (
        <TabPane key={tab.key} tab={tab.tab} disabled={tab.disabled}>
          {tab.component}
        </TabPane>
      ))}
    </Tabs>
  );
};
