import { iProject } from 'interface/project';
import gql from 'graphql-tag';
import { ReactElement, useState } from 'react';
import { projectService } from 'services/project.service';
import { useAuthedQuery } from 'utils/qlAuth';
import { createGitHubRepoForProject, getGitHubToken, isValidGithubApiToken } from 'services/githubApi';
import { Button, notification, Skeleton, Alert, Input, Space, Form, Typography, Popover, Dropdown, MenuProps, message } from 'antd';
import { ArrowRightOutlined, GithubOutlined, KeyOutlined, QuestionCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { spaceWidth, divWidth, buttonWidth } from 'utils/styles';
import { ProjectGitBtn_Clone, ProjectGitBtn_Pull, ProjectGitBtn_push, projectGitPull } from 'components/Projects/Settings/Git/GitButtons';
import { useHistory } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
interface iProjectGitSettings {
  deployment: iDeployment;
  showInDrawer?: boolean;
  onSave?: () => void;
}

const { Text, Title } = Typography;
const { Item } = Form;

export const GitConfiguration = (props: iProjectGitSettings): ReactElement => {
  const projectId = props.deployment?.projectId;
  const [extendForm, setExtendForm] = useState<Boolean>(null);
  const history = useHistory();
  const [reLink, setReLink] = useState(false);

  const { loading, error, data } = useAuthedQuery(
    gql`
      query ProjectGitController_checkGitLock($projectId: Int!) {
        ProjectGitController_checkGitLock(projectId: $projectId)
        ProjectController_getProject(projectId: $projectId) {
          gitInitialized
        }
        ProjectGitController_getGitConfiguration(projectId: $projectId) {
          gitToken
          gitUrl
          gitIntegrationType
          gitProjectPath
          gitBranch
          gitAuthType
        }
      }
    `,
    { variables: { projectId: projectId } },
  );

  if (loading) {
    return <Skeleton active={true} loading={true} />;
  }

  if (error) {
    return <Alert type="warning" showIcon key={'error'} message="Error" description={JSON.stringify(error)} />;
  }

  const git = data?.ProjectGitController_getGitConfiguration;
  const repoIsLinked = !reLink && git?.gitUrl && git?.gitBranch;

  let gitOAuthLink = `/api/github/oauth/repo-authorize?params=${encodeURIComponent(`gitAutoCreate-${props.deployment.id}`)}`;
  if (window.location.hostname === 'localhost') {
    gitOAuthLink = `http://localhost:4001${gitOAuthLink}`;
  }

  const items: MenuProps['items'] = [
    {
      label: `Use existed repository`,
      key: '1',
      icon: <UserOutlined />,
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = e => {
    setExtendForm(true);
    e.domEvent.stopPropagation();
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const logo = (
    <>
      &nbsp; <ArrowRightOutlined /> &nbsp;
    </>
  );

  const handleSubmit = async (e: { gitProjectPath: string; gitAuthType: string; gitUrl: string; gitBranch: any; gitToken: string }) => {
    const formData = e;

    if (formData.gitProjectPath === undefined) {
      formData.gitProjectPath = '';
    }

    formData.gitUrl = String(formData.gitUrl).replace('http://', '').replace('https://', '');

    const res = await projectService.saveProjectGitConfig(projectId, formData);

    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    } else {
      if (props.onSave) {
        props.onSave();
      }
      notification.success({ message: `Ready` });
    }

    await projectGitPull(projectId, (isOk: boolean) => {
      if (isOk) {
        history.push(`/app/${props.deployment?.id}/configuration/services`);
      } else {
        notification.error({ message: `Error pulling changes from git` });
      }
    });
  };

  let AddExtendForm = null;
  if (extendForm === true) {
    AddExtendForm = (
      <Form onFinish={handleSubmit} initialValues={git}>
        <Item key={`gitUrl`} name={`gitUrl`} label={<Text strong> Git repository URL </Text>} rules={[{ required: true }]}>
          <Input
            placeholder={`Enter URL (without 'https://') Here`}
            style={divWidth}
            type={`text`}
            addonAfter={
              <Popover
                placement="topLeft"
                title={'Your git repository url should be in the format `gitlab.com/username/repo` or `github.com/username/project.git`'}
              >
                <QuestionCircleTwoTone twoToneColor="#00A58E" />
              </Popover>
            }
          />
        </Item>
        <Item key={`gitToken`} name={`gitToken`} label={<Text strong> Access Token </Text>} rules={[{ required: true }]}>
          <Input
            placeholder={`Enter Access Token Here`}
            style={divWidth}
            type={`password`}
            addonAfter={
              <Popover
                placement="topLeft"
                title={
                  <Space direction="vertical">
                    <Title level={5}> Steps to get Access token </Title>
                    <Text>
                      Github {logo} Setting {logo} Developer Setting {logo} Personal Access Token {logo} Token (Classic) {logo} Generate New Token.
                    </Text>
                    <Text> Copy this access token and paste it in Git. </Text>
                    <Text> Provide Git repository link without http:// </Text>
                  </Space>
                }
              >
                <QuestionCircleTwoTone twoToneColor="#00A58E" />
              </Popover>
            }
          />
        </Item>
        <Item key={`gitBranch`} name={`gitBranch`} label={<Text strong> Branch </Text>} rules={[{ required: true }]}>
          <Input
            placeholder={`main`}
            style={divWidth}
            type={`text`}
            addonAfter={
              <Popover placement="topLeft" title={'Branch name should be the name of the branch you want to use for this project'}>
                <QuestionCircleTwoTone twoToneColor="#00A58E" />
              </Popover>
            }
          />
        </Item>
        <Button type="primary" htmlType="submit" style={buttonWidth}>
          Save {`&`} check access
        </Button>
      </Form>
    );
  }

  let linkedUI = null;
  if (repoIsLinked) {
    linkedUI = (
      <p>
        Linked to{' '}
        <a href={`http://` + git?.gitUrl} target="_blank">
          branch {git?.gitBranch}
        </a>
      </p>
    );
  }

  const isValidGithubToken = isValidGithubApiToken();
  if (!isValidGithubToken) {
    return (
      <>
        <a href={gitOAuthLink}>
          <Dropdown.Button type={repoIsLinked ? 'default' : 'primary'} data-cy="git-repo-auth" menu={menuProps}>
            Create a GitHub repo <GithubOutlined />
          </Dropdown.Button>
        </a>
        <br />
        {linkedUI}
        {AddExtendForm}
      </>
    );
  }

  return (
    <>
      <Dropdown.Button
        type="primary"
        data-cy="git-repo-auth"
        menu={menuProps}
        onClick={async () => {
          const repo = await createGitHubRepoForProject(props.deployment.ProjectModel);

          const res = await projectService.saveProjectGitConfig(projectId, {
            gitUrl: repo.clone_url,
            gitBranch: repo.default_branch,
            gitAuthType: 'github-oauth',
            gitToken: getGitHubToken(),
            gitProjectPath: '',
          });
          await projectGitPull(projectId, (isOk: boolean) => {
            if (isOk) {
              history.push(`/app/${props.deployment?.id}/configuration/services`);
            } else {
              notification.error({ message: `Error pulling changes from git` });
            }
          });
          if (res.error) {
            notification.error({ message: `Error - ${res.error}` });
          } else {
            if (props.onSave) {
              props.onSave();
            }
            notification.success({ message: `Ready` });
          }
        }}
      >
        Create a GitHub repo <GithubOutlined />
      </Dropdown.Button>
      {linkedUI}
      {AddExtendForm}
    </>
  );
};
