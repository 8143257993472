import gql from 'graphql-tag';
import { useState } from 'react';
import { DeleteDeployment } from './DeleteDeployment';
import { useAuthedQuery } from '../../../../utils/qlAuth';
import { LogoAvatar } from '../../../SharedComponents/LogoAvatar/LogoAvatar';
import { iDeployment, iRegionModel } from 'shared/deployment';
import { deploymentService, useGqlDeploymentById } from '../../../../services/deployment.service';
import { Alert, Button, Input, notification, Select, Skeleton, Space, Switch, Tag, Tooltip, Typography } from 'antd';
import { bannerStyle, buttonSize, buttonWidth, dropdownStyle, floatingRight, redColor, spaceWidth } from 'utils/styles';
import { NewClusterCreation } from 'components/Clusters/ClustersList';
import { QuestionCircleTwoTone } from '@ant-design/icons';

const { Text } = Typography;
const { Option } = Select;

export const DeleteTab = (props: { id: number }) => {
  const dpQuery = useGqlDeploymentById(props.id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};

  const [projectDeployment, setProjectDeployment] = useState(deployment?.projectId);
  const [regionDeployment, setRegionDeployment] = useState(deployment?.region);
  const { data } = useAuthedQuery(
    gql`
      query ProjectController_getProjectList($projectId: Int!) {
        ProjectController_getProjectList {
          id
          name
          title
          description
          logo
        }
        RegionsController_regionsForProject(projectId: $projectId) {
          regions {
            id
            tenant
            name
            title
            kubernetesVersion
            cloudProvider
            isPublic
          }
          disabledRegions
        }
      }
    `,
    { skip: !Number(deployment?.projectId), variables: { projectId: Number(deployment?.projectId) } },
  );

  const [inputValue, setInputValue] = useState<string>('');

  if (dpQuery.loading) return <Skeleton active={true} loading={true} />;

  const regions = data?.RegionsController_regionsForProject?.regions || [];
  const disabledRegions = data?.RegionsController_regionsForProject?.disabledRegions || [];

  const deploymentPause = async () => {
    await deploymentService.updateDeployment(deployment.id, { isEnabled: !deployment.isEnabled, timeStart: Math.round(Date.now() / 1000) });
    dpQuery.refetch();
    notification.success({ description: `Saved`, message: '' });
  };

  const autoOffFunctions = async () => {
    await deploymentService.updateDeployment(deployment.id, { autoOff: !deployment.autoOff });
    dpQuery.refetch();
    notification.success({ description: `Saved`, message: '' });
  };

  const changeProject = project => setProjectDeployment(project);

  const saveDeploymentProject = async () => {
    await deploymentService.updateDeployment(deployment.id, { projectId: projectDeployment });
    dpQuery.refetch();
    notification.success({ description: `Saved`, message: '' });
  };

  const saveDeploymentRegion = async () => {
    await deploymentService.updateDeployment(deployment.id, { region: regionDeployment });
    dpQuery.refetch();
    notification.success({ description: `Saved`, message: '' });
  };

  const pauseApplications = () => {
    const pauseSwitch = (
      <Space direction="horizontal">
        <Tooltip color="#115EA3" placement="left" title="Turn off Deployment in 48 hours after launch. This action can loss Deployment data">
          <Switch disabled={dpQuery.loading} checked={!!deployment.autoOff} onChange={autoOffFunctions} />
        </Tooltip>
        <Text> Pause Deployment 48 hours launch </Text>
      </Space>
    );

    const alertMessage = <Text strong> Pause Application </Text>;

    const alertDescription = (
      <Space direction="vertical">
        <Text> Pausing your service will temporarily suspend it and stop all running instances. </Text>
        <Text> If Application is paused, You can reactivate it at any time by clicking "Resume." </Text>
        {pauseSwitch}
      </Space>
    );

    const alertAction = (
      <Tooltip color="#115EA3" placement="left" title="It will remove Deployment from Cluster. This action can loss Deployment data">
        <Button loading={dpQuery.loading} onClick={deploymentPause} style={buttonSize}>
          {deployment.isEnabled ? 'Pause Service' : 'Resume Service'}
        </Button>
      </Tooltip>
    );

    return <Alert message={alertMessage} description={alertDescription} action={alertAction} style={bannerStyle} />;
  };

  const deleteApplications = () => {
    const handleInputChange = e => {
      setInputValue(e.target.value);
      e.preventDefault();
    };

    const alertMessage = <Text strong> Delete Application </Text>;

    const alertDescription = (
      <Space direction="vertical">
        <Text> Deleting this Application is permanent and cannot be undone. All associated data will be lost. </Text>
        <Text>
          <Text style={redColor}> This cannot be undone. </Text> Type <Text strong> Uptime Kuma </Text> to confirm.
        </Text>
        <Input placeholder="Enter confirmation text here..." value={inputValue} onChange={handleInputChange} style={dropdownStyle} />
      </Space>
    );

    const enabledButton = <DeleteDeployment id={deployment.id} confirmDelete={inputValue === 'Uptime Kuma' ? false : true} />;

    return <Alert message={alertMessage} description={alertDescription} action={enabledButton} style={bannerStyle} />;
  };

  const changeApplications = () => {
    if (!data?.ProjectController_getProjectList) return null;

    const changeApplicationsAction = (
      <Space>
        <Select style={dropdownStyle} onChange={changeProject} defaultValue={deployment?.projectId}>
          {data.ProjectController_getProjectList.map(elem => (
            <Option value={elem.id} key={elem.id}>
              <Space>
                {elem.logo && <LogoAvatar logo={elem.logo} name={elem.title} />}
                <Tooltip color="#115EA3" placement="right" title={elem.description}>
                  {elem.title}
                </Tooltip>
                {elem.tags?.map(tag => (
                  <Tag color="green" key={tag}>
                    {tag}
                  </Tag>
                ))}
              </Space>
            </Option>
          ))}
        </Select>
      </Space>
    );

    const alertMessage = <Text strong> Change Template </Text>;

    const alertDescription = (
      <Space direction="vertical">
        <Text> Changing a template can modify the application </Text>
        {changeApplicationsAction}
      </Space>
    );

    const alertAction = (
      <Tooltip color="#115EA3" placement="left" title="If you wish to change the template click here">
        <Button style={buttonWidth} onClick={saveDeploymentProject} disabled={!projectDeployment || projectDeployment === deployment?.projectId}>
          Apply Changes
        </Button>
      </Tooltip>
    );

    return <Alert message={alertMessage} description={alertDescription} action={alertAction} style={bannerStyle} />;
  };

  const moveApplications = () => {
    const moveCluster = (
      <Space direction="horizontal">
        <Space>
          <Select defaultValue={deployment.region} style={dropdownStyle} onChange={setRegionDeployment} placeholder="Choose Cluster Here">
            <Option value={null} key={0}>
              No Cluster is selected
            </Option>
            {[...regions]
              .sort((a, b) => (b.id - a.id !== 0 ? b.id - a.id : a.isPublic - b.isPublic))
              .map((v: iRegionModel) => (
                <Option value={v.id} key={v.id} disabled={disabledRegions.includes(v.id)}>
                  <Tag color={v.isPublic ? 'grey' : 'green'} title="Kubernetes version">
                    {v.isPublic ? 'Shared' : 'Private'}
                  </Tag>
                  <Tag color={v.kubernetesVersion ? 'green' : 'red'} title="Kubernetes version" style={{ ...floatingRight, top: 5 }}>
                    Kubernetes {v.kubernetesVersion || 'N/A'}
                  </Tag>
                  {v.title} {!disabledRegions.includes(v.id) ? '' : 'Not available, in this Cluster already exists this Deployment type'}
                </Option>
              ))}
          </Select>
        </Space>
        <Text />
        <Text />
        <NewClusterCreation />
      </Space>
    );

    const alertMessage = <Text strong> Move Application </Text>;

    const alertDescription = (
      <Space direction="vertical">
        <Text>
          Moving your deployment to a different cluster will result in temporary downtime. Please be aware that this action may lead to data loss.
        </Text>
        <Space direction="horizontal">
          <Text> Set Cluster </Text>
          <Tooltip color="#115EA3" placement="right" title="Application is created using the cluster, changing the cluster may lead to data loss">
            <QuestionCircleTwoTone twoToneColor="#00A58E" />
          </Tooltip>
        </Space>
        {moveCluster}
      </Space>
    );

    const alertAction = (
      <Button onClick={saveDeploymentRegion} style={buttonWidth} disabled={!regionDeployment || regionDeployment === deployment?.region}>
        Apply Changes
      </Button>
    );

    return regions && <Alert message={alertMessage} description={alertDescription} action={alertAction} style={bannerStyle} />;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {pauseApplications()}
      {moveApplications()}
      {deleteApplications()}
      {changeApplications()}
    </Space>
  );
};
