import gql from 'graphql-tag';
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../../services/deployment.service';
import { updateDeployment, layout } from '../common';
import { iDeployment } from 'shared/deployment';
import { useAuthedQuery } from 'utils/qlAuth';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Form, Button, Input, Skeleton, Select, Tag, Typography, Alert } from 'antd';
import { buttonWidth } from 'utils/styles';
import { LinkOwnDomain } from '../help/LinkOwnDomain';

const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;

export default function DeploymentGeneralSettingsForm({ deploymentId, hasRoutes }: any) {
  const history = useHistory();
  const [domain, setDomain] = useState(null);
  const dpQuery = useGqlDeploymentById(deploymentId);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;

  const qTagsList = useAuthedQuery(
    gql`
      query TagsController_tagsList {
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    {},
  );
  const tags = qTagsList?.data?.TagsController_tagsList || [];

  useEffect(() => {
    deployment ? setDomain(deployment.domain) : null;
  }, [domain, deployment]);

  if (error || loading) return <Skeleton active={true} loading={true} />;
  if (error) history.push('/');

  const freezeOptions = [
    { value: 1, label: 'Freezed (Any changes will not apply to Deployment)' },
    { value: 0, label: 'Ready for Updates' },
  ];

  const alertAction = (
    <Link to={`/project/${deployment.projectId}/settings/services`}>
      <Button type="primary" style={buttonWidth}>
        Add Routes
      </Button>
    </Link>
  );

  const alertMessage = (
    <Text>
      You can add more Routes in the Project service settings on
      <Text strong> Routes </Text>
      tab.
    </Text>
  );

  return (
    <>
      <Form onFinish={values => updateDeployment(deployment.id, values)} {...layout}>
        <Item
          name="name"
          label="Name"
          initialValue={deployment.name}
          rules={[{ required: true, message: 'Please input your Deployment Name Here!' }]}
        >
          <Input placeholder="Enter Deployment Name Here" />
        </Item>

        <Item hidden={!hasRoutes} name="domain" label="URL" initialValue={deployment.domain}>
          <Input
            placeholder="Enter deployment domain"
            onChange={e => setDomain(e.target.value)}
            addonAfter={<LinkOwnDomain region={deployment.RegionModel} />}
          />
        </Item>

        {tags?.length !== 0 ? (
          <Item {...layout} name="tags" label={<Text strong> Tags </Text>} initialValue={deployment.tags}>
            <Select mode="multiple" placeholder="Select Tags Here">
              {tags.map(
                (elem: {
                  id: Key;
                  color: string | (string & {});
                  name: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
                }) => (
                  <Option value={elem.id} key={elem.id}>
                    <Tag color={elem.color}> {elem.name} </Tag>
                  </Option>
                ),
              )}
            </Select>
          </Item>
        ) : null}

        <Item {...layout} name="freezed" label={<Text strong> Deployment Freeze </Text>} initialValue={deployment.freezed ? 1 : 0}>
          <Select>
            {freezeOptions.map(option => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Item>

        <Item>
          <BottomButtons>
            <Button type="primary" htmlType="submit" style={buttonWidth}>
              Save
            </Button>
          </BottomButtons>
        </Item>
      </Form>

      <Alert showIcon type="info" message={alertMessage} action={alertAction} />
    </>
  );
}
