import React, { useEffect, useRef } from 'react';
import Sider from 'antd/es/layout/Sider';
import NhHeader from 'components/SharedComponents/Header/Header';
import NhBreadcrumb from 'components/SharedComponents/Header/Breadcrumb';
import { hasSideNavBarLevel2, SideNavBarLevel1, SideNavBarLevel2 } from '../components/SharedComponents/SideNav/SideNavBar';
import { goToLogin, goToLoginLink, useCubeJsApi, useUser } from 'utils/common';
import { CubeProvider } from '@cubejs-client/react';
import { authService } from 'services/auth.service';
import { LoginOutlined } from '@ant-design/icons';
import { publicRoutes } from 'routes/RouteConfig';
import { Content } from 'antd/es/layout/layout';
import { Provider } from 'react-redux';
import { store } from 'services/store';
import { TitleUI } from './TitleUI';
import { WidgetArea } from './WidgetArea';
import { BrandLogoMain, excludedPaths } from 'components/SharedComponents/AuthComp/AuthComp';
import { Button, Card, Col, FloatButton, Layout, Modal, Result, Row, Skeleton, Space, Tooltip } from 'antd';
import { commonPadding, buttonWidth, headerStyle, floatingRight, layoutStyle, containerStyle, divStyle, contentStyle } from 'utils/styles';
import { spaceWidth } from 'utils/styles';
import { useLocation } from 'react-router-dom';

const { BackTop } = FloatButton;

const AdminLayout = ({ children }: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [cubeApi, error, loading] = useCubeJsApi();
  const [isAuth, setAuth] = React.useState(null);
  const [collapsed, setCollapsed] = React.useState(false);
  const user = useUser();
  const showSideNavBarLevel2 = hasSideNavBarLevel2(user);
  const publicUrls = publicRoutes.map(route => '#' + route.path);
  const { pathname } = useLocation();
  const location = window.location.hash;

  useEffect(() => {
    const iId = setInterval(() => {
      authService.checkAuth().then(res => {
        res ? setAuth(true) : !publicUrls.includes(location) && location.indexOf('#/login/email/') === -1 ? setAuth(false) : null;
      });
    }, 15000);
    return () => clearInterval(iId);
  }, []);

  if (loading) {
    return (
      <div style={commonPadding}>
        <Skeleton active={true} loading={true} />
      </div>
    );
  }

  if (error) {
    goToLogin();
    return (
      <div style={commonPadding}>
        <Skeleton active={false} loading={true} />
      </div>
    );
  }

  const handleOk = () => goToLogin();

  const modalExtra = (
    <a href={`/#${goToLoginLink()}`} onClick={handleOk}>
      <Button type="primary" style={buttonWidth}>
        Login
      </Button>
    </a>
  );

  const authTimeout = () => (
    <Modal title="" footer="" key="modal" open={isAuth === false} onOk={handleOk} onCancel={() => setAuth(true)}>
      <Result icon={<LoginOutlined />} title="Your session has ended." subTitle="Please login again." extra={modalExtra} />
    </Modal>
  );

  const headerBar = () => (
    <Card size="small" bordered={false} style={headerStyle}>
      <Space size="small" direction="horizontal">
        <BrandLogoMain />
      </Space>
      <Space size="small" style={floatingRight}>
        <NhHeader />
      </Space>
    </Card>
  );

  const topIcon = () => (
    <Tooltip color="#115EA3" placement="leftTop" title="Back to Top">
      <BackTop type="primary" target={() => containerRef.current} />
    </Tooltip>
  );

  const pageDetails = () => {
    const infoData = () => (
      <Row>
        <Col span={12}>
          <Space direction="vertical">
            <NhBreadcrumb />
            <TitleUI />
          </Space>
        </Col>
        <Col span={12}>
          <WidgetArea />
        </Col>
      </Row>
    );
    return !excludedPaths.includes(pathname) ? infoData() : null;
  };

  const layoutData = () => (
    <Layout hasSider={showSideNavBarLevel2} style={layoutStyle}>
      {showSideNavBarLevel2 && (
        <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
          <SideNavBarLevel2 />
        </Sider>
      )}
      <Layout>
        <div ref={containerRef} style={containerStyle}>
          <div style={divStyle}>
            <Content style={contentStyle}>
              <Space direction="vertical" style={spaceWidth}>
                {pageDetails()}
                {children}
              </Space>
            </Content>
          </div>
        </div>
        {topIcon()}
      </Layout>
    </Layout>
  );

  const adminContent = () => (
    <Provider store={store}>
      <CubeProvider cubejsApi={cubeApi}>
        {authTimeout()}
        {headerBar()}
        <SideNavBarLevel1 />
        {layoutData()}
      </CubeProvider>
    </Provider>
  );

  return adminContent();
};

export default AdminLayout;
