import { useEffect, useState } from 'react';
import { deploymentService } from '../../../../services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { Button, Input, notification, Select, message, Popover, Alert, Typography, Flex, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { buttonWidth, spaceWidth, storageStyle1, storageStyle2, storageStyle3, textCenter } from 'utils/styles';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';

const { Title, Text } = Typography;
const { Option } = Select;

interface iStorageProps {
  deployment: iDeployment;
}

export const Storage = (props: iStorageProps) => {
  const deployments = props.deployment;
  const [select, setSelect] = useState('disk');
  const [env, setEnv] = useState('');

  const tooltipIcon = () => (
    <Text type="secondary">
      <InfoCircleOutlined />
    </Text>
  );

  const tooltipContent_BUCKET_NAME = (
    <Tooltip
      color="#115EA3"
      placement="right"
      title="Can be empty. If it is empty then will be using one common bucket for all deployments. If you wish you can create manualy new S3 bucket and use it."
    >
      {tooltipIcon()}
    </Tooltip>
  );

  const tooltipContent_ACCESS_KEY_ID = (
    <Tooltip color="#115EA3" placement="right" title="Can be empty. It should be AWS API key with write access to S3 bucket `STORAGE_S3_BUCKET_NAME`">
      {tooltipIcon()}
    </Tooltip>
  );

  const tooltipContent_SECRET_ACCESS_KEY = (
    <Tooltip
      color="#115EA3"
      placement="right"
      title="Can be empty. It should be AWS API secret key with write access to S3 bucket `STORAGE_S3_BUCKET_NAME`"
    >
      {tooltipIcon()}
    </Tooltip>
  );

  const envName = [
    { name: 'Storage S3 bucket name', addonAfter: tooltipContent_BUCKET_NAME },
    { name: 'Storage S3 access key ID', addonAfter: tooltipContent_ACCESS_KEY_ID },
    { name: 'Storage S3 secret access key', addonAfter: tooltipContent_SECRET_ACCESS_KEY },
  ];

  const nameEnvStorage = 'STORAGE_TYPE';

  const handleChange = value => {
    setSelect(value);
    setEnv(
      // @ts-ignore
      env.filter((e: { name: string }) => e.name === nameEnvStorage)[0]?.name
        ? // @ts-ignore
          env.map((e: { name: string }) => (e.name === nameEnvStorage ? { name: nameEnvStorage, value } : e))
        : [...env, { name: nameEnvStorage, value }],
    );
  };

  useEffect(() => {
    const env = deployments ? Object.keys(deployments.env).map(k => ({ name: k, value: deployments.env[k] })) : '';
    // @ts-ignore
    setEnv(env);
    const storageType = env ? env.filter(e => e.name === nameEnvStorage)[0]?.value : undefined;
    setSelect(storageType || 'disk');
    // @ts-ignore
    !storageType ? setEnv([...env, { name: nameEnvStorage, value: 'disk' }]) : null;
  }, [deployments]);

  const onFinish = async values => {
    const sendEnv = {};
    let error = false;

    values.forEach((element: { name: string; value: any }) => {
      !element || (!element.name && !element.value)
        ? (error = true)
        : !element.name
        ? (message.error(`The value of the ${element.value} variable has no name`), (error = true))
        : !/^[A-Za-z0-9_]+$/.test(element.name)
        ? (message.error(`Variable name is invalid: ${element.name}`), (error = true))
        : (sendEnv[element.name] = element.value);
    });

    if (error) return;

    const res = await deploymentService.updateDeployment(props.deployment.id, { env: sendEnv });
    deployments.env = res.data.env;
    if (res.status === 401) return window.location.reload();

    notification.success({ description: `Saved`, message: '' });
  };

  const deployementFreezed_A = () => (
    <Title level={5} style={textCenter}>
      This deployment is frozen, to edit the parameters, change the value of General {'->'} frozen to "false"
    </Title>
  );

  const alertMessage = (
    <Text>
      You can know&nbsp;
      <Tooltip color="#115EA3" title="Click here to know how to create new S3 bucket">
        <Text strong>
          <a href="https://gitlab.nanoheal.com/sourcecode/filestorageservice/-/blob/main/docs/s3.md" target="_blank">
            How to create new S3 bucket
          </a>
        </Text>
      </Tooltip>
      &nbsp;and&nbsp;
      <Tooltip color="#115EA3" title="Click here to look at Docs for file storage service">
        <Text strong>
          <a href="https://gitlab.nanoheal.com/sourcecode/filestorageservice/-/blob/main/docs/s3.md" target="_blank">
            Docs for file storage service
          </a>
        </Text>
      </Tooltip>
      .
    </Text>
  );

  const s3Select = () => (
    <div style={{ margin: '50px 0' }}>
      {envName.map(el => (
        <S3settings addonAfter={el.addonAfter} key={el.name} name={el.name} env={env} setEnv={setEnv} />
      ))}
    </div>
  );

  const deployementFreezed_B = () => (
    <BottomButtons>
      <Button type="primary" onClick={() => onFinish(env)} style={buttonWidth}>
        Save
      </Button>
    </BottomButtons>
  );

  const storageOptions = [
    { value: 'disk', label: 'Disk' },
    { value: 's3', label: 'S3' },
    { value: 's3_efs', label: 'S3 and EFS' },
  ];

  return (
    <Space direction="vertical" style={spaceWidth}>
      {deployments.freezed ? deployementFreezed_A() : ''}
      <Alert showIcon type="info" message={alertMessage} closable={false} />
      <div style={{ minHeight: '60vh' }}>
        <Text strong> Storage type: &nbsp; </Text>
        <Select disabled={deployments.freezed ? true : false} value={select} defaultValue={select} onChange={handleChange} style={buttonWidth}>
          {storageOptions.map(option => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {select === 's3' || select === 's3_efs' ? s3Select() : ''}
      </div>
      {deployments.freezed ? '' : deployementFreezed_B()}
    </Space>
  );
};

const S3settings = ({ name, env, setEnv, addonAfter }: any) => {
  const [state, setstate] = useState('');

  useEffect(() => {
    setstate(env.filter((e: { name: string }) => e.name === name)[0]?.value);
  }, []);

  useEffect(() => {
    env.filter((e: { name: string }) => e.name === name)[0]?.name
      ? setEnv(env.map((e: { name: string }) => (e.name === name ? { name, value: state } : e)))
      : setEnv([...env, { name, value: state }]);
  }, [state]);

  return (
    <div style={storageStyle1}>
      <div style={storageStyle2}> {name} </div>
      <Input value={state} addonAfter={addonAfter} onChange={e => setstate(e.currentTarget.value)} style={storageStyle3} />
    </div>
  );
};
