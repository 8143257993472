import { Link, useParams } from 'react-router-dom';
import { Button, Typography, Space, Row } from 'antd';
import { formInput, formButton } from 'utils/styles';
import { AuthImageC, BrandLogo, AuthenticationForm, SubText, SubTextHeader, SubTextLogin } from 'components/SharedComponents/AuthComp/AuthComp';

const { Title, Text } = Typography;

export default function ErrorPage() {
  // @ts-ignore
  const { message } = useParams();

  return (
    <>
      <AuthImageC />
      <Row>
        <BrandLogo />
      </Row>
      <AuthenticationForm>
        <Title level={2}> Error </Title>
        <Space direction="vertical" style={formInput}>
          <Text strong> {message} </Text>
          <Space direction="horizontal">
            <Link to="/">
              <Button style={formButton} type="primary" data-cy="main-page-link">
                Go to Main page
              </Button>
            </Link>
          </Space>
        </Space>
      </AuthenticationForm>
      <SubText>
        <SubTextHeader /> <SubTextLogin />
      </SubText>
    </>
  );
}
