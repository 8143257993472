import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Skeleton, Flex, Typography } from 'antd';
import { DeleteDeployment } from './General/DeleteDeployment';
import { ProjectClonePage } from './new-deployment/ProjectClonePage';
import { SelectDeploymentTargetPage } from './new-deployment/SelectDeploymentTargetPage';
import { DeployPage } from './new-deployment/DeployPage';
import { DeploymentCustomisePage } from './new-deployment/DeploymentCustomisePage';
import { iDeployment } from 'shared/deployment';
import { useGqlDeploymentById } from 'services/deployment.service';
import { TitleUIRow } from 'layout/TitleUI';

const { Text } = Typography;

export default function SettingDetails() {
  const history = useHistory();
  // @ts-ignore
  let { id, tabName } = useParams();
  const tabs = ['basic', 'details', 'customise', 'deploy'];
  // tabName = basic | details | customise | deploy

  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const project = deployment.ProjectModel;
  let currentStep = 1;

  if (dpQuery.error || dpQuery.loading) return <Skeleton active={true} loading={true} />;
  if (dpQuery.error) history.push('/');
  if (project.gitInitialized) currentStep = 2;
  if (project.gitInitialized && deployment.region) currentStep = 3;

  return (
    <Col style={{ borderRadius: '3px', minHeight: '90vh' }}>
      <TitleUIRow title={`New Deployment Setup: ${deployment?.name} ${deployment?.ProjectModel?.title}`} />
      <Row>
        <Col className="gutter-row" span={12}>
          {project.description && <Text> Description: {project.description} </Text>}
        </Col>
        <Col className="gutter-row" span={12}>
          <Flex justify={'flex-end'}>
            <DeleteDeployment id={id} />
          </Flex>
        </Col>
      </Row>
      <Text />
      <Row>
        <Col span={24}>
          {!project.gitInitialized ? (
            <ProjectClonePage
              projectId={deployment.projectId}
              onClone={async () => {
                await dpQuery.refetch();
              }}
            />
          ) : (
            <>
              {tabName === 'details' && <SelectDeploymentTargetPage deployment={deployment} />}
              {tabName === 'customise' && <DeploymentCustomisePage deployment={deployment} />}
              {tabName === 'deploy' && <DeployPage deploymentId={deployment.id} />}
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
}
