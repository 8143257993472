import { CommonFormUI, CommonUI_DeploymentVarWidget, CommonUI_text } from './CommonFormUI';
import { Space } from 'antd';
import { spaceWidth } from 'utils/styles';

export interface iScaledObjectMainOptionsUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action?: string) => void;
}

export const form_schema_Labels = { schema: { type: 'object', additionalProperties: { type: 'string' } }, ui: {} };

export const ScaledObjectMainOptionsUI = (props: iScaledObjectMainOptionsUIProps) => {
  let volumeHelpUI = null;

  const formData = () => {
    const nameChildren = <CommonUI_text value={props.yamlObj?.metadata?.name} onChange={props.onChange} path=".metadata" propertyName="name" />;

    const scaleTargetRefKindChildren = (
      <CommonUI_text value={props.yamlObj?.spec?.scaleTargetRef?.kind} onChange={props.onChange} path=".spec.scaleTargetRef" propertyName="kind" />
    );

    const createDeploymentVarWidget = (path: string, propertyName: string, defaultValue?: any) => (
      <CommonUI_DeploymentVarWidget
        dstType="number"
        value={props.yamlObj?.spec?.fallback?.[propertyName] ?? defaultValue}
        onChange={props.onChange}
        path={path}
        propertyName={propertyName}
        validator={(val: number) => (val < 1 ? 'Must be more than 0' : null)}
      />
    );

    const minReplicaCountChildren = createDeploymentVarWidget('.spec', 'minReplicaCount');
    const targetCPUusageChildren = createDeploymentVarWidget('.spec.triggers[0].metadata', 'value');
    const maxReplicaCountChildren = createDeploymentVarWidget('.spec', 'maxReplicaCount');
    const fallbackReplicasCountChildren = createDeploymentVarWidget('.spec.fallback', 'replicas');
    const failureThresholdChildren = createDeploymentVarWidget('.spec.fallback', 'failureThreshold');

    volumeHelpUI = null;

    const formContent = [
      { name: 'Name', children: nameChildren },
      { name: 'scaleTargetRef kind', children: scaleTargetRefKindChildren },
      { name: 'Min replica count', children: minReplicaCountChildren },
      { name: 'Target CPU usage', children: targetCPUusageChildren },
      { name: 'Max replica count', children: maxReplicaCountChildren },
      { name: 'Fallback replicas count', children: fallbackReplicasCountChildren },
      { name: 'Failure threshold', children: failureThresholdChildren },
    ];
    return <CommonFormUI form={formContent} />;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {formData()}
      {volumeHelpUI}
    </Space>
  );
};
