import { useContext } from 'react';
import { CommonFormUI, CommonUI_DeploymentVarWidget, CommonUI_JsonFormModalUI, CommonUI_text } from './CommonFormUI';
import { ProjectDeploymentSyncVarsContext } from '../ProjectDeploymentContext';
import { Alert, Space } from 'antd';
import { spaceWidth } from 'utils/styles';

export interface iDeploymentMainOptionsUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action?: string) => void;
}

export const form_schema_Labels = { schema: { type: 'object', additionalProperties: { type: 'string' } }, ui: {} };

export const DeploymentMainOptionsUI = (props: iDeploymentMainOptionsUIProps) => {
  let volumeHelpUI = null;
  const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);

  const childrenName = () => (
    <CommonUI_text value={props.yamlObj?.metadata?.name} onChange={props.onChange} path={`.metadata`} propertyName={`name`} />
  );

  const childrenReplicas = () => (
    <CommonUI_DeploymentVarWidget
      dstType={'number'}
      value={props.yamlObj?.spec?.replicas}
      onChange={props.onChange}
      path={`.spec`}
      propertyName={`replicas`}
      validator={(val: number) => (val < 0 ? 'Must be more than 0' : null)}
    />
  );

  const childrenMatchLabels = () => (
    <CommonUI_JsonFormModalUI
      formData={props.yamlObj?.spec?.selector?.matchLabels || {}}
      formConfigs={form_schema_Labels}
      SyncVarsContext={syncVarsContext}
      title={`Match labels`}
      onChange={v => props.onChange(`.spec.selector.matchLabels`, v)}
    />
  );

  /** @todo - get the difference between `.spec.template.metadata.labels` and `.metadata.labels` */
  const childrenMetadataLabels = () => (
    <CommonUI_JsonFormModalUI
      formData={props.yamlObj?.spec?.template?.metadata?.labels || {}}
      formConfigs={form_schema_Labels}
      SyncVarsContext={syncVarsContext}
      title={`Metadata labels`}
      onChange={v => [`.spec.template.metadata.labels`, `.metadata.labels`].forEach(path => props.onChange(path, { ...v }))}
    />
  );

  const form = [
    { name: 'Name', children: childrenName() },
    { name: 'Replicas', children: childrenReplicas() },
    { name: 'Match labels', children: childrenMatchLabels() },
    { name: 'Metadata labels', children: childrenMetadataLabels() },
  ];

  volumeHelpUI = () => {
    const alertDescription = 'A Deployment provides declarative updates for Pods and ReplicaSets';
    const alertAction = <a href={'https://kubernetes.io/docs/concepts/workloads/controllers/app/'}> Learn more... </a>;
    return <Alert showIcon type="info" description={alertDescription} action={alertAction} />;
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      <CommonFormUI form={form} />
      {volumeHelpUI()}
    </Space>
  );
};
