import { ReactElement } from 'react';
import { OneFile } from '../FileEditor/OneFile';
import { PROJECT_CODE_NAME } from 'interface/common';
import { Alert, Typography, Space } from 'antd';
import { spaceWidth } from 'utils/styles';
import { iProject } from 'interface/project';
import { iProjectModel } from 'shared/deployment';

const { Text } = Typography;

export const ProvisionerConfiguration = (props: { project: iProjectModel }): ReactElement => {
  const alertMessage = (
    <ul>
      <li> This tab allows you to provide custom karpenter NodePools and NodeClasses for Deployments. </li>
      <li> Not optimal configuration will cause an increase in hosting costs. </li>
    </ul>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Text>
        You can add here your
        <Text strong>
          <a href="https://karpenter.sh/docs/concepts/nodepools/" target="_blank">
            &nbsp;NodePools&nbsp;
          </a>
        </Text>
        configuration and
        <Text strong>
          <a href="https://karpenter.sh/docs/concepts/nodeclasses/" target="_blank">
            &nbsp;NodeClasses&nbsp;
          </a>
        </Text>
      </Text>
      <Alert type={props.project.allowCustomProvisioner ? 'warning' : 'info'} closable={true} message={alertMessage} />
      {props.project.allowCustomProvisioner ? (
        <OneFile fileName={`${PROJECT_CODE_NAME}/Provisioner.yaml`} project={props.project} />
      ) : (
        <Alert type="warning" showIcon message="This function not enabled in AdminUI for this Project" closable={true} />
      )}
    </Space>
  );
};
