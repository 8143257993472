import gql from 'graphql-tag';
import { projectService } from 'services/project.service';
import { useHistory } from 'react-router-dom';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';
import { Alert, Button, Collapse, Modal, Skeleton, Space, Typography, notification } from 'antd';
import { ForkOutlined, WarningFilled } from '@ant-design/icons';
import { buttonWidth } from 'utils/styles';
import type { CollapseProps } from 'antd';
import { iProjectModel } from 'shared/deployment';

const { Text, Title } = Typography;

interface iDeleteProjectProps {
  project: iProjectModel;
}

export const DeleteProject = (props: iDeleteProjectProps) => {
  const project = props.project;
  const history = useHistory();

  const [copyProject, newProject] = useAuthedMutationWithNotification(gql`
    mutation ProjectController_copyProject($projectId: Int!) {
      ProjectController_copyProject(projectId: $projectId) {
        id
      }
    }
  `);

  const deleteProject = () => {
    return new Promise(resolve => {
      const modalContent = (
        <Text>
          Do you want to Delete <Text strong> {project.name} </Text> Project?
        </Text>
      );

      const modalOnOk = async () => {
        const res = await projectService.deleteProject(Number(project.id));
        if (res.status == 403) notification.error({ description: `Deleted`, message: 'Deletion is Prohibited. The project has Child Deployments' });
        else {
          if (res.status === 200) {
            notification.success({ description: `Deleted`, message: 'Project Deleted' });
            history.push('/projects');
            resolve(true);
          }
        }
      };

      const modalOnCancel = () => resolve(false);

      Modal.confirm({ title: 'Confirm?', content: modalContent, okText: 'Ok', cancelText: 'Cancel', onOk: modalOnOk, onCancel: modalOnCancel });
    });
  };

  if (newProject) {
    if (newProject.loading) return <Skeleton active={true} loading={true} />;
    if (newProject?.data?.ProjectController_copyProject) {
      history.push(`/project/${newProject?.data?.ProjectController_copyProject?.id}/settings/general`);
      return <Skeleton active={true} loading={true} />;
    }
  }

  const onChange = (key: string | string[]) => console.log(key);

  const deleteProjectLabel = () => (
    <Space direction="horizontal">
      <Text strong type="danger">
        <WarningFilled /> &nbsp;
        {`Delete ${project.name} Project?`}
      </Text>
    </Space>
  );

  const deleteProjectChildren = () => {
    const alertActionDelete = (
      <Button type="primary" danger onClick={deleteProject} style={buttonWidth}>
        DELETE
      </Button>
    );
    return (
      <Alert
        type="error"
        message={<Title level={5}> Deleting {project.name} Project </Title>}
        description={`This is not a reversible action. Deletion will result in Data loss.`}
        action={alertActionDelete}
      />
    );
  };

  const copyProjectLabel = () => (
    <Space direction="horizontal">
      <Text strong type="success">
        <ForkOutlined type="success" /> &nbsp;
        {`Fork ${project.name} Project?`}
      </Text>
    </Space>
  );

  const forkProjectChildren = () => {
    const alertActionFork = () => {
      const handleForkButton = async () => await copyProject({ variables: { projectId: Number(project.id) } });
      return (
        <Button type="primary" style={buttonWidth} onClick={handleForkButton}>
          <ForkOutlined /> Fork
        </Button>
      );
    };

    return (
      <Alert
        type="info"
        message={<Title level={5}> Fork {project.name} Project </Title>}
        description={`You can create a fork of ${project.name} Project.`}
        action={alertActionFork()}
      />
    );
  };

  const items: CollapseProps['items'] = [{ key: 'deleteProject', label: deleteProjectLabel(), children: deleteProjectChildren() }];

  if (project.gitInitialized) items.push({ key: 'copyProject', label: copyProjectLabel(), children: forkProjectChildren() });

  return <Collapse size="small" items={items} onChange={onChange} ghost />;
};
