import { ReactElement } from 'react';
import { iProject } from 'interface/project';
import { Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { GitConfiguration } from './GitConfiguration';
import { ConfigurationServices } from './services/ConfigurationServices';
import { NewService } from './services/NewService';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { ConfigurationSettings } from './ConfigurationSettings';

interface iTemplateSettingsRoutes {
  tabName: string;
  serviceName: string;
  project: iProjectModel;
  deployment: iDeployment;
}

export const TemplateSettingsRoutes = (props: iTemplateSettingsRoutes): ReactElement => {
  const history = useHistory();

  const project = props.project;
  let tabName = props.tabName;
  let serviceName = props.serviceName;

  if (tabName === 'git') {
    return <GitConfiguration deployment={props.deployment} />;
  }
  if (tabName === 'new-service') {
    return <NewService deployment={props.deployment} />;
  }
  if (tabName === 'services') {
    return <ConfigurationServices deployment={props.deployment} serviceName={serviceName} />;
  }

  if (tabName === 'settings') {
    return <ConfigurationSettings deployment={props.deployment} settingsTab={serviceName} />;
  }

  history.push(`/app/${props.deployment.id}/configuration/git`);
  return <Skeleton active={true} loading={true} />;
};
