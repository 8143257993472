import { projectService } from 'services/project.service';
import { iProject } from 'interface/project';
import { Alert, Button, Form, Space, Switch, Typography, notification } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { buttonWidth, orangeColor, spaceWidth } from 'utils/styles';
import { layout } from './ProjectEnvSettings';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { useState } from 'react';
import { CodeLine } from 'components/SharedComponents/CodeLine/CodeLine';
import { iProjectModel } from 'shared/deployment';

interface iShareProjectProps {
  project: iProjectModel;
}

const { Text } = Typography;
const { Item } = Form;

export const ShareProject = (props: iShareProjectProps) => {
  const project = props.project;

  const [isPublic, setIsPublic] = useState(project.isPublic);

  const handleSubmit = async data => {
    debugger;
    const res = await projectService.setProjectSettings(project.id, data);
    res.error ? notification.error({ message: `Error - ${res.error}` }) : notification.success({ message: `Ready` });
  };

  const switches = () => (
    <Item label="Other users will be able to see and use this template" name={`isPublic`} {...layout} style={{ marginLeft: -80 }}>
      <Switch checked={isPublic} checkedChildren="Project is public" unCheckedChildren="Project is private" onChange={e => setIsPublic(e)} />
    </Item>
  );

  const readmeContent = () => {
    const alertData = () => {
      const alertDescription = (
        <Text>
          Making a template public will allow other users to see and use it.&nbsp;
          <Text strong> Please be sure if you publish you template to the public github repository. </Text>
        </Text>
      );
      const alertIcon = <WarningFilled style={orangeColor} />;
      return <Alert message="Warning" description={alertDescription} type="warning" showIcon icon={alertIcon} />;
    };

    const instruction = () => (
      <Text>
        Please add this code to your <Text strong> README.md </Text> file to allow other users to deploy your template with one click:
      </Text>
    );

    const copyCode = () => (
      <CodeLine>
        [![deploy with unifie](https://api.unifie.cloud/deploy-btn/button_unifie-one-click-deploy.gif)](
        {`https://unifie.cloud/kubernetes/${project.uuid}`})
      </CodeLine>
    );

    return (
      isPublic && (
        <Space direction="vertical" style={spaceWidth}>
          {alertData()} <Text /> {instruction()} {copyCode()}
        </Space>
      )
    );
  };

  const bottomButtons = () => (
    <Item>
      <BottomButtons>
        <Button type="primary" htmlType="submit" style={buttonWidth}>
          Save
        </Button>
      </BottomButtons>
    </Item>
  );

  return (
    <Form initialValues={project} onFinish={async values => handleSubmit(values)} {...layout}>
      {switches()}
      {readmeContent()}
      {bottomButtons()}
    </Form>
  );
};
