import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deploymentService } from '../../../../services/deployment.service';
import { useApiQuery } from '../../../../utils/common';
import { DeploymentEnvVariablesInfo } from './EnvVarDocs';
import { iDeployment } from 'shared/deployment';
import { projectService } from 'services/project.service';
import { IEnvSettingSchema } from 'interface/common';
import { Alert, AutoComplete, Button, Input, message, notification, Skeleton, Space, Switch, Typography } from 'antd';
import { DeleteOutlined, EnterOutlined, PlusOutlined } from '@ant-design/icons';
import { buttonWidth, buttonBorder, spaceWidth, nav1, nav2, nav3, nav4, nav5 } from 'utils/styles';

export interface DeploymentEnvVariablesFormProps {
  deployment: iDeployment;
  onSave?: () => void;
}

const { Text } = Typography;

export default function DeploymentEnvVariablesForm(props: DeploymentEnvVariablesFormProps) {
  const history = useHistory();
  const deployment: iDeployment = props.deployment;
  const [projectEnvData, error, loading] = useApiQuery(() => projectService.getProjectEnv(deployment.projectId));
  const [defaulteDeployment, setDefaulteDeployment] = useState([{ name: 'null', value: 'null', isActive: false, newVar: false }]);
  const [stateDeployment, setDeployment] = useState([{ name: 'null', value: 'null', isActive: false, newVar: false }]);
  const [isDisabled, setDisabled] = useState(false);

  const projectEnv: IEnvSettingSchema = projectEnvData;

  useEffect(() => {
    const env = deployment
      ? Object.keys(deployment.env).map(k => ({
          name: k,
          value: deployment.env[k],
          isActive: false,
          newVar: false,
        }))
      : '';
    env ? (setDefaulteDeployment(env), setDeployment(env)) : null;
  }, [deployment]);

  if (error || loading) return <Skeleton active={true} loading={true} />;
  if (error) history.push('/');

  function getEnvVariableType(name: string) {
    return projectEnv.env.find(e => e.name === name) ? projectEnv.env.find(e => e.name === name).type : 'text';
  }

  const possibleEnvNames = projectEnv.env.map(e => e.name);

  const onFinish = async (values: any[]) => {
    setDisabled(true);

    let isEdit = false;
    stateDeployment.map((e, i) => {
      if (!e) return (isEdit = true);
      defaulteDeployment[i] && e
        ? e.name !== defaulteDeployment[i].name || e.value !== defaulteDeployment[i].value
          ? (isEdit = true)
          : ''
        : e.value && e.name
        ? (isEdit = true)
        : '';
    });

    let error = false;
    const env = {};

    values.forEach(element => {
      !element || (!element.name && !element.value)
        ? null
        : !element.name
        ? (message.error(`The value of the ${element.value} variable has no name`), (error = true))
        : !element.value
        ? (message.error(`The variable ${element.name} has no value`), (error = true))
        : !/^[A-Za-z0-9_]+$/.test(element.name)
        ? (message.error(`Variable name is invalid: ${element.name}`), (error = true))
        : (env[element.name] = element.value);
    });

    if (error || !isEdit) return setDisabled(false);

    const res = await deploymentService.updateDeployment(deployment.id, { env });

    deployment.env = res.data.env;
    if (res.status === 401) return window.location.reload();

    notification.success({ description: `Saved`, message: '' });
    setDeployment(stateDeployment.filter(e => (e ? (e.name && e.value ? { ...e, isActive: false, newVar: false } : '') : '')));
    setDefaulteDeployment(stateDeployment.filter(e => (e ? (e.name && e.value ? { ...e, isActive: false, newVar: false } : '') : '')));
    setDisabled(false);

    if (props.onSave) props.onSave();
  };

  const chekEdit = (i: string | number, value: string, key: string) =>
    stateDeployment[i].newVar
      ? true
      : defaulteDeployment[i][key] === value
      ? key === 'name'
        ? defaulteDeployment[i].name !== stateDeployment[i].name
        : defaulteDeployment[i].value !== stateDeployment[i].value
      : false;

  const onChangeHandler = (value: string, key: string, index: number) => {
    setDeployment(stateDeployment.map((e, i) => (i === index ? { ...e, [key]: value, isActive: !chekEdit(i, value, key) } : e)));
    !value && stateDeployment.length !== index + 1
      ? (key === 'value' && !stateDeployment[index].name) || (key === 'name' && !stateDeployment[index].value)
        ? onRemoveHandler(index)
        : null
      : null;
  };

  const onRemoveHandler = (index: number) => setDeployment(stateDeployment.map((e: any, i: any) => (i === index ? '' : e)));

  const addNewVar = () => {
    stateDeployment[stateDeployment.length - 1]
      ? stateDeployment[stateDeployment.length - 1].name && stateDeployment[stateDeployment.length - 1].value
        ? setDeployment([...stateDeployment, { name: '', value: '', isActive: false, newVar: true }])
        : ''
      : setDeployment([...stateDeployment, { name: '', value: '', isActive: false, newVar: true }]);
  };

  const cancelHandler = (i: string | number) =>
    setDeployment(stateDeployment.map((e, index) => (i === index ? { ...e, ...defaulteDeployment[i], isActive: false } : e)));

  const canselAllHadler = () => setDeployment(defaulteDeployment);

  const deploymentFreezed_A = () => (
    <>
      <Alert description={`This deployment is frozen, to edit the parameters, change the value of General -> frozen to "false"`} type="warning" />
      <Text />
    </>
  );

  const deploymentFreezed_B = () => (
    <Space direction="vertical" style={spaceWidth}>
      <Text />
      <Button disabled={isDisabled} onClick={addNewVar} type="dashed" block icon={<PlusOutlined />}>
        Add Variable
      </Button>
      <Text />
      <Space wrap>
        <Button disabled={isDisabled} onClick={() => onFinish(stateDeployment)} type="primary" htmlType="submit" style={buttonWidth}>
          Save
        </Button>
        <Button disabled={isDisabled} onClick={canselAllHadler} htmlType="submit" style={buttonWidth}>
          Cancel
        </Button>
      </Space>
      <Text />
      <Text />
    </Space>
  );

  const deploymentFreezed = () => (
    <>
      <Alert showIcon type="info" message={`Environment variable names are Case sensitive.`} />
      {deployment.freezed ? deploymentFreezed_A() : null}
      {stateDeployment.map((e, i) => {
        if (e) {
          const inputE_1 = () => (
            <Input
              type={getEnvVariableType(e.name)}
              addonAfter={<DeploymentEnvVariablesInfo name={e.name} value={e.value} deployment={deployment} projectEnv={projectEnv} />}
              style={nav4}
              disabled={isDisabled}
              value={e.value}
              onChange={event => onChangeHandler(event.currentTarget.value, 'value', i)}
            />
          );

          const inputE_2 = () => (
            <div style={nav1}>
              <Switch checked={e.value === 'true'} onChange={checked => onChangeHandler(checked ? 'true' : 'false', 'value', i)} />
              <div style={nav2}>
                <DeploymentEnvVariablesInfo name={e.name} value={e.value} deployment={deployment} projectEnv={projectEnv} />
              </div>
            </div>
          );

          let inputE = inputE_1();
          if (getEnvVariableType(e.name) === 'boolean') inputE = inputE_2();

          return (
            <div key={i} style={nav3}>
              <div style={{ ...nav4, ...nav5 }}>
                <SelectEnvName e={e} onChangeHandler={onChangeHandler} possibleEnvNames={possibleEnvNames} isDisabled={isDisabled} i={i} />
              </div>
              <div style={{ ...nav4, ...nav5 }}> {inputE} </div>
              {stateDeployment[i].isActive ? (
                <Button type="primary" style={buttonBorder} disabled={isDisabled} onClick={() => cancelHandler(i)}>
                  <EnterOutlined />
                </Button>
              ) : deployment.freezed ? (
                ''
              ) : (
                <Button type="primary" danger style={buttonBorder} disabled={isDisabled} onClick={() => onRemoveHandler(i)}>
                  <DeleteOutlined />
                </Button>
              )}
            </div>
          );
        }
      })}
      {deployment.freezed ? null : deploymentFreezed_B()}
    </>
  );

  return deploymentFreezed();
}

const SelectEnvName = ({ e, onChangeHandler, possibleEnvNames, isDisabled, i }: any) => {
  const [filteredEnv, setFilteredEnv] = useState(possibleEnvNames.map((env: string) => ({ value: env })));

  const onChangeAutoComplete = (value: string, key: string, i: any) => {
    setFilteredEnv(
      possibleEnvNames
        ? possibleEnvNames
            .filter((env: string) => String(env.toLowerCase()).includes(String(value.toLowerCase())))
            .map((env: string) => ({ value: env }))
        : [],
    );
    onChangeHandler(value, key, i);
  };

  return (
    <AutoComplete
      disabled={isDisabled}
      value={e.name}
      onChange={value => onChangeAutoComplete(value, 'name', i)}
      options={filteredEnv}
      style={spaceWidth}
    />
  );
};
