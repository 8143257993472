import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { nhTemplateInJson } from 'shared/project.interface';
import { ProjectDeploymentSyncVarsContext } from 'components/Projects/ProjectDeploymentContext';
import { VarNameComponent } from 'components/Projects/YamlSpecCustomization/VarNameComponent';
import { Button, Input, InputNumber, Select, Space } from 'antd';
import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import { selectWidth, spaceWidth } from 'utils/styles';

const { Option } = Select;
const { Compact } = Space;
const { TextArea } = Input;

/**
 * DeploymentVarWidget
 * @param props
 * @returns
 * if props.options.showDstType === true - will show dst type select box props.options.syncVars - can contain  syncVars for autocomplete
 */

export const DeploymentVarWidget = (props: WidgetProps) => {
  let value = props.value || '';
  let jsonData: nhTemplateInJson = null;
  let baseUiType = 'text';

  if (value === null) value = '';
  else if (Array.isArray(value)) value = JSON.stringify(value);
  else if (typeof value === 'object') value = JSON.stringify(value);
  else if (typeof value !== 'string') value = String(value);

  let type = 'hardcoded';
  try {
    jsonData = JSON.parse(value);
  } catch (e) {
    type = 'hardcoded';
  }

  if (jsonData?._nhKind !== 'tpl') {
    type = 'hardcoded';
  } else {
    type = jsonData.srcType;
    value = jsonData.value;
  }

  if (String(value).indexOf('\n') !== -1 || String(value).length > 100) baseUiType = 'textarea';

  const [error, setError] = useState<string>('');
  const [srcType, setSrcType] = useState(type || 'hardcoded');
  const [dstType, setDstType] = useState(String(props.schema.type || 'string'));
  const [inputType, setInputType] = useState(baseUiType);
  const [userValue, setUserValue] = useState(value);
  const syncVarsContext = useContext(ProjectDeploymentSyncVarsContext);

  useEffect(() => {
    if (props.value != userValue) {
      if (typeof props.value !== 'string') {
        setUserValue(props.value);
        return;
      }
      try {
        const jsonData = JSON.parse(props.value);
        if (jsonData.value != userValue) setUserValue(jsonData.value);
      } catch (e) {
        setUserValue(props.value);
      }
    }
  }, [props.value]);

  const setNewValueType = (newValue: string, newSrcType: string, newDstType: string) => {
    const result: nhTemplateInJson = {
      _nhKind: 'tpl',
      value: newValue,
      defaultValue: props.schema.default,
      srcType: newSrcType,
      dstType: newDstType,
    };
    if (newSrcType === 'hardcoded') {
      let newValueParsed: any = newValue;
      if (newDstType === 'number') newValueParsed = Number(newValue);
      else if (newDstType === 'boolean') newValueParsed = Boolean(newValue);
      props.onChange(newValueParsed);
    } else {
      result;
      props.onChange(JSON.stringify(result));
    }
  };

  const setNewDstType = (value: string) => {
    setDstType(value);
    setNewValueType(userValue, srcType, value);
  };

  const setNewSrcType = (value: string) => {
    setSrcType(value);
    setNewValueType(userValue, value, dstType);
  };

  const setNewUserValue = (event: any) => {
    if (props.schema?.validator && srcType === 'hardcoded') {
      const res = props.schema?.validator(event.target.value);
      if (res) {
        setError(res);
        return;
      } else setError(null);
    } else setError(null);
    setUserValue(event.target.value);
    setNewValueType(event.target.value, srcType, dstType);
  };

  let userUIValue = userValue;
  if (Array.isArray(userValue) && ['object', 'array'].includes(String(props?.schema?.type || dstType))) userUIValue = JSON.stringify(userValue);
  if (typeof userValue === 'object' && ['object', 'array'].includes(String(props?.schema?.type || dstType))) userUIValue = JSON.stringify(userValue);

  const errorMessage =
    srcType === 'hardcoded' && error ? (
      <>
        <br />
        {error}
      </>
    ) : null;

  const optionMapping = { hardcoded: 'Value', variable: 'Variable', template: 'Template' };
  const typeOptions = { string: 'String', number: 'Number', boolean: 'Boolean', object: 'Object', array: 'Array' };

  const selectBefore = (
    <>
      <Select
        defaultValue={'hardcoded'}
        value={srcType || 'hardcoded'}
        onChange={setNewSrcType}
        className={`DeploymentVarWidget-selectBefore`}
        style={{ minWidth: '95px' }}
      >
        {Object.entries(optionMapping).map(([value, label]) => (
          <Option key={value} value={value}>
            {label}
          </Option>
        ))}
      </Select>

      {props?.options?.showDstType && (
        <Select defaultValue={dstType} value={dstType || 'string'} onChange={setNewDstType} className={`DeploymentVarWidget-selectBefore`}>
          {Object.entries(typeOptions).map(([value, label]) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      )}
    </>
  );

  if (srcType === 'variable') {
    let syncVars = props?.options?.syncVars;
    if (!syncVars && syncVarsContext?.syncVars && syncVarsContext?.syncVars[syncVarsContext?.serviceName])
      syncVars = syncVarsContext?.syncVars[syncVarsContext?.serviceName];

    return (
      <VarNameComponent
        addonBefore={selectBefore}
        onChange={(value: string) => {
          setUserValue(value);
          setNewValueType(value, srcType, dstType);
        }}
        syncVars={syncVars}
        value={userUIValue}
      />
    );
  }

  if (inputType === 'text') {
    if (props.schema.enum) {
      return (
        <>
          <Compact block>
            {selectBefore}
            <Select
              defaultValue={!!props?.schema?.default}
              onChange={v => setNewUserValue({ target: { value: v } })}
              options={props.schema.enum.map((v: any) => {
                return { value: v, label: v };
              })}
              style={selectWidth}
            />
          </Compact>
          {errorMessage}
        </>
      );
    }

    if (dstType === 'number' && srcType === 'hardcoded') {
      return (
        <>
          <Compact block>
            {selectBefore}
            <InputNumber
              status={error ? 'error' : null}
              value={userUIValue}
              defaultValue={String(props.schema.default || '')}
              onChange={v => setNewUserValue({ target: { value: '' + v } })}
              style={{ ...selectWidth, ...spaceWidth }}
            />
          </Compact>
          {errorMessage}
        </>
      );
    } else if (dstType === 'boolean' && srcType === 'hardcoded') {
      const selectOptions = [
        { value: true, label: 'True' },
        { value: false, label: 'False' },
      ];
      return (
        <>
          <Compact block>
            {selectBefore}
            <Select
              defaultValue={!!props?.schema?.default}
              onChange={v => setNewUserValue({ target: { value: v } })}
              options={selectOptions}
              style={selectWidth}
            />
          </Compact>
          {errorMessage}
        </>
      );
    }

    return (
      <>
        <Compact block>
          {selectBefore}
          <Input
            status={error ? 'error' : null}
            value={userUIValue}
            defaultValue={String(props.schema.default || '')}
            onChange={setNewUserValue}
            suffix={<ArrowsAltOutlined onClick={v => setInputType('textarea')} />}
          />
        </Compact>
        {errorMessage}
      </>
    );
  }

  return (
    <>
      <Space>
        {selectBefore}
        <Button onClick={v => setInputType('text')}>
          <ShrinkOutlined />
        </Button>
      </Space>
      <br /> <br />
      <TextArea status={error ? 'error' : null} value={userValue} defaultValue={String(props.schema.default || '')} onChange={setNewUserValue} />
      {errorMessage}
    </>
  );
};
