import { Button, Col, Input, InputNumber, Popover, Row, Select, Space, Tag } from 'antd';
import { DeploymentVarWidget } from 'form-components/deploymentVarWidget';
import React from 'react';
import { JsonFormModalUI, iJsonFormModalUIProps } from './JsonFormModalUI';
import { EditOutlined, QuestionCircleTwoTone } from '@ant-design/icons';

export const CommonFormUI = (props: { form: { name: any; children: any; help?: any }[] }) => {
  return (
    <>
      {props.form.map((v, i) => {
        return (
          <Row key={i}>
            <Col flex="200px">{v.name}</Col>
            <Col flex="auto" className="formRowDefaultSpace">
              {v.children}
              {v.help || null}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export const autoFixer_emptyAsUndefined = (v: string) => {
  if (v === '') {
    return undefined;
  }
  return v;
};

export const CommonUI_text = (props: {
  help?: any;
  value: any;
  onChange: any;
  propertyName: string;
  path: string;
  validator?: (v) => string;
  autoFixer?: (v) => string;
}) => {
  const [error, setError] = React.useState<string>('');
  return (
    <>
      <Input
        value={props.value}
        style={{ width: '100%' }}
        status={error ? 'error' : null}
        onChange={v => {
          if (props.autoFixer) {
            v.target.value = props.autoFixer(v.target.value); // auto fixer
          }
          if (props.validator) {
            const res = props.validator(v.target.value);
            if (res) {
              setError(res);
              return;
            } else {
              setError(null);
            }
          }
          props.onChange(`${props.path}.${props.propertyName}`, v.target.value);
        }}
        addonAfter={
          props.help ? (
            <Popover placement="topLeft" content={props.help}>
              <QuestionCircleTwoTone twoToneColor="#00A58E" />
            </Popover>
          ) : null
        }
      />
      {error ? (
        <>
          <br />
          {error}
        </>
      ) : null}
    </>
  );
};
export const CommonUI_number = (props: { value: any; onChange: any; propertyName: string; path: string; validator?: (v) => string }) => {
  const [error, setError] = React.useState<string>('');
  return (
    <>
      <InputNumber
        value={props.value}
        style={{ width: '100%' }}
        status={error ? 'error' : null}
        onChange={v => {
          if (props.validator) {
            const res = props.validator(v);
            if (res) {
              setError(res);
              return;
            } else {
              setError(null);
            }
          }
          props.onChange(`${props.path}.${props.propertyName}`, Number(v));
        }}
      />
      {error ? (
        <>
          <br />
          {error}
        </>
      ) : null}
    </>
  );
};

export const CommonUI_DeploymentVarWidget = (props: {
  value: any;
  onChange: any;
  propertyName: string;
  path: string;
  dstType?: any;
  validator?: (v) => string;
}) => {
  return (
    <DeploymentVarWidget
      id={'1'}
      name={props.propertyName}
      schema={{ type: props.dstType, validator: props.validator }}
      value={props.value}
      options={undefined}
      onBlur={function (id: string, value: any): void {
        console.log('onBlur', id, value);
      }}
      onChange={function (value: any): void {
        props.onChange(`${props.path}.${props.propertyName}`, value);
      }}
      onFocus={function (id: string, value: any): void {
        console.log('onFocus', id, value);
      }}
      label={''}
      registry={undefined}
    />
  );
};

export const CommonUI_JsonFormModalUI = (props: iJsonFormModalUIProps) => {
  let hasData = false;
  if (Array.isArray(props.formData)) {
    hasData = props.formData.length > 0;
  } else if (typeof props.formData === 'object' && props.formData !== null) {
    hasData = Object.keys(props.formData).length > 0;
  } else {
    hasData = !!props.formData;
  }

  return (
    <Space>
      <Button
        onClick={() => {
          JsonFormModalUI(props);
        }}
      >
        <EditOutlined />
      </Button>
      {hasData ? (
        <>
          <Tag color="green">Configured</Tag>
        </>
      ) : (
        <Tag>Not configured</Tag>
      )}
    </Space>
  );
};

export const CommonUI_select = (props: {
  value: any;
  onChange: any;
  propertyName: string;
  path: string;
  options: { value: any; label: string | any }[];
  mode?: 'multiple' | 'tags';
}) => {
  return (
    <>
      <Select
        mode={props.mode || undefined}
        style={{ minWidth: '200px', width: '100%' }}
        value={props.value}
        onChange={v => props.onChange(`${props.path}.${props.propertyName}`, v)}
      >
        {props.options.map((v, i) => {
          return (
            <Select.Option key={i} value={v.value}>
              {v.label}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};
