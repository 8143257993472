import { Link, useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { iDeployment } from 'shared/deployment';
import { YamlTab } from './General/YamlTab';
import { RoutesTab } from './General/RoutesTab';
import { DeploymentSyncVars } from './General/DeploymentSyncVars';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { Button, Col, Menu, Result, Row, Skeleton, Tabs } from 'antd';
import { CloudServerOutlined, CodeOutlined, WarningOutlined } from '@ant-design/icons';
import { innerSidebar, orangeColor, redColor, siderStyle, topMargined, topPadding } from 'utils/styles';
import { MonitoringOfServices } from '../monitoring/MonitoringOfServices/MonitoringOfServices';
import { AWSPriceForDeployment } from '../monitoring/PriceCharts/AWSPriceForDeployment';
import { OpenCostPriceForDeployment } from '../monitoring/PriceCharts/OpenCostPriceForDeployment';
import OverViewV2 from '../OverView/OverViewV2';
import { OverViewInInit } from '../OverView/OverviewInInit';
import { DeploymentWebsshConsole } from '../DeploymentWebssh/DeploymentWebssh';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { DeploymentPvcList } from '../monitoring/DeploymentPvcList';

export default function DeploymentStatusPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();

  const user = useUser();
  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;

  const inInitStage_NoNamespace = !deployment?.NamespaceModel?.name;
  const inInitStage_NoSync = !(deployment?.NamespaceModel?.lastSyncDelay < 1707802250);
  const inInitStage = inInitStage_NoNamespace || inInitStage_NoSync;

  if (!tabName) {
    history.push(`/app/${id}/status/overview`);
  }

  if (error) {
    return (
      <Skeleton active={false} loading={false}>
        {JSON.stringify(error)}
      </Skeleton>
    );
  }

  if (loading || !deployment) {
    return <Skeleton active={true} loading={true} />;
  }

  if (!deployment.isReady) {
    return (
      <Result
        status="warning"
        title="Application not ready"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => history.push(`/app/${deployment.id}/setting/details`)}>
            Finish configuration
          </Button>
        }
      />
    );
  }

  if (inInitStage) {
    return (
      <>
        <TitleUIRow title={`Deployment: ${deployment.name}`} />
        <OverViewInInit deployment={deployment} />
      </>
    );
  }
  return (
    <>
      <TitleUIRow title={`Deployment: ${deployment.name}`} />
      <DeploymentWidgetsRow deployment={deployment} />
      <Tabs
        defaultActiveKey={tabName || `overview`}
        style={topMargined}
        onChange={(activeKey: string) => history.push(`/app/${id}/status/${activeKey}`)}
      >
        <Tabs.TabPane tab="Overview" key="overview">
          <OverViewV2 deployment={deployment} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Services" key="services">
          <MonitoringOfServices deployment={deployment} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="OpenCost" key="price-opencost">
          <OpenCostPriceForDeployment deployment={deployment} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="AWS" key="price-aws">
          <AWSPriceForDeployment deployment={deployment} />
        </Tabs.TabPane>
        {userHasPermission(user, UserPermissions.DeploymentWebSSH) && (
          <Tabs.TabPane
            tab={
              <>
                <CodeOutlined /> Console
              </>
            }
            key="console"
          >
            <DeploymentWebsshConsole deployment={dpQuery.data?.DeploymentsController_getDeployment} />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <>
              <CloudServerOutlined /> Volumes
            </>
          }
          key="pvc"
        >
          <DeploymentPvcList deployment={deployment} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
