import { CRUDTable } from 'components/SharedComponents/CrudTable';
import { iUserModel } from 'shared/user';
import { auditLogsService } from 'services/auditLogs.service';
import { usersService } from 'services/users.service';
import { crudAdminBuildParams, crudAdminFiltersApi, useApiQuery } from 'utils/common';
import { message, Skeleton } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { spaceWidth } from 'utils/styles';

const defaultFilters = {};
const usersOpts = {};

export const AuditLogs = () => {
  const [users, err, loading] = useApiQuery(usersService.getUsers);
  if (!users || err || loading) return <Skeleton active={true} loading={true} />;

  const goToUser = (users: any[], row: { userId: number }, history: string[]) =>
    users.find((user: iUserModel) => user.id === row.userId) ? history.push(`/users/${row.userId}`) : message.error('This User does not exist');

  users.map((row: iUserModel) => (usersOpts[row.id] = row.email));

  const modelOpts = { DeploymentModel: 'Deployment', VersionModel: 'Version', ProjectModel: 'Project', UserModel: 'User', RegionModel: 'Cluster' };

  const logColumn = [
    { title: 'ID', dataIndex: 'id', hideInForm: true },
    { title: 'User', dataIndex: 'userId', valueType: 'select', valueEnum: usersOpts, fieldProps: { mode: 'multiple' } },
    { title: 'Model', dataIndex: 'modeName', valueType: 'select', sorter: true, valueEnum: modelOpts },
    { title: 'Object ID', dataIndex: 'objectId', valueType: 'id', sorter: true },
    { title: 'Action', dataIndex: 'actionName', valueType: 'string', sorter: true },
    { title: 'Time', dataIndex: 'createdAt', valueType: 'dateTime', sorter: true, search: false, hideInForm: true },
    { title: 'Data', dataIndex: 'actionData', valueType: 'text', sorter: true, search: false, hideInTable: true },
  ];

  const AuditLogsApi = {
    getFilters: crudAdminFiltersApi(),
    getObj: async (params: any, sort: any, filter: any) => {
      const answer = await auditLogsService.getLogs(crudAdminBuildParams(defaultFilters, crudAdminFiltersApi(), params, sort, filter));
      return { data: answer.data?.data || [], success: true, total: answer.data?.total || answer.data?.data?.length || 0 };
    },
    addObj: async () => false,
    updateObj: async () => false,
    removeObj: async () => false,
    actionObj: async () => false,
  };

  const viewUser = async (row: { userId: number }, opt: { history: string[] }) => goToUser(users, row, opt.history);
  const logAction = [{ name: 'Go to User', icon: <ArrowRightOutlined />, isSingle: true, callback: viewUser }];
  const opt = { primaryKey: 'id', columns: logColumn, canCreate: false, canUpdate: false, canDelete: false, crud: AuditLogsApi, actions: logAction };

  return (
    <div style={spaceWidth}>
      <CRUDTable {...opt} />
    </div>
  );
};
