import gql from 'graphql-tag';
import { Alert, Button, Form, Input, Select, Skeleton, Space, Switch, Tag, Typography, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { TitleUIRow } from 'layout/TitleUI';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { regionsService, useRegionsController_getRegionById } from 'services/regions.service';
import { useAuthedQuery } from 'utils/qlAuth';
import { buttonWidth, redColor, spaceWidth } from 'utils/styles';
import { ClusterDeleteButton } from '../ClusterDangerZone';
import { Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import { ClusterRegionNames } from './ClusterRegionNames';

const { Text } = Typography;
const { Item } = Form;
const { Option } = Select;

export const ClusterCreaionStep3 = () => {
  const history = useHistory();
  const params: any = useParams();

  const { loading, region, stopPolling, startPolling, refetch } = useRegionsController_getRegionById(params.regionId);

  const [useCostExplorerApi, setUseCostExplorerApi] = useState(false);
  const [usePluginOpencost, setUsePluginOpencost] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const qTags = useAuthedQuery(
    gql`
      query TagsController_tagsList {
        TagsController_tagsList {
          id
          name
          color
        }
      }
    `,
    {},
  );

  useEffect(() => {
    if (!region) return;
    setUseCostExplorerApi(region.useCostExplorerApi !== useCostExplorerApi ? region.useCostExplorerApi : useCostExplorerApi);
    setUsePluginOpencost(region.usePluginOpencost !== usePluginOpencost ? region.usePluginOpencost : usePluginOpencost);
  }, [region, useCostExplorerApi, usePluginOpencost]);

  if (loading || qTags.loading) return <Skeleton active={true} loading={true} />;

  const clusterSaveConfig = async values => {
    values.configureStatus = 'configured';
    const res = await regionsService.updateOwnRegion(region.id, values);
    res.error
      ? notification.error({ message: `Error - ${res.error}` })
      : (notification.success({ message: `Ready` }), history.push(`/cluster/new/${region.id}/integrations`));
  };

  const tags = qTags?.data?.TagsController_tagsList || [];

  const useTerraform = region.useTerraform;

  const extraForm = () => {
    const useCostExplorerApiMessage = (
      <Text>
        Use Cost Explorer API to get cost data. If you don't have Cost Explorer enabled, you can use the OpenCost plugin. Use Cost Explorer API is
        paid api. AWS will charge you for it. Be careful.
      </Text>
    );
    return (
      <>
        {region.cloudProvider === 'aws' && (
          <Space direction="vertical" style={spaceWidth}>
            <Item name="useCostExplorerApi" label={`AWS cost explorer API ${'\u00A0'.repeat(35)}`}>
              <Switch checked={useCostExplorerApi} onChange={setUseCostExplorerApi} />
            </Item>
            {useCostExplorerApi && <Alert showIcon type="warning" message={useCostExplorerApiMessage} />}
            <Text />
            <Item
              name="AWS_Account_ID"
              hidden={!useCostExplorerApi}
              label={`AWS account ID ${'\u00A0'.repeat(23)}`}
              initialValue={region.AWS_Account_ID}
            >
              <Input placeholder="Enter AWS Account ID" />
            </Item>
            <Item
              name="CE_AWS_ACCESS_KEY_ID"
              hidden={!useCostExplorerApi}
              label={`CE AWS access key ID ${'\u00A0'.repeat(10)}`}
              initialValue={region.CE_AWS_ACCESS_KEY_ID}
            >
              <Input placeholder="Enter CE_AWS_ACCESS_KEY_ID" />
            </Item>
            <Item name="CE_AWS_SECRET_ACCESS_KEY" hidden={!useCostExplorerApi} label={`CE AWS secret access KEY ${'\u00A0'.repeat(1)}`}>
              <Input type="password" placeholder="Enter CE_AWS_SECRET_ACCESS_KEY" />
            </Item>
          </Space>
        )}

        <Item name="usePluginOpencost" label={`I have OpenCost installation in the Cluster ${'\u00A0'.repeat(2)}`}>
          <Switch checked={usePluginOpencost} onChange={e => setUsePluginOpencost(e)} />
        </Item>
        <Item
          name="pluginOpencostHost"
          label={`Plugin Opencost Host ${'\u00A0'.repeat(14)}`}
          hidden={!usePluginOpencost}
          initialValue={region.pluginOpencostHost}
        >
          <Input placeholder="Enter pluginOpencostHost" />
        </Item>
        <Space direction="vertical">
          <Text /> <Text /> <Text /> <Text /> <Text />
        </Space>
      </>
    );
  };

  const filteredTags = tags.filter(tag => tag.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const clusterDuration = (seconds: number) => {
    const warning = seconds > 600 ? <Text style={redColor}> Last sync is unavailable due to a possible issue with the cluster. </Text> : '';
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;
    const duration = `Last sync was: ${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds ago.`;
    return warning || duration;
  };

  const clusterCreationStep3 = () => (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={`Cluster Configuration`} />
      <Text> You install the agent to your Cluster. </Text>
      <Text strong> {clusterDuration(region.noSyncTime)} </Text>
      <Text> Now please finish configuration of the Cluster! </Text>
      <Text />
      <Text />

      <Form initialValues={region} onFinish={clusterSaveConfig}>
        {!useTerraform && (
          <Item name="name" label={`Cluster Name ${'\u00A0'.repeat(4)}`} rules={[{ message: 'Please input the Cluster name!', required: true }]}>
            <Input placeholder="Enter Cluster Name Here" />
          </Item>
        )}
        <Item name="title" label={`Cluster Title ${'\u00A0'.repeat(10)}`}>
          <Input placeholder="Enter Cluster Title Here" />
        </Item>
        {!useTerraform && (
          <Item name="regionName" label={`Region Name ${'\u00A0'.repeat(7)}`}>
            <ClusterRegionNames cloudProvider={region.cloudProvider} value={region.regionName} />
          </Item>
        )}
        {tags.length > 0 && (
          <Item name="tags" label={`Tags ${'\u00A0'.repeat(22)}`}>
            <Select
              showSearch
              placeholder="Select Tags Here"
              mode="multiple"
              onSearch={value => setSearchTerm(value)}
              filterOption={false}
              style={spaceWidth}
            >
              {filteredTags.map(
                (elem: {
                  id: Key;
                  color: string | (string & {});
                  name: string | number | boolean | ReactElement<string> | Iterable<ReactNode> | ReactPortal;
                }) => (
                  <Option value={elem.id} key={elem.id}>
                    <Tag color={elem.color}> {elem.name} </Tag>
                  </Option>
                ),
              )}
            </Select>
          </Item>
        )}
        {/* {extraForm()} */}
        <BottomButtons>
          <Space>
            <Button type="primary" htmlType="submit" data-qa="ClusterCreaionStep3.tsx" style={buttonWidth}>
              Save & Continue
            </Button>
            <ClusterDeleteButton region={region} />
          </Space>
        </BottomButtons>
      </Form>
    </Space>
  );

  return clusterCreationStep3();
};
