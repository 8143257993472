import moment from 'moment';
import InfoUpdate from '../OverView/InfoUpdateComponent';
import { useHistory, useParams } from 'react-router-dom';
import { useApiQuery, useUser } from '../../../utils/common';
import { auditLogsService } from 'services/auditLogs.service';
import { iDeployment } from 'shared/deployment';
import { TitleUIRow } from 'layout/TitleUI';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { UserNoPermissions } from 'components/Permissions/Permissions';
import { Col, Typography, Collapse, Skeleton, Tag, Tooltip, Space, Alert } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { autoOverflow, spaceWidth } from 'utils/styles';
import { PROJECT_NAME } from 'interface/common';

const { Text } = Typography;
const { Panel } = Collapse;

export default function AuditLogs(props: { deployment: iDeployment }) {
  // @ts-ignore
  let { id } = useParams();
  const history = useHistory();
  const user = useUser();
  const [dHistory, dHistoryError, dHistoryLoading] = useApiQuery(() => auditLogsService.getDeploymentLogsHistory(id));

  if (!userHasPermission(user, UserPermissions.DeploymentHistory)) return <UserNoPermissions permission={UserPermissions.DeploymentHistory} />;

  if (dHistoryError) {
    history.push('/');
    return <Skeleton active={true} loading={true} />;
  }

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Alert showIcon type="info" message={`Audit logs details can be seen below.`} />
      <Skeleton active={true} loading={dHistoryLoading}>
        <TitleUIRow title={`Audit logs: ${props.deployment.name}`} />
        <Col>
          <Collapse size="small">
            {(dHistory || []).map(
              (v: { createdAt: moment.MomentInput; data: { diff: { [x: string]: any } }; userName: any; name: string }, key: number) => {
                const formattedDate = moment(v.createdAt).format('DD-MM-YYYY ~ hh:mm:ss A');

                const panelData = () => {
                  const userLogs = () => {
                    return Object.keys(v.data?.diff || []).map(key => {
                      if (key == 'env' || key == 'serviceVars') {
                        return Object.keys(v.data.diff[key] || []).map(envKey => {
                          const keyData = v.data.diff[key][envKey];

                          const dataOld = () => (
                            <Tooltip color="#115EA3" placement="bottom" title="Old env value">
                              <Tag color="gold"> {JSON.stringify(keyData.old)} </Tag>
                            </Tooltip>
                          );

                          const dataNew = () => (
                            <Space direction="horizontal">
                              <ArrowRightOutlined />
                              <Tooltip color="#115EA3" placement="bottom" title="New env value">
                                <Tag color="green"> {JSON.stringify(keyData.new)} </Tag>
                              </Tooltip>
                            </Space>
                          );

                          return (
                            <li>
                              <Tooltip color="#115EA3" placement="bottom" title="ENV name">
                                <Tag color="magenta"> {envKey} </Tag>
                              </Tooltip>
                              {keyData.old ? dataOld() : null}
                              {keyData.new ? dataNew() : null}
                            </li>
                          );
                        });
                      }
                      const newVal = v.data.diff[key] === true ? 'true' : v.data.diff[key] === false ? 'false' : JSON.stringify(v.data.diff[key]);
                      return (
                        <li key={key}>
                          <InfoUpdate keyElement={key} valueElement={newVal} />
                        </li>
                      );
                    });
                  };

                  return (
                    <Space direction="vertical" style={spaceWidth}>
                      <Text>
                        <Text strong> Username: </Text> {v.userName || `Anonymous ${PROJECT_NAME} User`}
                      </Text>
                      <Text />
                      {userLogs()}
                    </Space>
                  );
                };

                return (
                  <Panel
                    key={key}
                    header={v.name.replace(/([.*+?^="!:${}()|\[\]\/\\])/g, '')}
                    extra={formattedDate}
                    style={{ ...autoOverflow, wordBreak: 'break-all' }}
                  >
                    {panelData()}
                  </Panel>
                );
              },
            )}
          </Collapse>
        </Col>
      </Skeleton>
    </Space>
  );
}
