import { iProject } from '../../../interface/project';
import { Card, Col, Row, Input, Tooltip, Form, Button, Skeleton, Dropdown, Space, Menu, message, FormItemProps, Drawer } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  ArrowRightOutlined,
  CheckOutlined,
  DiffOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  StarOutlined,
  StopOutlined,
} from '@ant-design/icons/lib';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable, { ActionType, ProColumns } from '@ant-design/pro-table';

interface IProjectVersions {
  project: iProjectModel;
}

import { createNewVersion, deleteVersion, projectVersionsList, updateVersion } from '../../../services/qraphqlVesions.service';

import { iProjectModel } from 'shared/deployment';
import { ProTableApi } from 'components/SharedComponents/ProTableApi/ProTableApi';
import { ProjectCiCd } from '../ProjectCiCd/ProjectCiCd';

interface CustomColumn extends ProColumns<any> {
  render?: (dom: any, entity: any) => JSX.Element;
}

export const ProjectVersions = (props: IProjectVersions) => {
  const projectId = props.project.id;

  const [open, setOpen] = useState(false);

  const opt = {
    primaryKey: 'id',
    columns: [
      {
        title: 'Id',
        dataIndex: 'id',
        tip: 'Id is the unique key',
        hideInForm: true,
      },
      {
        tip: 'Dev channel contain dev builds, Prod channel contain protected code (for PHP it is IonCube)',
        title: 'Channel',
        dataIndex: 'channel',
        valueType: 'text',
        new: {},
      },
      {
        tip: 'Name of the build (often it is git brunch name), if it is `latest` then this automatically updated version.',
        title: 'Name',
        dataIndex: 'name',
        valueType: 'text',
        new: {},
      },
      {
        tip: 'Number of the build (if 0 then this latest version. It is automatically updated version)',
        title: 'Build',
        dataIndex: 'build',
        valueType: 'text',
        new: {},
      },
      {
        tip: 'Commit title',
        title: 'Title',
        dataIndex: 'title',
        valueType: 'text',
        new: {},
      },
      {
        tip: 'Commit description',
        title: 'Description',
        dataIndex: 'description',
        valueType: 'textarea',
        new: {},
      },
      {
        tip: 'If true then users in cloud dushboard can select this version.',
        title: 'Available',
        dataIndex: 'isAvailable',
        valueType: 'boolean',
        new: {},
      },
      {
        title: 'Images',
        dataIndex: 'images',
        valueType: 'map',
        new: {},
        hideInTable: true,
        tableStyle: 'tdIntableFull',
      },
      {
        title: 'updatedAt',
        sorter: true,
        dataIndex: 'updatedAt',
        valueType: 'dateTime',
        hideInTable: true,
      },
      {
        title: 'createdAt',
        sorter: true,
        dataIndex: 'createdAt',
        valueType: 'dateTime',
      },
    ],
    canCreate: true,
    canUpdate: true,
    canDelete: true,
    isAllow: true,
    actions: [
      {
        name: 'Delete',
        isSingle: true,
        icon: <StopOutlined style={{ color: '#8b5b00' }} />,
        callback: async (row: any, opt: any) => {
          console.log(row, opt);
        },
      },
    ],
  };

  const getData = async (where: any) => {
    delete where['current'];
    delete where['pageSize'];
    const result = await projectVersionsList(projectId, where);
    return {
      data: result.data.GraphqlVersionsController_projectVersionsList,
      success: true,
      total: result.data.GraphqlVersionsController_projectVersionsList.length,
    };
  };

  const Add = async (data: object) => {
    const arrayOfObjects = data['images'];
    const settingImages = {};
    arrayOfObjects.forEach(obj => {
      settingImages[obj.key] = obj.val;
    });
    data['images'] = { images: settingImages };
    await createNewVersion(projectId, data);
  };

  const Update = async (data: any) => {
    const arrayOfObjects = data['images'];
    const settingImages = {};
    arrayOfObjects.forEach(obj => {
      settingImages[obj.key] = obj.val;
    });
    data['images'] = settingImages;
    debugger; // check `data.id` value
    await updateVersion(data.id, data);
  };

  return (
    <>
      <ProTableApi
        opt={opt}
        onAction={async (type: string, data: any) => {
          await deleteVersion(data?.[0].id);
        }}
        getData={getData}
        Add={Add}
        Update={Update}
        extraButtons={[
          <Button key="button" type="default" onClick={e => setOpen(true)}>
            <PlusOutlined /> CI/CD integration
          </Button>,
        ]}
      />
      <Drawer title="CI/CD Integration" open={open} onClose={e => setOpen(false)} closable={true} width={800}>
        <ProjectCiCd project={props.project} />
      </Drawer>
    </>
  );
};
