/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { ModalForm, ProFormCheckbox, ProFormDigit, ProFormSelect, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import ProDescriptions from '@ant-design/pro-descriptions';
import { Button, message, Drawer, Modal, Form, Space, Input, Dropdown, Menu, Typography } from 'antd';
import { FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { CheckOutlined, EditOutlined, ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined, StarOutlined } from '@ant-design/icons';
import { StopOutlined } from '@ant-design/icons';
import { buttonWidth, flexDisplay } from 'utils/styles';

export type CRUDTableProps = {
  columns: any[];
  actions?: any[];
  canCreate?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  crud: any;
  primaryKey: string;
};

const styles: any = {};

const { Title, Text } = Typography;
const { Item, List } = Form;

export const inputMap = (attr, currentRow) => (
  <div key={attr.name}>
    <p> {attr.label} </p>
    <List name={attr.name} initialValue={Object.keys(currentRow[attr.name]).map(k => ({ name: k, value: currentRow[attr.name][k] }))}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <Space key={key} align="baseline" style={{ ...flexDisplay, marginBottom: 8 }}>
              {['name', 'value'].map(field => (
                <Item
                  key={field}
                  {...restField}
                  name={[name, field]}
                  fieldKey={[fieldKey, field]}
                  rules={[{ required: true, message: `Missing ${field}` }]}
                >
                  <Input placeholder={field === 'name' ? 'Enter Variable Name Here' : 'Enter Value Here'} />
                </Item>
              ))}
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add variable
            </Button>
          </Item>
        </>
      )}
    </List>
  </div>
);

export const CRUDTable: React.FC<CRUDTableProps> = (props: CRUDTableProps) => {
  /** Pop-up window for new window*/
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  /** The pop-up window of the distribution update window */
  const [updateModalVisible, handleUpdateModalVisible] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>();
  const [selectedRowsState, setSelectedRows] = useState<any[]>([]);

  const actionRef = useRef<ActionType>();

  const history = useHistory();
  /** Add @param fields */

  const handleAdd = async fields => {
    const hide = message.loading('Adding');
    try {
      await props.crud.addObj({ ...fields });
      hide();
      message.success('Added successfully');
      return true;
    } catch (error) {
      hide();
      error && error.data && error.data.message
        ? message.error(error.data.message)
        : message.error('Failed to add, please try again!' + `\n${JSON.stringify(error)}`);
      return false;
    }
  };

  /**
   * @param fields
   */
  const handleUpdate = async (fields, rowId: number) => {
    const hide = message.loading('Configuring');
    try {
      await props.crud.updateObj(rowId, fields);
      hide();
      message.success('Configuration is successful');
      return true;
    } catch (error) {
      hide();
      message.error('Configuration failed, please try again!');
      return false;
    }
  };

  /**
   * @param selectedRows
   */
  const handleAction = async (action, selectedRows: any[]) => {
    if (!selectedRows) return true;
    const opt = { history };
    try {
      selectedRows.map(async row => {
        const hide = message.loading('Action in progress..', 0);
        action.callback ? await action.callback(row, opt) : await props.crud.actionObj(action, row.id, opt);
        hide();
      });
      return true;
    } catch (error) {
      message.error('Action failed, please try again');
      return false;
    }
  };

  /**
   * @param selectedRows
   */
  const handleRemove = async (selectedRows: any[]) => {
    const hide = message.loading('deleting');
    if (!selectedRows) return true;
    try {
      selectedRows.map(row => props.crud.removeObj(row.id));
      hide();
      message.success('Deleted successfully and will be refreshed soon');
      return true;
    } catch (error) {
      hide();
      message.error('Deletion failed, please try again');
      return false;
    }
  };

  const columns: ProColumns<any>[] = props.columns.map(column => {
    if (!column.render && (column.valueType === 'ProFormCheckbox' || column.valueType === 'boolean')) {
      column.render = (entity: { [x: string]: any }) =>
        entity[column.dataIndex] ? <CheckOutlined style={{ color: '#1F6D00' }} /> : <StopOutlined style={{ color: '#8B5B00' }} />;
    }

    if (column.valueType === 'map' && !column.render) {
      column.normalize = (value: any[]) => (!value ? value : value.reduce((obj, element) => ({ ...obj, [element.name]: element.value }), {}));

      column.render = (entity: { [x: string]: any }) => {
        let resVal = entity[column.dataIndex];
        if (typeof resVal == 'string') {
          try {
            resVal = JSON.parse(resVal);
          } catch (e) {
            return resVal;
          }
        }

        if (Array.isArray(resVal)) {
          return (
            <ul>
              {resVal.map((key, index) => (
                <li key={index}> {key} </li>
              ))}
            </ul>
          );
        }

        if (typeof resVal != 'object') return JSON.stringify(resVal);

        return (
          <table>
            <tbody>
              {Object.keys(resVal).map(key => (
                <tr key={key}>
                  <td className={styles.tdIntable} title={key}>
                    {key}
                  </td>
                  <td className={styles[column.tableStyle || 'tdIntable']} title={resVal[key]}>
                    {resVal[key]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      };
    }

    if (column.valueType === 'link' && !column.render) {
      column.render = entity => {
        const url = entity[column.dataIndex]
          ? /^http/.test(entity[column.dataIndex])
            ? entity[column.dataIndex]
            : `http://${entity[column.dataIndex]}`
          : '';
        return entity[column.dataIndex] ? (
          <a href={url} className={styles.linkIntable} target="_blank">
            {entity[column.dataIndex]}
          </a>
        ) : (
          <Text italic>
            <StopOutlined style={{ color: '#8B5B00' }} /> empty
          </Text>
        );
      };
    }

    if (['text', 'textarea'].includes(column.valueType) && !column.render) {
      column.render = (entity: {
        [x: string]: string | number | boolean | React.ReactElement<string> | Iterable<React.ReactNode> | React.ReactPortal;
      }) => (
        <div className={styles.textIntable} style={column.styles}>
          {entity[column.dataIndex]}
        </div>
      );

      column.renderAll = (entity: {
        [x: string]: string | number | boolean | React.ReactElement<string> | Iterable<React.ReactNode> | React.ReactPortal;
      }) => <div> {entity[column.dataIndex]} </div>;
    }

    if (column.dataIndex === props.primaryKey) {
      column.render = (dom: string | number | boolean | React.ReactElement<string> | Iterable<React.ReactNode> | React.ReactPortal, entity: any) => {
        let menuNotEmpty = props.canUpdate;
        const handleEditClick = () => {
          setCurrentRow(entity);
          setShowDetail(false);
          handleUpdateModalVisible(true);
          handleModalVisible(false);
        };
        const menu = (
          <Menu>
            {props.canUpdate ? (
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={handleEditClick}>
                Edit
              </Menu.Item>
            ) : null}
            {props.actions
              ? props.actions
                  .filter(action => (action.isAllow ? action.isAllow(entity) : true))
                  .map((action, index) => {
                    menuNotEmpty = true;
                    const btnProps: any = {
                      className: action.className || undefined,
                      style: action.style || undefined,
                      icon: action.icon || <StarOutlined />,
                    };
                    const handleActionClick = async () => await handleAction(action, [entity]);
                    return (
                      <Menu.Item key={index} {...btnProps} onClick={handleActionClick}>
                        {action.name}
                      </Menu.Item>
                    );
                  })
              : null}
          </Menu>
        );

        let dropdownProps: any = {};
        if (menuNotEmpty) dropdownProps.overlay = menu;
        const handleButtonClick = () => {
          setCurrentRow(entity);
          setShowDetail(true);
          handleUpdateModalVisible(false);
          handleModalVisible(false);
        };
        return (
          <Space wrap>
            <Dropdown.Button {...dropdownProps} onClick={handleButtonClick}>
              {dom}
            </Dropdown.Button>
          </Space>
        );
      };
    }
    return column;
  });

  const editForm = currentRow => {
    if (!currentRow || !currentRow[props.primaryKey]) return <Text> No Data </Text>;
    const form = columns
      .filter((column: any) => column.new != undefined)
      .map((column: any) => {
        let val = currentRow[column.dataIndex];
        const attr: any = {
          initialValue: val,
          key: `${currentRow.id}-${column.dataIndex}`,
          label: column.title,
          name: column.dataIndex,
          width: 'lg',
          rules: [{ required: column.new.required, message: `${column.title} is required` }],
        };

        const inputMapInitialValue =
          Array.isArray(val) || typeof val !== 'object' || !val
            ? ((val = {}), (currentRow[column.dataIndex] = {}), [])
            : Object.keys(val).map(k => ({ name: k, value: val[k] }));

        return column.valueType === 'ProFormTextArea' || column.valueType === 'textarea' ? (
          <ProFormTextArea {...attr} />
        ) : ['ProFormText', 'text', 'json', 'link', 'password'].includes(column.valueType) ? (
          <ProFormText {...attr} />
        ) : column.valueType === 'password' ? (
          <ProFormText.Password {...attr} />
        ) : column.valueType === 'map' ? (
          inputMap({ ...attr, initialValue: inputMapInitialValue }, currentRow)
        ) : column.valueType === 'ProFormSelect' || column.valueType === 'select' ? (
          <ProFormSelect
            {...{
              ...attr,
              valueEnum: column.valueEnum || undefined,
              options: column.valueEnum ? Object.keys(column.valueEnum).map(k => ({ value: k, label: column.valueEnum[k] })) : undefined,
              request: column.request || undefined,
              showSearch: true,
              optionFilterProp: !column.filterOption ? 'textName' : undefined,
              filterOption: !column.filterOption
                ? (input: string, option: { children: string }) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                : undefined,
            }}
          />
        ) : column.valueType === 'ProFormDigit' || column.valueType === 'number' ? (
          <ProFormDigit {...attr} />
        ) : column.valueType === 'ProFormCheckbox' || column.valueType === 'boolean' ? (
          <>
            {(attr.options = ['true', 'false'])}
            <ProFormCheckbox {...attr} />
          </>
        ) : null;
      });

    const handleFormOnFinish = async (value: { [x: string]: any }) => {
      columns.map((column: any) => (column.normalize ? (value[column.dataIndex] = column.normalize(value[column.dataIndex])) : null));
      await handleUpdate(value, currentRow[props.primaryKey]);
      handleUpdateModalVisible(false);
      setCurrentRow({});
      actionRef.current ? actionRef.current.reload() : null;
    };

    return (
      <>
        <Title level={1}>
          {props.primaryKey} - {currentRow[props.primaryKey]}
        </Title>
        <Form onFinish={handleFormOnFinish}>
          {form}
          <Button type="primary" htmlType="submit" style={buttonWidth}>
            Save
          </Button>
        </Form>
      </>
    );
  };

  let newBtn = (
    <Button type="primary" key="primary" onClick={() => handleModalVisible(true)}>
      <PlusOutlined /> New
    </Button>
  );

  if (!props.canCreate) newBtn = <></>;

  const title = Object.keys(props.crud.getFilters).map(key => (
    <span style={{ paddingLeft: '10px' }} key={key}>
      {key} - {props.crud.getFilters[key]}
    </span>
  ));

  const proTableData = () => (
    <ProTable
      headerTitle={title}
      actionRef={actionRef}
      rowKey="id"
      search={{ labelWidth: 120 }}
      toolBarRender={() => [newBtn]}
      request={props.crud.getObj}
      columns={columns}
      rowSelection={{ onChange: (_, selectedRows) => setSelectedRows(selectedRows) }}
    />
  );

  const footerToolbarData = () => {
    const footerToolbarExtra = (
      <div>
        chosen <a style={{ fontWeight: 600 }}> {selectedRowsState.length} </a> item
      </div>
    );

    const handleFooterToolbarClick = async () => {
      const handleOnOk = async () => {
        await handleRemove(selectedRowsState);
        setSelectedRows([]);
        actionRef.current?.reloadAndRest?.();
      };
      Modal.confirm({
        title: `Do you want to delete ${selectedRowsState.length} rows?`,
        icon: <ExclamationCircleOutlined />,
        content: `You will delete ${selectedRowsState.length} rows!`,
        onOk: handleOnOk,
      });
    };

    const footerToolbarFilter = (action: { isAllow: (arg0: string) => any; isSingle: any }) => {
      action.isAllow
        ? selectedRowsState.length === 1 && action.isAllow(selectedRowsState[0])
        : !action.isSingle || (action.isSingle && selectedRowsState.length === 1);
    };

    const footerToolbarMap = (action, index) => {
      const btnProps: any = {
        icon: action.icon || <StarOutlined />,
        className: action.className || undefined,
        style: action.style || undefined,
      };
      return (
        <Button key={index} {...btnProps} onClick={async () => await handleAction(action, selectedRowsState)}>
          {action.name}
        </Button>
      );
    };

    return (
      selectedRowsState?.length > 0 && (
        <FooterToolbar extra={footerToolbarExtra}>
          {props.canDelete ? <Button onClick={handleFooterToolbarClick}> Batch deletion </Button> : null}
          {props.actions ? props.actions.filter(footerToolbarFilter).map(footerToolbarMap) : null}
        </FooterToolbar>
      )
    );
  };

  const modalFormData = () => {
    const handleOnFinish = async value => {
      (await handleAdd(value)) && actionRef.current ? actionRef.current.reload() : null;
      handleModalVisible(false);
      setCurrentRow({});
    };

    const modalFormFilter = (column: { new: any }) => column.new != undefined;

    const modalFormMap = column => {
      const attr: any = {
        key: column.dataIndex,
        label: column.title,
        name: column.dataIndex,
        width: 'md',
        rules: [{ required: column.ne, message: `${column.title} is required` }],
      };
      return column.valueType === 'ProFormTextArea' || column.valueType === 'textarea' ? (
        <ProFormTextArea {...attr} />
      ) : ['ProFormText', 'text', 'json', 'link'].includes(column.valueType) ? (
        <ProFormText {...attr} />
      ) : column.valueType === 'password' ? (
        <ProFormText.Password {...attr} />
      ) : column.valueType === 'ProFormDigit' || column.valueType === 'number' ? (
        <ProFormDigit {...attr} />
      ) : column.valueType === 'ProFormSelect' || column.valueType === 'select' ? (
        <>
          {column.valueEnum && (attr.valueEnum = column.valueEnum)}
          {column.request && (attr.request = column.request)}
          <ProFormSelect {...attr} />
        </>
      ) : column.valueType === 'ProFormCheckbox' || column.valueType === 'boolean' ? (
        <>
          {(attr.options = ['true', 'false'])}
          <ProFormCheckbox {...attr} />
        </>
      ) : null;
    };

    return (
      <ModalForm title={'New item'} width="400px" open={createModalVisible} onVisibleChange={handleModalVisible} onFinish={handleOnFinish}>
        {props.columns.filter(modalFormFilter).map(modalFormMap)}
      </ModalForm>
    );
  };

  const modalData = () => {
    const modalOnOk = () => {
      handleUpdateModalVisible(false);
      setCurrentRow({});
    };

    const modalOnCancel = () => {
      handleUpdateModalVisible(false);
      setCurrentRow({});
    };

    return (
      <Modal
        title={'Edit item'}
        width="400px"
        destroyOnClose={true}
        open={updateModalVisible}
        onOk={modalOnOk}
        onCancel={modalOnCancel}
        footer={null}
        closable={true}
      >
        {editForm(currentRow)}
      </Modal>
    );
  };

  const drawerData = () => {
    const proData = () => {
      const drawerTitle = currentRow?.email || currentRow?.name;
      const drawerRequest = async () => {
        const data = {};
        Object.keys(currentRow).forEach(
          k => (data[k] = Array.isArray(currentRow[k]) || typeof currentRow[k] === 'object' ? JSON.stringify(currentRow[k]) : currentRow[k]),
        );
        return { data };
      };
      const drawerColumns = columns.map((c: any) => ({ ...c, render: c.renderAll ? c.renderAll : c.render }));
      return <ProDescriptions column={1} title={drawerTitle} request={drawerRequest} params={{ id: currentRow?.id }} columns={drawerColumns} />;
    };

    const handleDrawerOnClose = () => {
      setCurrentRow(undefined);
      setShowDetail(false);
    };

    return (
      <Drawer width={600} open={showDetail} onClose={handleDrawerOnClose} closable={false}>
        {currentRow?.id && proData()}
      </Drawer>
    );
  };

  return (
    <>
      {proTableData()}
      {footerToolbarData()}
      {modalFormData()}
      {modalData()}
      {drawerData()}
    </>
  );
};
