import LogsBoard from './LogsBoard/LogsBoard';
import { ReactElement } from 'react';
import { TitleUIRow } from 'layout/TitleUI';
import { Alert, Space } from 'antd';
import { spaceWidth } from 'utils/styles';

export const ProjectPage = (props: { project: any }): ReactElement => {
  const project = props.project;

  return (
    <Space direction="vertical" style={spaceWidth}>
      <TitleUIRow title={`Template ${props.project?.name || ''} - activity`} />
      <Alert type="info" showIcon message={`Here you can see the logs of your template.`} />
      <LogsBoard projectId={project.id} />
    </Space>
  );
};
