import * as yaml from 'js-yaml';

export const YamlSplitRegExp = new RegExp(/[\n\r]+---[\n\r]+/gim);
export const prepareToYamlSplit = (content: string): string => {
  return `\n${content}\n`;
};

export const stringToYaml = (content: string): any => {
  try {
    return prepareToYamlSplit(content)
      .split(YamlSplitRegExp)
      .map(part => {
        return yaml.load(part, { json: true });
      })
      .filter(v => !!v);
  } catch (error) {
    console.error(error);
    return { error };
  }
};

export interface SpecsRenderError {
  type: 'error' | 'warning' | 'info';
  error: any;
  message: string;
  groups: string[];
  kind?: string;
  name?: string;
}

export interface SpecsRenderResult {
  specs: any[];
  errors: SpecsRenderError[];
}
