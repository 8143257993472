import gql from 'graphql-tag';
import { Console } from 'console-feed';
import { useEffect, useState } from 'react';
import { iJobModel } from 'shared/deployment';
import { useAuthedQuery } from 'utils/qlAuth';
import { ApplyJobButton } from './ApplyJobButton';
import { DestroyJobButton } from './DestroyJobButton';
import { Alert, Button, Modal, Skeleton, Space, Typography } from 'antd';
import { logsStyle, logsText } from 'utils/styles';

const { Text } = Typography;

interface iShowJobLogsBtnProps {
  job: iJobModel;
  title?: string;
  type?: any | 'default' | 'primary' | 'dashed' | 'link' | undefined;
}

export const ShowJobLogsBtn: React.FC<iShowJobLogsBtnProps> = (props: iShowJobLogsBtnProps) => {
  const [open, setOpen] = useState(false);

  let ApplyJobButtonUI = null;
  if (props.job.status === 'finished' && props.job?.tfMode === 'plan') {
    ApplyJobButtonUI = (
      <ApplyJobButton
        job={props.job}
        onApply={function (jobId: number): void {
          setOpen(false);
        }}
      />
    );
  }

  let DestroyJobButtonUI = null;
  if (props.job.status === 'finished' && props.job?.tfMode === 'destroy-plan') {
    DestroyJobButtonUI = (
      <DestroyJobButton
        job={props.job}
        onDestroy={function (jobId: number): void {
          setOpen(false);
        }}
      />
    );
  }

  const modalData = () => {
    const modalFooter = [
      <Button key="back" onClick={() => setOpen(false)}>
        Close
      </Button>,
      ApplyJobButtonUI,
      DestroyJobButtonUI,
    ];

    return (
      <Modal
        width={1000}
        open={open}
        title={`#${props.job.id} - ${props.job.name} task`}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={modalFooter}
      >
        <JobLogs job={props.job} />
      </Modal>
    );
  };

  return (
    <>
      {modalData()}
      <Button type={props.type || `default`} onClick={e => setOpen(true)}>
        {props?.title || `View logs`}
      </Button>
    </>
  );
};

interface JobLogsProps {
  job: iJobModel;
}
// Plan: 49 to add, 0 to change, 0 to destroy.

const JobLogs: React.FC<JobLogsProps> = ({ job }: JobLogsProps) => {
  const [logs, setLogs] = useState<any>(null);
  const [error, setError] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const query = useAuthedQuery(
    gql`
      query JobsController_getJobLogs($jobId: Int!) {
        JobsController_getJobLogs(jobId: $jobId) {
          logs
          error
          noUpdates
          job {
            status
            lastError
            finishTime
          }
        }
      }
    `,
    { skip: !Number(job?.id), variables: { jobId: Number(job?.id) } },
  );

  useEffect(() => {
    return query.stopPolling;
  }, []);

  useEffect(() => {
    if (query?.data?.JobsController_getJobLogs?.logs) {
      const podLogs = query?.data?.JobsController_getJobLogs?.logs;
      const pods = Object.keys(podLogs);
      if (pods.length === 0) {
        setLogs(null);
        setLoading(false);
      } else {
        const realLogs = podLogs[pods[0]]?.logs;
        const feedLogs = (realLogs?.split('\n') || [])
          .filter(log => log !== '')
          .map((log: string) => {
            return { method: 'info', data: [log] };
          });
        setLogs(feedLogs);
        setLoading(false);
      }
    }
    if (query?.data?.JobsController_getJobLogs?.error) {
      setError(query?.data?.JobsController_getJobLogs?.error);
      setLoading(false);
    }
    if (query?.data?.JobsController_getJobLogs?.noUpdates) {
      query.stopPolling();
    } else {
      query.startPolling(30000);
    }
  }, [query?.data?.JobsController_getJobLogs?.logs]);

  if (loading) return <Skeleton active={true} loading={true} />;

  const noUpdates = query?.data?.JobsController_getJobLogs?.noUpdates;
  const lastError = query?.data?.JobsController_getJobLogs?.job?.lastError;

  let joblastErrorUi = null;
  if (lastError) {
    joblastErrorUi = <Alert message={lastError} type="error" />;
  }

  if (!noUpdates && error && logs === null) {
    return (
      <Space direction="vertical">
        <Alert message={`Please wait, logs will come soon.`} description={error} type="warning" showIcon />
        {joblastErrorUi}
      </Space>
    );
  }

  let tfPlanSummary = null;
  if (job?.tfMode === 'plan' && logs) {
    // Plan: 49 to add, 0 to change, 0 to destroy.
    const tfPlanSummaryLine = logs.find((log: any) => log.data[0].includes('Plan:'));
    if (tfPlanSummaryLine) {
      tfPlanSummaryLine.method = 'warn';
      const tfPlanSummarySting = tfPlanSummaryLine?.data[0].replace(/^.*Plan/, 'Plan');
      tfPlanSummary = <Alert message={tfPlanSummarySting} type="info" showIcon />;
    }
  }

  let jobStatusUi = null;
  if (query?.data?.JobsController_getJobLogs?.job?.status === 'finished') {
    jobStatusUi = <Alert message={`Job finished at ${new Date(query?.data?.JobsController_getJobLogs?.job?.finishTime)}`} type="success" showIcon />;
  } else {
    jobStatusUi = <Text strong> {`Job status: ${query?.data?.JobsController_getJobLogs?.job?.status}`} </Text>;
  }
  return (
    <>
      <div style={logsStyle}>
        {error && <div className="job-error"> {error} </div>}
        {logs !== null && <Console logs={logs} variant="light" styles={{ ...logsText, maxHeight: '400px' }} />}
      </div>
      <Space direction="vertical">
        {tfPlanSummary}
        {jobStatusUi}
        {joblastErrorUi}
        {!noUpdates && <Button onClick={async () => await query.refetch()}> Update logs </Button>}
      </Space>
    </>
  );
};
