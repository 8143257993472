import history from '../history';
import { Router, Switch, Route } from 'react-router-dom';
import { routeConfigAdminLayout } from './RouteConfig';
import { RouteWithLayout } from './RouteWithLayout';
import NotFoundPage from 'components/SharedComponents/NotFound/NotFound';

export default function Routes() {
  return (
    <Router history={history}>
      <Switch>
        {routeConfigAdminLayout.map((route, i) => {
          if (route.layout) return <RouteWithLayout key={i} layout={route.layout} exact path={route.path} component={route.component} />;
          return <Route key={i} exact path={route.path} component={route.component} />;
        })}
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}
