import { CommonFormUI, CommonUI_DeploymentVarWidget, CommonUI_text } from './CommonFormUI';
import { Alert, Button, Dropdown, Space, Tabs, Typography } from 'antd';
import { DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { PatchYamlAction } from './utils/yaml-tools';

export interface iVolumesUIProps {
  yamlObj: any;
  onChange: (key: string, newObj: any, action: PatchYamlAction) => void;
}

interface iNewVolumesUIBtn {
  onChange: (arg0: string, arg1: string, arg2: string) => void;
}

interface iOneVolumesUIForm {
  indexInArray: number;
  onChange: any;
  volume: any;
  path: string;
}

const { Text } = Typography;

export const VolumesUI = (props: iVolumesUIProps) => {
  const tabsArr = [];

  props.yamlObj.spec?.template?.spec?.volumes?.forEach((current_volume, i) => {
    tabsArr.push({
      label: `${current_volume.name || 'container ' + i}`,
      key: `container-${i}`,
      children: <OneVolumesUIForm path={`.spec.template.spec.volumes`} indexInArray={i} volume={current_volume} onChange={props.onChange} />,
    });
  });

  const arrLabel = (
    <>
      New <PlusCircleOutlined />
    </>
  );

  tabsArr.push({
    label: arrLabel,
    key: `container-${props.yamlObj.spec?.template?.spec?.volumes?.length || '0'}`,
    children: <NewVolumesUIBtn onChange={props.onChange} />,
  });

  return <Tabs tabPosition={'top'} defaultActiveKey="1" items={tabsArr} />;
};

const NewVolumesUIBtn = (props: iNewVolumesUIBtn) => {
  const handleMenuOnClick = {
    onClick: (e: { key: string }) => {
      console.log(`NewVolumesUIBtn:event`, e);
      const newVolume: any =
        e.key === 'EmptyDir'
          ? { name: 'empty-dir', emptyDir: { sizeLimit: '500Mi' } }
          : e.key === 'ConfigMap'
          ? { name: 'my-conigs', configMap: { name: 'my-conigs' } }
          : e.key === 'PVC'
          ? { name: 'my-pvc', persistentVolumeClaim: { claimName: 'my-pvc' } }
          : e.key === 'Secret'
          ? { name: 'my-secret', secret: { secretName: 'my-secret' } }
          : { name: 'new-volume' };
      props.onChange('.spec.template.spec.volumes', newVolume, 'push');
    },
    items: [
      { label: 'Empty Dir', key: 'EmptyDir' },
      { label: 'Secret', key: 'Secret' },
      { label: 'Config Map', key: 'ConfigMap' },
      { label: 'Persistent Volume Claim', key: 'PVC' },
    ],
  };

  const alertData = () => {
    const alertMessage = (
      <Space direction="vertical">
        <Text> Kubernetes supports many types of volumes. </Text>
        <Text>
          A Pod can use any number of volume types simultaneously. Please see docs for &nbsp;
          <a href={`https://kubernetes.io/docs/concepts/storage/volumes/#volume-types`} target="_blank">
            Volume types
          </a>
        </Text>
      </Space>
    );
    return <Alert showIcon type="info" message={alertMessage} />;
  };

  return (
    <Space direction="vertical">
      <Dropdown menu={handleMenuOnClick}>
        <Button>
          <Space>
            Add volume
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {alertData()}
    </Space>
  );
};

const OneVolumesUIForm = (props: iOneVolumesUIForm) => {
  // https://kyndryl-mdmreport.nanoheal.app/main/custom/json_android.php?site=BFIL_Production_IBM
  let volumeType = null;
  let volumeHelpUI = null;

  const alertVolumeHelpUIA = () => {
    const descriptionA = (
      <Text>
        A ConfigMap provides a way to inject configuration data into pods. The data stored in a ConfigMap can be referenced in a volume of type
        configMap and then consumed by containerized applications running in a pod. When referencing a ConfigMap, you provide the name of the
        ConfigMap in the volume. You can customize the path to use for a specific entry in the ConfigMap.&nbsp;
        <a href={`https://kubernetes.io/docs/concepts/storage/volumes/#configmap`} target="_blank">
          configmap volume type
        </a>
      </Text>
    );
    return <Alert showIcon type="info" description={descriptionA} />;
  };

  const alertVolumeHelpUIB = () => {
    const descriptionB = (
      <Text>
        For a Pod that defines an emptyDir volume, the volume is created when the Pod is assigned to a node. As the name says, the emptyDir volume is
        initially empty. All containers in the Pod can read and write the same files in the emptyDir volume, though that volume can be mounted at the
        same or different paths in each container. When a Pod is removed from a node for any reason, the data in the emptyDir is deleted permanently.
        Please see docs for&nbsp;
        <a href={`https://kubernetes.io/docs/concepts/storage/volumes/#emptydir`} target="_blank">
          emptydir volume type
        </a>
      </Text>
    );
    return <Alert showIcon type="info" description={descriptionB} />;
  };

  /**
   * @todo - add checks for other types of names like `configMap` or `ConfigMap` or `configmap`
   * @todo - add auto complete for secrets names
   * @todo - add auto complete for ConfigMap names
   * @todo - add auto complete for pvc names */
  const form = [
    {
      name: 'Name (in mount path)',
      children: (
        <CommonUI_text value={props.volume.name} onChange={props.onChange} path={`${props.path}[${props.indexInArray}]`} propertyName={`name`} />
      ),
    },
  ];
  if (props?.volume?.configMap) {
    /** @todo - add checks for other types of names like `configMap` or `ConfigMap` or `configmap` */
    volumeType = `configMap`;
    /** @todo - add auto complete for ConfigMap names */
    form.push({
      name: 'ConfigMap name',
      children: (
        <CommonUI_DeploymentVarWidget
          value={props?.volume?.configMap?.name || 'my-config'}
          onChange={props.onChange}
          path={`${props.path}[${props.indexInArray}].configMap`}
          propertyName={`name`}
        />
      ),
    });
    volumeHelpUI = alertVolumeHelpUIA;
  } else if (props?.volume?.secret) {
    volumeType = `secret`;
    /** @todo - add auto complete for secrets names */
    form.push({
      name: 'Secret name',
      children: (
        <CommonUI_DeploymentVarWidget
          value={props?.volume?.secret?.secretName || 'my-secret'}
          onChange={props.onChange}
          path={`${props.path}[${props.indexInArray}].secret`}
          propertyName={`secretName`}
        />
      ),
    });
  } else if (props?.volume?.persistentVolumeClaim) {
    volumeType = `pvc`;
    /** @todo - add auto complete for pvc names */
    form.push({
      name: 'Claim name',
      children: (
        <CommonUI_DeploymentVarWidget
          value={props?.volume?.persistentVolumeClaim?.claimName || 'my-pvc'}
          onChange={props.onChange}
          path={`${props.path}[${props.indexInArray}].persistentVolumeClaim`}
          propertyName={`claimName`}
        />
      ),
    });
  } else if (props?.volume?.emptyDir) {
    volumeType = `emptyDir`;
    form.push({
      name: 'Size limit',
      children: (
        <CommonUI_DeploymentVarWidget
          value={props.volume?.emptyDir?.sizeLimit || '100Mi'}
          onChange={props.onChange}
          path={`${props.path}[${props.indexInArray}].emptyDir`}
          propertyName={`sizeLimit`}
        />
      ),
    });
    volumeHelpUI = alertVolumeHelpUIB;
  }

  return (
    <Space direction="vertical">
      <CommonFormUI form={form} />
      <Button type="dashed" danger onClick={() => props.onChange(props.path, props.indexInArray, `splice`)}>
        Un mount
      </Button>
      {volumeHelpUI}
    </Space>
  );
};
