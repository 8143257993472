import { ReactElement, useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { ProjectDeploymentContext } from './ProjectDeploymentContext';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { iProject } from 'interface/project';
import { ProjectSettingsRoutes } from './ProjectSettingsRoutes';
import { ProjectWidgetsRow } from './ProjectWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { iProjectModel } from 'shared/deployment';

/**
 * ProjectSetting tabs component
 * @returns
 */

export const ProjectSetting = (): ReactElement => {
  // @ts-ignore
  const params: any = useParams();
  const { projectId, tabName } = params;
  const [deploymentContext, setDeploymentContext] = useState({ deployment: null });

  const { loading, error, data, stopPolling, startPolling, refetch } = useAuthedQuery(
    gql`
      query ProjectController_getProject($projectId: Int!) {
        ProjectController_getProject(projectId: $projectId) {
          id
          uuid
          name
          title
          description
          logo
          tags
          tenantId
          isPublic
          gitInitialized
          allowCustomClusterRoles
          allowCustomProvisioner
          oneDeploymentPerCluster
          creationType
          hardcodedNameSpace
          notAllowRemoveNamespace
          showVerionsTab
          allowAutoApplyLimits
          showEnvVarsTab
          autoAddEnvVarsToDeployments
          gitBranch
        }
      }
    `,
    { variables: { projectId: Number(projectId) } },
  );
  const project: iProjectModel = data?.ProjectController_getProject;

  useEffect(() => {
    return stopPolling;
  }, []);
  if (project?.gitInitialized === false) startPolling(10000);
  if (loading || !project) return <Skeleton active={true} loading={true} />;

  return (
    <ProjectDeploymentContext.Provider value={deploymentContext}>
      <TitleUIRow title={`Project: ${project.name}`} />
      <ProjectWidgetsRow project={project} setDeploymentContext={setDeploymentContext} />
      <ProjectSettingsRoutes tabName={tabName} project={project} />
    </ProjectDeploymentContext.Provider>
  );
};
