import gql from 'graphql-tag';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAuthedQuery } from 'utils/qlAuth';
import { iRegionModel } from 'shared/deployment';
import { Alert, Button, Card, Col, Flex, Row, Skeleton, Space, Tag, Tooltip, Typography, Input, Result, Modal, message } from 'antd';
import { UnorderedListOutlined, AppstoreOutlined, CaretLeftFilled, DeleteOutlined, WarningFilled } from '@ant-design/icons';
import { bottomMargin, buttonBorder, clusterTag, clusterTags, internalStatusTag, loginTag, spaceWidth, topMargin } from 'utils/styles';

interface iNodesProps {
  region: iRegionModel;
}

const { Title, Text } = Typography;
const { Search } = Input;

export const ClusterNodes = (props: iNodesProps) => {
  const [isCompactView, setIsCompactView] = useState(false);
  const [activeButton, setActiveButton] = useState<'list' | 'card'>('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clusterToDelete, setClusterToDelete] = useState<{ regionId: number; nodeName: string } | null>(null);

  const qTopNodes = useAuthedQuery(
    gql`
      query ApiAgentController_getTopNodes($regionId: Int!) {
        ApiAgentController_getTopNodes(regionId: $regionId) {
          status
          message
          nodes {
            CPU
            Memory
            Node {
              metadata
              status
            }
          }
          noSyncTime
        }
      }
    `,
    { variables: { regionId: Number(props.region.id) } },
  );

  const DELETE_NODE_MUTATION = gql`
    mutation ApiAgentController_deleteNode($regionId: Int!, $nodeName: String!) {
      ApiAgentController_deleteNode(regionId: $regionId, nodeName: $nodeName) {
        status
        answer
      }
    }
  `;

  const [deleteNode] = useMutation(DELETE_NODE_MUTATION);

  const showDeleteConfirm = (regionId: number, nodeName: string) => {
    setClusterToDelete({ regionId, nodeName });
    setIsModalVisible(true);
  };

  const handleDeleteNode = async () => {
    if (clusterToDelete) {
      try {
        const { data } = await deleteNode({ variables: { regionId: clusterToDelete.regionId, nodeName: clusterToDelete.nodeName } });
        if (data?.ApiAgentController_deleteNode?.status === `success`) {
          message.success(`Cluster ${clusterToDelete.nodeName} deletion is successful`);
          setClusterToDelete(null);
          setIsModalVisible(false);
          qTopNodes.refetch();
        } else message.error(`Cluster ${clusterToDelete.nodeName} deletion failed: ${data?.ApiAgentController_deleteNode?.status}`);
      } catch (error) {
        message.error(`Error deleting cluster: ${error.message}`);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setClusterToDelete(null);
  };

  const nodes = qTopNodes.data?.ApiAgentController_getTopNodes.nodes;
  const agentError = qTopNodes?.data?.ApiAgentController_getTopNodes.message;

  if (agentError && qTopNodes?.data?.ApiAgentController_getTopNodes.status !== 'success')
    <Alert type="error" message={agentError} style={bottomMargin} />;

  const percent = (v1: number, v2: number) => Math.floor((v1 / v2) * 100);
  const toGi = (mem: number) => Math.floor((mem / 1024 / 1024 / 1024) * 10) / 10;
  const floor2 = (val: number) => Math.floor(val * 10) / 10;

  if (qTopNodes.loading) return <Skeleton active={true} loading={true} />;

  const toggleView = (view: 'list' | 'card') => {
    setIsCompactView(view === 'card');
    setActiveButton(view);
  };

  const filteredData = (nodes || []).filter((node: { Node: { metadata: { name: string } } }) =>
    node?.Node?.metadata?.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleSearch = (value: string) => setSearchQuery(value);

  const getMemoryColor = (percent: number) => (percent > 80 || percent <= 95 ? '#A9DFBF' : percent < 80 ? 'orange' : 'red');

  const clusterData = () => {
    if (filteredData.length === 0) {
      const noResults = (
        <Button type="primary" onClick={() => setSearchQuery('')} icon={<CaretLeftFilled />}>
          Back to Nodes
        </Button>
      );
      return <Result status="404" subTitle="This Node does not exist... Please search for appropriate Node" extra={noResults} style={topMargin} />;
    }

    return (
      <Row gutter={[16, 16]}>
        {filteredData.map((node, index) => {
          const labels = node?.Node?.metadata?.labels || {};
          const capacityType = labels[`eks.amazonaws.com/capacityType`];
          const instanceType = labels[`node.kubernetes.io/instance-type`] || labels[`beta.kubernetes.io/instance-type`];
          const name = node?.Node?.metadata?.name;
          const maxPods = node?.Node?.status?.allocatable?.pods;
          const maxStorage = toGi(node?.Node?.status?.allocatable['ephemeral-storage']);
          const zone = labels['topology.kubernetes.io/zone'];
          const region = labels['topology.kubernetes.io/region'];
          const arch = labels['kubernetes.io/arch'];
          const nodepool = labels['karpenter.sh/nodepool'];
          const memoryRequestPercent = percent(node.Memory.RequestTotal, node.Memory.Capacity);
          const memoryColor = getMemoryColor(memoryRequestPercent);

          const conditions = (
            <Space direction="horizontal" wrap>
              {node.Node.status.conditions.map((condition, conditionIndex: number) => (
                <Tag style={clusterTag} key={conditionIndex}>
                  {condition.message}
                </Tag>
              ))}
            </Space>
          );

          const cpuInfo = [
            { label: 'Maximum Pods', value: maxPods },
            { label: 'CPU Capacity', value: floor2(node.CPU.Capacity) },
            { label: 'CPU RequestTotal', value: floor2(node.CPU.RequestTotal), percent: percent(node.CPU.RequestTotal, node.CPU.Capacity) },
            { label: 'CPU LimitTotal', value: floor2(node.CPU.LimitTotal), percent: percent(node.CPU.LimitTotal, node.CPU.Capacity) },
          ];

          const memoryInfo = [
            { label: 'Maximum Storage', value: maxStorage },
            { label: 'Memory Capacity', value: `${toGi(node.Memory.Capacity)} Gi` },
            { label: 'Memory RequestTotal', value: `${toGi(node.Memory.RequestTotal)} Gi`, percent: memoryRequestPercent },
            {
              label: 'Memory LimitTotal',
              value: `${toGi(node.Memory.LimitTotal)} Gi`,
              percent: percent(node.Memory.LimitTotal, node.Memory.Capacity),
            },
          ];

          const newNodeInfo = [
            { label: 'Zone', value: zone },
            { label: 'Region', value: region },
            { label: 'Arch', value: arch },
            { label: 'Nodepool', value: nodepool },
          ];

          const ClusterNodes = ({ data }) => (
            <Space direction="horizontal" wrap>
              {data.map((info, index) => (
                <Tag key={index} style={{ ...clusterTags, backgroundColor: info.percent !== undefined ? memoryColor : 'inherit' }}>
                  {info.label} = {info.value}
                  {info.percent !== undefined && ` - ${info.percent}%`}
                </Tag>
              ))}
            </Space>
          );

          const CPU = <ClusterNodes data={cpuInfo} />;
          const Memory = <ClusterNodes data={memoryInfo} />;
          const newNode = <ClusterNodes data={newNodeInfo} />;

          const capacityTypeTag = capacityType && (
            <Tag color="#F1F8FE" style={{ ...internalStatusTag, ...loginTag }}>
              {capacityType}
            </Tag>
          );

          const instanceTypeTag = instanceType && (
            <Tag color="#F1F8FE" style={{ ...internalStatusTag, ...loginTag }}>
              {instanceType}
            </Tag>
          );

          const deleteClusterButton = () => {
            const handleOnClickDeleteButton = () => showDeleteConfirm(props.region.id, name);
            return <Button size="small" danger type="primary" icon={<DeleteOutlined />} onClick={handleOnClickDeleteButton} style={buttonBorder} />;
          };

          const nodeData = () => {
            const cardTitle = <Text strong> {name} </Text>;
            const cardExtra = (
              <>
                {capacityTypeTag}
                {instanceTypeTag}
                {deleteClusterButton()}
              </>
            );
            return (
              <Col span={isCompactView ? 12 : 24} key={index}>
                <Card size="small" bordered={false} title={cardTitle} extra={cardExtra}>
                  <Space direction="vertical" style={spaceWidth}>
                    {conditions}
                    <Space direction="vertical" wrap>
                      {CPU}
                      {Memory}
                      {newNode}
                    </Space>
                  </Space>
                </Card>
              </Col>
            );
          };

          return nodeData();
        })}
      </Row>
    );
  };

  const searchViews = () => (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <Search placeholder="Search" enterButton allowClear onChange={e => handleSearch(e.target.value)} />
      </Col>
      <Col span={14}>
        <Flex justify={'flex-end'}>
          <Space direction="horizontal">
            <Tooltip color="#115EA3" title="List View" placement="top">
              <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('list')} type={activeButton === 'list' ? 'primary' : 'default'} />
            </Tooltip>
            <Tooltip color="#115EA3" title="Grid View" placement="top">
              <Button icon={<AppstoreOutlined />} onClick={() => toggleView('card')} type={activeButton === 'card' ? 'primary' : 'default'} />
            </Tooltip>
          </Space>
        </Flex>
      </Col>
    </Row>
  );

  const confirmData = () => {
    const modalTitle = (
      <Title type="danger" level={5}>
        <WarningFilled /> &nbsp; Are you sure you want to delete this node?
      </Title>
    );

    const modalFooter = [
      <Button key="cancel" onClick={handleCancel}>
        Cancel
      </Button>,
      <Button key="delete" type="primary" danger onClick={handleDeleteNode} style={buttonBorder}>
        Delete
      </Button>,
    ];

    return (
      <Modal
        title={modalTitle}
        open={isModalVisible}
        onOk={handleDeleteNode}
        onCancel={handleCancel}
        footer={modalFooter}
        okText="Delete"
        cancelText="Cancel"
      >
        <Text> This action is irreversible, and deletion will lead to the loss of data. </Text>
      </Modal>
    );
  };

  return (
    <Space direction="vertical" style={spaceWidth}>
      {searchViews()}
      <Alert
        closable
        showIcon
        type="info"
        message={`Please search for Clusters based on last 2 or 3 digits in the IP.`}
        action={
          <Text strong type="secondary">
            {`Total of ${filteredData.length} Cluster integrations.`}
          </Text>
        }
      />
      {clusterData()}
      <Text />
      {confirmData()}
    </Space>
  );
};
