import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isTunnelError } from 'shared/TunnelErrors';
import { useAuthedQuery } from 'utils/qlAuth';
import { Popover, Skeleton, Space, Tag, Typography } from 'antd';
import { CheckCircleOutlined, ThunderboltOutlined, PoweroffOutlined, RocketOutlined, DeleteOutlined } from '@ant-design/icons';
import { WarningOutlined, SyncOutlined, FieldTimeOutlined, FireOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { blueColor, redColor } from 'utils/styles';

const { Text } = Typography;

export interface Pod {
  name: string;
  id: string;
}

interface iResourcesStatesProps {
  deploymentId: number;
  onPodsFetched: (pods: Pod[]) => void;
}

export interface iOneMetric {
  name: string;
  labels: any;
  memory: number;
  cpu: number;
  nodeName: string;
}

export const ResourcesStates = (props: iResourcesStatesProps) => {
  const { deploymentId, onPodsFetched } = props;

  const podsQuery = useAuthedQuery(
    gql`
      query DeploymentsController_getPodsMetrics($deploymentId: Int!) {
        DeploymentsController_getPodsMetrics(deploymentId: $deploymentId) {
          error
          metrics
        }
        DeploymentsController_getPodsStatus(deploymentId: $deploymentId) {
          error
          status
        }
      }
    `,
    { variables: { deploymentId: Number(deploymentId) } },
  );

  const loadingStatus = podsQuery?.loading;
  const errorStatus = podsQuery?.error;
  const newStatus = podsQuery?.data?.DeploymentsController_getPodsStatus?.status;
  const newMetrics = podsQuery?.data?.DeploymentsController_getPodsMetrics?.metrics;

  const [oldStatus, setOldStatus] = useState(null);
  const [oldMetrics, setOldMetrics] = useState(null);

  useEffect(() => {
    if (newStatus && !isTunnelError(newStatus?.error)) setOldStatus(newStatus);
    if (newMetrics && !isTunnelError(newMetrics?.error)) setOldMetrics(newMetrics);
    return podsQuery.stopPolling;
  }, [newStatus, newMetrics]);

  useEffect(() => {
    if (newStatus && Array.isArray(newStatus)) {
      const pods = newStatus.map((service: any) => ({ name: getServiceNameForUI(service), id: service.name }));
      onPodsFetched(pods);
    }
  }, [newStatus, onPodsFetched]);

  podsQuery.startPolling(5000);

  if (loadingStatus) return <Skeleton active={true} loading={true} />;
  if (!errorStatus && !newStatus) return <> No Pods found </>;
  if (errorStatus || !newStatus || newStatus.error === 401) return <> Error: {JSON.stringify(errorStatus || newStatus?.error)} </>;

  let metricsObj = isTunnelError(newMetrics?.error) ? oldMetrics || <Skeleton active={true} loading={true} /> : newMetrics;

  const metricsKey = Object.keys(metricsObj)[0];
  const metrics: iOneMetric[] = metricsObj[metricsKey];

  let status = newStatus;
  if (isTunnelError(newStatus?.error)) {
    status = oldStatus
      ? oldStatus
      : (() => {
          console.error('Tunnel error', newStatus?.error);
          return <Skeleton active={true} loading={true} />;
        })();
  }

  const metricsByPodName = (podName: string): iOneMetric => {
    if (!metrics || metrics?.length === 0 || Array.isArray(metrics) === false) return null;
    return (metrics || []).find(m => m?.name === podName);
  };

  if (!status) return null;

  if (status?.ErrorBody || Array.isArray(status) === false)
    return <Text type="danger"> {status?.ErrorBody?.message || status?.message || JSON.stringify(status)} </Text>;

  return (
    <Space direction="vertical">
      {status &&
        status.map((service: any, index: any) => {
          let checkError = getCheckError(service);
          // console.log(service.name, checkError);
          const podName = service.name;
          const nodeName = service?.nodeName || ``;
          const usage: iOneMetric = metricsByPodName(podName);

          const content = () => {
            const last = service.last_condition;
            return (
              <Space direction="vertical">
                <Text strong> {getTitleHelp(checkError)} </Text>
                <Text italic> Node {nodeName} </Text>
                <p style={{ maxWidth: '300px' }}>
                  {last.lastTransitionTime ? (
                    <ul>
                      {last.lastTransitionTime ? <li> Last transition time: {last.lastTransitionTime} </li> : ''}
                      {last.message ? <li> Message: {last.message} </li> : ''}
                      {last.reason ? <li> Reason: {last.reason} </li> : ''}
                      {last.status ? <li> Status: {last.status} </li> : ''}
                      {last.type ? <li> Type: {last.type} </li> : ''}
                    </ul>
                  ) : (
                    ''
                  )}
                </p>
              </Space>
            );
          };

          return (
            <Space>
              <Tag key={service.name} icon={serviceToIcon(service)}>
                <span style={{ minWidth: '90px' }}>
                  <Popover placement="left" content={content} title={`Phase: ${checkError}`}>
                    <Link to={`/app/${deploymentId}/logs/pods_${service.name}`}> {getServiceNameForUI(service)} </Link>
                  </Popover>
                </span>
              </Tag>
              {usage && <Text> CPU usage {Math.floor(usage?.cpu)}m </Text>}
              {usage && <Text> Memory usage {Math.floor(usage?.memory * 100) / 100}Mi </Text>}
            </Space>
          );
        })}
    </Space>
  );
};

const getCheckError = (service: any): string | undefined => {
  // let stateError = null;
  // if (service.status_containerStatuses.state && service.status_containerStatuses.state[0].reason)
  //   stateError = `${service.status_containerStatuses.state[0].reason} - ${service.status_containerStatuses.state[0].message}`;

  if (!service) return 'Unknown';

  const wasTerminated = service?.status_containerStatuses?.state?.find((v: any) => v.stateType === 'terminated');
  const age = (new Date().getTime() - new Date(service.status_startTime).getTime()) / 1000;

  const status = service?.last_condition?.reason || service?.status_phase || service?.last_condition?.message;
  let checkError = status;

  checkError =
    wasTerminated && status === 'ContainersNotReady'
      ? 'CrashLoopBackOff'
      : wasTerminated && wasTerminated.reason === 'ContainerStatusUnknown'
      ? 'ContainerStatusUnknown'
      : age < 180 && status === 'ContainersNotReady'
      ? 'ContainerCreating'
      : service?.status_conditions?.length === 0 && status === 'Failed' && service?.last_condition?.lastTransitionTime === 0
      ? 'Evicted'
      : checkError;

  return checkError;
};

export const getTitleHelp = (service: any): string => {
  if (!service) return 'No data';
  const checkError = getCheckError(service);
  const titleMap = {
    Running: `${checkError} - It is working well`,
    'Init:CrashLoopBackOff': `${checkError} - It won't work, try to fix it. It will not work until you fix it. (check Init container`,
    CrashLoopBackOff: `${checkError} - It won't work, try to fix it. It will not work until you fix it.`,
    ContainerCreating: `${checkError} - Just wait some time, it is okay`,
    ContainersNotInitialized: `${checkError} - Just wait some time, it is okay`,
    Terminating: `${checkError} - Just wait some time, it is okay`,
    'Init:Error': `${checkError} - It is an error (check Init container)`,
    Error: `${checkError} - It is an error`,
    Pending: `${checkError} - Just wait some time, try to fix if this status do not changes more than 5 minutes`,
    ContainersNotReady: `${checkError} - Just wait some time, try to fix if this status do not changes more than 5 minutes`,
    Succeeded: `${checkError} - It is okay`,
    PodCompleted: `${checkError} - It is okay`,
    Unschedulable: `${checkError} - It is an error`,
    PodFailed: `${checkError} - It is an error`,
    ContainerStatusUnknown: `${checkError} - It is not critical if we have at least one green pod for this service`,
    Evicted: `${checkError} - Evicted/Failed`,
  };
  return titleMap[checkError] || (checkError === undefined ? 'No errors' : checkError);
};

export const serviceToIcon = (service: any) => {
  if (!service) return undefined;

  const checkError = getCheckError(service);
  const serviceToIconTitle = {
    title: getTitleHelp(service),
  };

  const runningIcon = <CheckCircleOutlined {...serviceToIconTitle} style={{ color: '#00A58E' }} />;
  const initIcon = <ThunderboltOutlined {...serviceToIconTitle} style={redColor} />;
  const evictedIcon = <ThunderboltOutlined {...serviceToIconTitle} style={{ color: '#808080' }} />;
  const unknownIcon = <PoweroffOutlined {...serviceToIconTitle} style={{ color: '#808080' }} />;
  const crashIcon = <ThunderboltOutlined {...serviceToIconTitle} style={redColor} />;
  const createIcon = <RocketOutlined {...serviceToIconTitle} style={blueColor} />;
  const terminateIcon = <DeleteOutlined {...serviceToIconTitle} style={blueColor} />;
  const errorsIcon = <WarningOutlined {...serviceToIconTitle} style={redColor} />;
  const errorIcon = <WarningOutlined {...serviceToIconTitle} style={redColor} />;
  const notReadyIcon = <SyncOutlined {...serviceToIconTitle} spin={true} style={blueColor} />;
  const successIcon = <FieldTimeOutlined {...serviceToIconTitle} style={{ color: '#00A58E' }} />;
  const unscheduledIcon = <FireOutlined {...serviceToIconTitle} style={{ color: '#FF7300' }} />;
  const closeIcon = <CloseCircleOutlined {...serviceToIconTitle} style={redColor} />;

  return (
    <>
      {checkError === 'Running'
        ? runningIcon
        : checkError === 'Init:CrashLoopBackOff'
        ? initIcon
        : checkError === 'Evicted'
        ? evictedIcon
        : checkError === 'ContainerStatusUnknown'
        ? unknownIcon
        : checkError === 'CrashLoopBackOff'
        ? crashIcon
        : checkError === 'ContainerCreating' || checkError === 'ContainersNotInitialized'
        ? createIcon
        : checkError === 'Terminating'
        ? terminateIcon
        : checkError === 'Init:Error'
        ? errorsIcon
        : checkError === 'Error'
        ? errorIcon
        : checkError === 'Pending' || checkError === 'ContainersNotReady'
        ? notReadyIcon
        : checkError === 'Succeeded' || checkError === 'PodCompleted'
        ? successIcon
        : checkError === 'Unschedulable'
        ? unscheduledIcon
        : closeIcon}
    </>
  );
};

export const getServiceNameForUI = (service: any): string => {
  if (!service) return '';
  const labels = service?.labels || {};
  const keys = ['ui-name', 'name', 'app'];
  for (const key of keys) if (labels[key]) return labels[key];

  return service?.name || 'no-name';
};
