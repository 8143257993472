import Form from '@rjsf/antd';
import validator from '@rjsf/validator-ajv8';
import { CustomWidgets } from 'form-components';
import { CustomFieldTemplate } from 'form-components/ObjectFieldTemplate';
import { SubmitButtonProps, getSubmitButtonOptions } from '@rjsf/utils';
import { ProjectDeploymentSyncVarsContext, iProjectDeploymentSyncVarsContext } from '../ProjectDeploymentContext';
import { Button, Modal, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { buttonWidth } from 'utils/styles';

export interface iJsonFormModalUIProps {
  onChange: (value: any) => void;
  onCancel?: () => void;
  formData: any;
  formConfigs: { ui: any; schema: any };
  title?: string;
  help?: any;
  SyncVarsContext?: iProjectDeploymentSyncVarsContext;
}

export const JsonFormModalUI = (props: iJsonFormModalUIProps) => {
  let instance = null;

  const handleSubmit = async e => {
    instance.destroy();
    props.onChange(e.formData);
  };

  const SubmitButton = (props: SubmitButtonProps) => {
    const { uiSchema } = props;
    const { norender } = getSubmitButtonOptions(uiSchema);
    return !norender ? (
      <Space direction="horizontal">
        <Button key="back" onClick={() => instance.destroy()} style={buttonWidth}>
          Cancel
        </Button>
        <Button htmlType="submit" type="primary" style={buttonWidth}>
          Save
        </Button>
      </Space>
    ) : null;
  };

  const modalContent = (
    <ProjectDeploymentSyncVarsContext.Provider value={props?.SyncVarsContext}>
      <Form
        widgets={CustomWidgets}
        formData={props.formData}
        templates={{ FieldTemplate: CustomFieldTemplate, ButtonTemplates: { SubmitButton } }}
        schema={props.formConfigs.schema}
        uiSchema={props.formConfigs.ui}
        validator={validator}
        onChange={() => console.log('changed')}
        onSubmit={handleSubmit}
        onError={e => console.log('Error from submit: ', e)}
      />
      {props.help}
    </ProjectDeploymentSyncVarsContext.Provider>
  );

  const modalControl = () => props.onCancel && props.onCancel();

  instance = Modal.info({
    title: props.title,
    icon: <EditOutlined />,
    width: '50%',
    content: modalContent,
    onOk: modalControl,
    onCancel: modalControl,
    footer: null,
  });

  return null;
};
