import gql from 'graphql-tag';
import MetacontrollerLogs from '../monitoring/metacontroller';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useGqlDeploymentById } from '../../../services/deployment.service';
import { DeploymentNamespace } from './General/DeploymentNamespace';
import { iDeployment } from 'shared/deployment';
import { DeploymentWidgetsRow } from '../DeploymentWidgetsRow';
import { TitleUIRow } from 'layout/TitleUI';
import { serviceToIcon } from '../ResourcesStates/ResourcesStates';
import { ServiceLogs } from '../monitoring/logs';
import { UserPermissions, userHasPermission } from 'shared/UserRolesPermission';
import { useAuthedQuery } from 'utils/qlAuth';
import { innerSidebar, orangeColor, redColor, siderStyle, topMargined, topPadding } from 'utils/styles';
import { useUser } from 'utils/common';
import { Button, Col, Menu, Row, Skeleton } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import K8sEvents from '../monitoring/k8sEvents';

export default function DeploymentLogsPage() {
  const history = useHistory();
  // @ts-ignore
  const { id, tabName } = useParams();
  const user = useUser();
  const dpQuery = useGqlDeploymentById(id);
  const deployment: iDeployment = dpQuery.data?.DeploymentsController_getDeployment || {};
  const error = dpQuery.error;
  const loading = dpQuery.loading;

  /**
   * Container name for logs in cubernetes pod
   * @type {string}
   * @todo get container name from the pod describe command ( https://nanoheal.atlassian.net/browse/DP-565 )
   * can be empty string - in this case logs will be taken only if in pod only one container
   */
  let container: string = '';

  const podsQuery = useAuthedQuery(
    gql`
      query DeploymentsController_getPodsMetrics($deploymentId: Int!) {
        DeploymentsController_getPodsStatus(deploymentId: $deploymentId) {
          error
          status
        }
      }
    `,
    { variables: { deploymentId: Number(id) } },
  );
  const status = podsQuery?.data?.DeploymentsController_getPodsStatus?.status;
  const loadingStatus = podsQuery?.loading;
  const errorStatus = podsQuery?.error;

  let serviceName = '';
  let podsTabRegex = /^pods_(.*?)_container_/;
  const containerRegex = /container_(.*)/;
  const podsTabsRegex = /^pods_(.*)/;
  if (tabName) {
    // let serviceName = tabName.replace(podsTabRegex, '$1');
    tabName && podsTabRegex.test(tabName)
      ? ((serviceName = tabName.match(podsTabRegex)[1]),
        containerRegex.test(tabName) ? (container = tabName.match(containerRegex)[1]) : (container = ''))
      : (serviceName = podsTabsRegex.test(tabName) ? tabName.match(podsTabsRegex)[1] : serviceName);
  }

  const query = useAuthedQuery(
    gql`
      query DeploymentsController_getPodsDescribe($deploymentId: Int!, $podName: String) {
        DeploymentsController_getPodsDescribe(deploymentId: $deploymentId, podName: $podName) {
          error
          status
          yaml
        }
      }
    `,
    { variables: { deploymentId: Number(id), podName: serviceName } },
  );

  // const [status, errorStatus, loadingStatus] = useApiQueryLoop(() => deploymentService.getDeploymentStatus(id));

  if (!tabName) history.push(`/app/${id}/logs/namespace`);

  if (error || errorStatus || !query.data) {
    return (
      <Skeleton active={false} loading={false}>
        {errorStatus?.message || JSON.stringify(error)}
      </Skeleton>
    );
  }

  if (loading || !deployment || errorStatus || loadingStatus || !deployment.isReady) return <Skeleton active={true} loading={true} />;
  if (!deployment.isReady) history.push(`/app/${deployment.id}/logs/namespace`);

  const handleClick = (e: { key: any }) => history.push(`/app/${id}/logs/${e.key}`);

  const specsErrors = deployment?.specsErrors;
  const specsWarns = deployment?.specsWarns;

  let YamlIcons = null;
  YamlIcons = specsErrors ? (
    <WarningOutlined twoToneColor={'#FF00000'} style={redColor} />
  ) : specsWarns ? (
    <WarningOutlined twoToneColor={'orange'} style={orangeColor} />
  ) : null;

  const tabComponents: { [key: string]: { component: JSX.Element } } = {
    namespace: { component: <DeploymentNamespace deployment={deployment} /> },
    sync: { component: <MetacontrollerLogs deployment={deployment} /> },
    events: { component: <K8sEvents deployment={deployment} /> },
  };

  // container = '';
  const podContainerList = [];
  let desctibeContiner = {};
  let yamlPod = '';
  if (query.data?.DeploymentsController_getPodsDescribe?.status) {
    yamlPod = query.data?.DeploymentsController_getPodsDescribe?.yaml;
    const allContainers = query.data?.DeploymentsController_getPodsDescribe?.status[0]?.spec?.containers;
    const position = podContainerList.indexOf(container);
    if (allContainers) {
      allContainers.map((item: { name?: any }) => {
        podContainerList.push(item.name);
        desctibeContiner = item.name === container ? item : desctibeContiner;
      });
      container = container === '' ? podContainerList[0] : podContainerList[position];
      desctibeContiner = container === podContainerList[0] ? allContainers[0] : allContainers[position];
    }
  }

  if (podsTabRegex.test(tabName)) {
    tabComponents[tabName] = {
      component: (
        <ServiceLogs serviceName={serviceName} deploymentId={deployment.id} container={container} describe={desctibeContiner} yamlPod={yamlPod} />
      ),
    };
  }

  const changeContainer = (elem: any) => history.push(`/app/${id}/logs/pods_${serviceName}_container_${elem}`);

  tabComponents['buttonComponentList'] = {
    component: (
      <div>
        {podContainerList.map(item => (
          <Button onClick={() => changeContainer(item)} style={{ marginTop: '10px', marginRight: '10px' }}>
            {item}
          </Button>
        ))}
      </div>
    ),
  };

  const menuItems = [
    { key: 'namespace', label: <Link to={`/app/${deployment.id}/logs/namespace`}> Namespace </Link> },
    userHasPermission(user, UserPermissions.DeploymentSpecs)
      ? { key: 'sync', label: <Link to={`/app/${deployment.id}/logs/sync`}> Sync logs </Link> }
      : null,
    userHasPermission(user, UserPermissions.DeploymentLogs)
      ? {
          key: 'pods',
          label: 'Pods logs',
          disabled: status?.length === 0,
          children: status?.length
            ? status.map((service: { name: any }) => ({
                key: `pods_${service.name}`,
                label: (
                  <>
                    {serviceToIcon(service)} {service.name}
                  </>
                ),
              }))
            : null,
        }
      : null,
    userHasPermission(user, UserPermissions.DeploymentLogs)
      ? { key: 'events', label: <Link to={`/app/${deployment.id}/logs/events`}> Events </Link> }
      : null,
  ];

  return (
    <>
      <TitleUIRow title={`Deployment: ${deployment.name}`} />
      <DeploymentWidgetsRow deployment={deployment} />
      <Row gutter={[0, 0]} wrap={false} style={topMargined}>
        <Col flex={'250px'} style={innerSidebar}>
          <Menu
            style={siderStyle}
            items={menuItems.filter(Boolean)}
            onClick={handleClick}
            defaultSelectedKeys={[tabName]}
            defaultOpenKeys={[tabName, `namespace`]}
            mode="inline"
          />
        </Col>
        <Col flex="auto">
          <div> {tabComponents['buttonComponentList']?.component} </div>
          <div style={topPadding}> {tabComponents[tabName]?.component} </div>
        </Col>
      </Row>
    </>
  );
}
