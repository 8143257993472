import { InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, Space, Tag, Popover, Typography } from 'antd';
import { uptimeToString } from 'utils/common';
import { DeploymentTag } from '../DeploymentTag';
import { iDeployment } from 'shared/deployment';

const { Title, Text } = Typography;

export const DeploymentUptime = (props: { deployment: iDeployment; hasRoutes: boolean; popoverContent: React.ReactNode }) => {
  const { deployment, hasRoutes, popoverContent } = props;
  return (
    <Row>
      <Col span={24}>
        <Space>
          {deployment.uptime ? <Tag> Deployment age: {uptimeToString(deployment.uptime)} </Tag> : ''}
          {hasRoutes ? (
            <a href={`https://${deployment.domain}`} target="_blank">
              <Tag>
                <Text strong> Management Portal URL: </Text> {deployment.domain}
              </Tag>
            </a>
          ) : (
            <Popover title={<Title level={5}> Public URL </Title>} content={popoverContent}>
              <Tag color="green" icon={<InfoCircleOutlined />}>
                This Deployment does not have a Public URL
              </Tag>
            </Popover>
          )}
          <DeploymentTag deployment={deployment} />
        </Space>
        <br /> <br />
      </Col>
    </Row>
  );
};
