import { iProject } from 'interface/project';
import { OneFile } from 'components/Projects/FileEditor/OneFile';
import { HelpNoteAboutGoTemplates } from './HelpNotes';
import { PROJECT_CODE_NAME } from 'interface/common';
import { Alert, Space, Typography } from 'antd';
import { spaceWidth } from 'utils/styles';
import { useState } from 'react';
import { iProjectModel } from 'shared/deployment';

export interface iProjectOneServiceCustomYAML {
  project: iProjectModel;
  serviceName: string;
  tabType: string;
}

/** Show custom.yaml file for service Usfull for create some additional kubernetes entities related to service
 * @param props
 * @returns
 */

const { Text } = Typography;

export const ProjectOneServiceCustomYAML = (props: iProjectOneServiceCustomYAML) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const serviceName = props.serviceName;

  const alertMessage = (
    <Space direction="vertical">
      <Text> Usefull for create some additional Kubernetes Entities related to Service. </Text>
      <HelpNoteAboutGoTemplates />
    </Space>
  );

  return (
    <Space direction="vertical" style={spaceWidth}>
      <Text />
      <Text strong> Configuration for {serviceName} Custom YAML </Text>
      <OneFile
        fileName={`${PROJECT_CODE_NAME}/services/${serviceName}/custom.yaml`}
        project={props.project}
        height={isFullscreen ? 'calc(100vh - 215px)' : '100vh'}
      />
      <Alert showIcon type="info" message={alertMessage} />
      <Text /> <Text /> <Text /> <Text /> <Text /> <Text />
    </Space>
  );
};
