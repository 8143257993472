import DashboardItem from 'components/ChartRenderer/DashboardItem';
import { Link } from 'react-router-dom';
import { iRegionModel } from 'shared/deployment';
import { ChartRenderer } from 'components/ChartRenderer/ChartRenderer';
import { Alert, Col, Typography } from 'antd/lib';
import { clusterHeight } from 'utils/styles';

const { Text } = Typography;
export interface iClusterAwsPriceProps {
  region: iRegionModel;
}

export const ClusterAwsPrice = (props: iClusterAwsPriceProps) => {
  const region: iRegionModel = props.region;
  const stats_aws_ce = [
    {
      id: 0,
      lg: 24,
      span: `24`,
      name: 'Price for 30 days',
      vizState: {
        query: {
          limit: 500,
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['day'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['aws'] },
            { member: 'CostHistory.regionId', operator: 'equals', values: [`${region.id}`] },
          ],
          dimensions: ['CostHistory.uiName'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', granularity: 'day', dateRange: 'Last 30 days' }],
          measures: ['CostHistory.sum'],
          order: { 'CostHistory.sum': 'desc' },
        },
        chartType: 'area',
        pivotConfig: {
          x: ['CostHistory.timeStart.day'],
          y: ['CostHistory.uiName'],
          fillMissingDates: true,
          joinDateRange: false,
        },
      },
    },
    {
      id: 0,
      lg: 24,
      span: `24`,
      name: 'Price for 30 days',
      vizState: {
        query: {
          limit: 1000,
          order: { 'CostHistory.sum': 'desc' },
          filters: [
            { member: 'CostHistory.timeInterval', operator: 'equals', values: ['day'] },
            { member: 'CostHistory.costProvider', operator: 'equals', values: ['aws'] },
            { member: 'CostHistory.regionId', operator: 'equals', values: [`${region.id}`] },
          ],
          dimensions: ['Deployments.name', 'CostHistory.uiName'],
          timeDimensions: [{ dimension: 'CostHistory.timeStart', dateRange: 'Last 30 days' }],
          measures: ['CostHistory.sum'],
        },
        chartType: 'table',
      },
    },
  ];

  const alertDescription = (
    <ul>
      <li>
        AWS cost is calculated based on AWS Cost Explorer API. It is paid service and you will be charged. To get the data you need to enable Cost
        Explorer API in your AWS account and provide credentials on the&nbsp;
        <Text strong>
          <Link to={`/clusters/${region.id}/settings`} target="_blank">
            cluster configuration page
          </Link>
        </Text>
      </li>
      <li>
        This integration will calculate cost only for resources with this tags <b> " dp.tag/deploymentId " </b> and
        <b> " clusterName " </b>
      </li>
      <li>
        Code expect that you all resources with tag <b> " clusterName={region.name} " </b> belongs to this cluster.
      </li>
      <li>
        Code expect that you all resources with tag <b> " dp.tag/deploymentId=DEPLOYMENT_ID " </b> belongs to additional resources created for
        deployment with ID <b> " DEPLOYMENT_ID " </b> .
      </li>
    </ul>
  );

  return (
    <>
      {stats_aws_ce.map((stat, index) => {
        return (
          <Col span={stat.span || 24} key={stat.id || index} style={clusterHeight}>
            <DashboardItem title={stat.name}>
              <ChartRenderer vizState={stat.vizState} />
            </DashboardItem>
          </Col>
        );
      })}
      <Col span={24} key={'note'} style={clusterHeight}>
        <Alert showIcon type="info" message="Note" description={alertDescription} />
      </Col>
    </>
  );
};
