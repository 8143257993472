import { useCallback, useEffect } from 'react';
import { Button, Flex, message, Tooltip } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import { floatingRight, fullScreenButton, fullScreenView } from 'utils/styles';

interface FullScreenProps {
  children?: React.ReactNode;
  isFullscreen?: boolean;
  setIsFullscreen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FullScreenButton: React.FC<FullScreenProps> = ({ isFullscreen, setIsFullscreen }) => {
  const handleFullScreenViewClick = () => {
    setIsFullscreen(!isFullscreen);
    message.info('Click ESC key to go back');
  };
  return (
    <Tooltip color="#115EA3" placement="left" title="Click here for Full Screen View">
      <Flex justify="flex-end" style={floatingRight}>
        <Button type="dashed" icon={<FullscreenOutlined style={fullScreenButton} />} onClick={handleFullScreenViewClick} />
      </Flex>
    </Tooltip>
  );
};

export const FullScreenEditor: React.FC<FullScreenProps> = ({ children, isFullscreen, setIsFullscreen }) => {
  const handleESC = useCallback(
    (event: KeyboardEvent) => {
      return event.key === 'Escape' && isFullscreen ? setIsFullscreen(false) : null;
    },
    [isFullscreen, setIsFullscreen],
  );
  useEffect(() => {
    window.addEventListener('keydown', handleESC);
    return () => window.removeEventListener('keydown', handleESC);
  }, [handleESC]);
  return <div style={isFullscreen ? fullScreenView : undefined}> {children} </div>;
};

FullScreenEditor.defaultProps = { isFullscreen: false, setIsFullscreen: () => {} };
