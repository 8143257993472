import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { iRegionModel } from 'shared/deployment';
import { useRegionsController_getRegionById } from 'services/regions.service';
import { GeneralSettings } from './GeneralSettings';
import { KubeCost } from './KubeCost';
import { AwsPrice } from './AwsPrice';
import { Col, Menu, Row, Skeleton } from 'antd';
import { innerSidebar, siderStyle, topMargined, topPadding } from 'utils/styles';
import { LoadBalancer } from './LoadBalancer';
import { TerraformJobsConfig } from './TerraformJobsConfig';

export interface iClusterConfigPageProps {
  region: iRegionModel;
}

export const ClusterConfigPage = (props: iClusterConfigPageProps) => {
  const history = useHistory();
  const params: any = useParams();
  const [tabName, setCurrent] = useState(params.subTabName || `general`);

  useEffect(() => {
    setCurrent(params?.subTabName || `general`);
  }, [params?.subTabName]);

  const { loading, error, region } = useRegionsController_getRegionById(props.region.id);

  if (loading || error) return <Skeleton active={true} loading={true}></Skeleton>;

  const handleClick = (e: any) => {
    console.log('click ', e.key);
    const path = `/clusters/${props.region.id}/settings/${e.key}`;
    history.push(path);
  };

  const menuItems = [{ key: 'general', label: <Link to={`/clusters/${props.region.id}/settings/general`}> General </Link> }];
  // if (['aws', 'gcp', 'azure'].includes(region.cloudProvider)) {
  menuItems.push({ key: 'kube-cost', label: <Link to={`/clusters/${props.region.id}/settings/kube-cost`}> Kube cost </Link> });
  // }
  if (region.cloudProvider === 'aws') {
    menuItems.push({ key: 'aws-price', label: <Link to={`/clusters/${props.region.id}/settings/aws-price`}> AWS Price </Link> });
  }
  menuItems.push({ key: 'load-balancer', label: <Link to={`/clusters/${props.region.id}/settings/load-balancer`}> Loadbalancer </Link> });

  if (region.cloudProvider === 'aws') {
    menuItems.push({
      key: 'terraform-config',
      label: <Link to={`/clusters/${props.region.id}/settings/terraform-config`}>Terraform configuration </Link>,
    });
  }

  const tabComponents: { [key: string]: JSX.Element } = {
    general: <GeneralSettings region={region} />,
    'kube-cost': <KubeCost region={region} />,
    'aws-price': <AwsPrice region={region} />,
    'load-balancer': <LoadBalancer region={region} />,
    'terraform-config': <TerraformJobsConfig region={region} />,
  };

  return (
    <Row gutter={[0, 0]} wrap={false} style={topMargined}>
      <Col flex={'250px'} style={innerSidebar}>
        <Menu
          style={siderStyle}
          items={menuItems}
          onClick={handleClick}
          defaultSelectedKeys={[tabName]}
          defaultOpenKeys={[tabName, `pods`]}
          mode="inline"
        />
      </Col>
      <Col flex="auto">
        <div style={topPadding}> {tabComponents[tabName]} </div>
      </Col>
    </Row>
  );
};
